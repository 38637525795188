import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useRef} from "react";

const TrackingForm = (props) => {
    const {errors,register}=props;

    return (
        <Fragment>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking Name<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingName" type="text" className={`form-control ${errors.trackingName ? 'is-invalid' : ''}`} placeholder="DHL" {...register('trackingName')}  />
                            <div className="invalid-feedback">{errors.trackingName?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking ID<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingId" type="text" className={`form-control ${errors.trackingId ? 'is-invalid' : ''}`} placeholder="2164116410" {...register('trackingId')}  />
                            <div className="invalid-feedback">{errors.trackingId?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Tracking URL<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingUrl" type="text" className={`form-control ${errors.trackingUrl ? 'is-invalid' : ''}`} placeholder="https://www.dhl.co.uk/url" {...register('trackingUrl')}  />
                            <div className="invalid-feedback">{errors.trackingUrl?.message}</div>
                        </FormGroup>
                    </Col>
                </Row>
        </Fragment>
    );
};

export default TrackingForm;