import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {addRoleApi, deleteRoleApi, getRolesApi, updateRoleApi} from "../../../services/manage-user/role";
import {toast} from "react-toastify";
import {getUsers} from "./userSlice";


export const getRoles = createAsyncThunk('roles/getRole', async () => {
    const response = await getRolesApi();
    return response.response;
});

export const addRole = createAsyncThunk('roles/addRole', async (role,{dispatch,  getState}) =>{
    try {
        const response = await addRoleApi(role);
        const data = await response.response;
        dispatch(getRoles());
        toast.success("Add Role successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;

    } catch (e) {

        return Promise.reject(e);
    }
});

export const updateRole = createAsyncThunk('roles/updateRole', async (role, {dispatch, getState}) => {
    try {
        const response = await updateRoleApi(role, role.id);
        const data = await response.response;
        dispatch(getRoles());
        toast.success("Updated Role successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }

});

export const deleteRole = createAsyncThunk('roles/updateRole', async (id, {dispatch, getState}) => {
    try {
        const response = await deleteRoleApi(id);
        const data = await response.response;
        dispatch(getRoles());
        toast.success("Deleted Role successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});



const rolesAdapter = createEntityAdapter({});

export const {selectAll: selectRole, selectById: selectRoleById} = rolesAdapter.getSelectors(
    state => state.user.role
);

const roleSlice = createSlice({
    name: 'roles',
    initialState: rolesAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'Role',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        setRoleSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'Role',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog:(state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        }
    },
    extraReducers: {
        [getRoles.fulfilled]: rolesAdapter.setAll
    }
});

export const {setRoleSearchText, openNewDialog, closeNewDialog, openEditDialog, closeEditDialog,} = roleSlice.actions;

export default roleSlice.reducer;