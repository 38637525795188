import API from "../utils/api";

export const getBackStockRequestData = async (data) => {
    const response = await API.get(`customer-product-request/get-product-request-list`, { params: data });
    return { response };
};

export const getSelectedProductDataApi = async (id) => {
    const response = await API.get(`customer-product-request/get-selected-data/${id}`);
    return { response };
};

export const getCustomerListApi = async () => {
    const response = await API.get(`customer-product-request/get-customer-list`);
    return { response };
}