import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Popover, PopoverBody, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import productReducer from "../../store";
import ProductTypeTagForm from "./productTypeTagForm";
// import {
//     addProductType,
//     closeEditDialog,
//     deleteProductType,
//     getAllProductTypes,
//     openEditDialog,
//     openNewDialog,
//     selectProductType,
//     updateProductType
// } from "../../store/productTypeSlice";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {tagSettingResponseToTable} from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";
import { addTagSetting, getTagSettingData, setTagSettingData, closeEditDialog, openEditDialog,
    openNewDialog, 
    selectProductTagSetting,
    getAllTagSetting,
    editTagSetting,
    updateTagSetting,
    deleteTagSetting} from "../../store/productTagSettingSlice";


const defaultValues = {
    id: '',
    name: '',
    tageName: '',
    is_tag: false
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 2,
        text: 'Tag Setting',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a  type tag name'),
    // tageName: yup.string().required('You must enter a tag name'),
});

const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const ProductTypeTag = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [tag, setTag] = useState([]);
    const [loading, setLoading] = useState(false);
    const tagSettings = useSelector(selectProductTagSetting);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [settingData, setSettingData] = useState([]);
    
    useEffect(() => {
        const data = {
            name,
            // tag
        }
        dispatch(getAllTagSetting({...data})).then(res=>{
            if (!res.error)
                setLoading(false);
        });

    }, [dispatch, name]);

    console.log(tagSettings);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        let tags = [];
        (data.tags).map((item, i) => {
            tags.push(item.id);
        });
        data.tags = tags;
        data.is_tag?data.is_tag = 1:data.is_tag = 0;
        
        if (dialog.type === 'new') {
            dispatch(addTagSetting({...data})).then(res => {
                setButtonDisable(false);
                console.log(res)
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateTagSetting({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    const closeDialog = () => {
        dispatch(closeEditDialog());
    };

    const onEdit = (row) => {
        dispatch(editTagSetting(row.id)).then(res => {
			if (!res.error) dispatch(openEditDialog(res.payload));
		});
        // dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteTagSetting(row.id))
    }

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
            width:"20%"
        },
        {
            name: 'Tags',
            // selector: row => row.tagName,
            selector: row => (row.tooltip_list? (<PopoverBasicItem id={row.id} Popoverbody={row.tooltip_list} btntext={row.tagName}/>):row.tagName),
            sortable: true,
            left: true,
            width:"60%"
        }
    ]

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                    <Breadcrumb data={dataMenu} title="Tag Setting"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Product Types') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Tag Setting</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                                   type="text"
                                                   className="form-control border"
                                                   placeholder="Search By Tag Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-4">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={tagSettingResponseToTable(tagSettings)}
                                                    pagination
                                                    onEdit={(currentUser.permission).includes('Update Product Types') ? onEdit:''}
                                                    onDelete={(currentUser.permission).includes('Delete Product Types') ? onDelete:''}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                         </>
                }

            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Tag Setting'
                selector='product'
                dialog={({product}) => product.productTagSetting.addDialog}
                closeDialog={closeDialog}
                // publish
                setValue={setValue}
                getValues={getValues}
                // btnText={btnText}
                buttonDisable={buttonDisable}
            >
                <ProductTypeTagForm  control={control} errors={errors} register={register} setValue={setValue}
                                 trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([
    {
        productTypeTag: productReducer
    }
])(ProductTypeTag);