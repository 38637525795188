import React, { Component }  from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {setUserDataPassportAuth} from "../redux/auth/userSlice";
import {loginUserByToken} from "../services/auth";
import {getUserMenus} from "../redux/auth/navigationSlice";


class Auth extends Component {
    state = {
        waitAuthCheck: true
    };

    componentDidMount() {
        return Promise.all([
            // Comment the lines which you do not use
            this.authCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false });
        });
    }

    authCheck = () =>
        new Promise((resolve, reject) => {
            const accessToken = localStorage.getItem('token');
            if (accessToken) {
                loginUserByToken()
                    .then(data => {
                        this.props.setUserDataPassportAuth(data.response);
                        this.props.getUserMenus();

                        resolve();
                    })
                    .catch(error => {
                        // this.props.logoutUser();
                        resolve();
                    });
            } else {
                resolve();
            }
            this.setState({ waitAuthCheck: true });

            return Promise.resolve();
        });

    render() {


        return (
            this.state.waitAuthCheck ?null:<>{this.props.children}</>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setUserDataPassportAuth,
            getUserMenus,

        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(Auth);
