import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useSelector} from "react-redux";



export default function CustomModal(props) {
    const {
        dialog,
        title,
        closeDialog,
        size = "md",
        subTitle,
        isUseSelector,
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const addEditDialog = isUseSelector ? dialog : useSelector(dialog);

    const closeModalDialog = () => {
        closeDialog()
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...addEditDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    {title}
                    <br/>
                    {subTitle && <small><i>{subTitle}</i></small>}
                </ModalHeader>
                    <ModalBody>

                        {props.children}

                    </ModalBody>

            </Modal>

        </div>
    );
}
