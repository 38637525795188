import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ShippingInformation = (props) => {
    const {orderState,colSize}=props;
    return (
        <Fragment>
            <Col sm={colSize}>
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-danger h6">Shipping Information</div>
                        {(orderState.data && orderState.data.shipping_address)?
                            <Row>
                                <Col sm={colSize}>
                                    <span className="text-capitalize">{orderState.data.shipping_address.name?orderState.data.shipping_address.name:"-"}</span>
                                </Col>
                                <Col sm={colSize}>
                                    <span> <b className="text-secondary">Address:</b> {orderState.address? `${orderState.address}` :"-"}</span>
                                </Col>
                                <Col sm={colSize}>
                                    <span> <b className="text-secondary">Phone:</b> {orderState.data.shipping_address.phone?orderState.data.shipping_address.phone:"-"}</span>
                                </Col>
                                <Col sm={colSize}>
                                    <span> <b className="text-secondary">Mobile:</b> {orderState.data.shipping_address.mobile?orderState.data.shipping_address.mobile:"-"}</span>
                                </Col>
                            </Row>
                            :null
                        }
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};