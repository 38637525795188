import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import { setSkuDialog} from "../../../store/prodcutSlice";



const SkuDetails = (props) => {
    const {setValue, trigger, getValues,imageSelectOnClick} = props;
    const dispatch = useDispatch();
    const productState = useSelector(({product}) => product.product.skuDialogProps);
    const colourId=productState.data?productState.data.colour_id:"";
    const colourName=productState.data?productState.data.colour:"";
    const [sku,setSku]=useState([]);


    const closDialog = ()=>{
        dispatch(setSkuDialog({props:false}))
    }
    useEffect(()=>{
        if(colourId) {
            let skuData = [];
            const colourData = getValues('color').find(x => x.id == colourId);
            if(getValues('selling_type').find(x => x.id === 1)) {
                getValues('size').map((item, index) => {
                    skuData.push(((getValues('code') + "-" + colourData.tag_name + "-" + item.name).toLocaleLowerCase()).replaceAll(' ', ''));
                });
            }
            if(getValues('selling_type').find(x => x.id === 2)) {
                skuData.push(((getValues('code') + "-" + colourData.tag_name + "-pack").toLocaleLowerCase()).replaceAll(' ', ''));
            }
            setSku(skuData);

        }

    },[colourId]);





    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="md"    {...productState.props}toggle={() => {closDialog() }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        {colourName+"'s sku"}
                    </ModalHeader>
                    <ModalBody>


                                    {sku.map((item, i) =>
                                        <Row key={i} className="mb-1">
                                    <Col sm={12} >
                                            <div className="prooduct-details-box" style={{marginBottom:10}}>
                                                <div className="media">
                                                    <div className="media-body ml-3">
                                                        <div className="product-name">
                                                            <h6>{item.toUpperCase()}</h6>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        </Row>
                                            )}



                    </ModalBody>

                </Modal>
            </div>
        </Fragment>
    );
}
function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}
export default React.memo(SkuDetails,areEqual);