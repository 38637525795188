import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap'
import moment from "moment";
const Footer = (props) => {
  const domainName = window.location.origin;
  const name = (domainName == "https://mybabezlondon.com") ? 'Babez london' : 'City Goddess';
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">{`Copyright ${moment().year()} © Powered by AkH IT Solutions.`}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;