import API from "../utils/api";

export const getAllShippingSettingsApi = async (data) => {
    const response = await API.get('order/settings/shipping', {params: data});
    return { response };
};

export const storeShippingSettingsApi = async (data) => {
    const response = await API.post('order/settings/shipping', data);
    return { response };

};
export const updateShippingSettingsApi = async (data, id) => {
    const response = await API.put(`order/settings/shipping/${id}`, data);
    return { response };
};

export const activateShippingSetting = async (data, id) => {
    const response = await API.put(`order/settings/shipping/set-active/${id}`, data);
    return { response };
};

export const getShippingSettingsApi = async (data, id) => {
    const response = await API.get(`order/settings/shipping/get-settings`);
    return { response };
};

export const sortShippingOrderApi = async (data) => {
    const response = await API.post('order/settings/shipping/sort', data);
    return { response };
}
