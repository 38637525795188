import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {addSellerVatApi, getCountryListApi, getSelectedSellerVatApi} from "../../../services/sellerVat";
import {toast} from "react-toastify";
import {getAllSellersApi} from "../../../services/shippingZone";


export const getCountryList = createAsyncThunk('sellerVat/getCountryList', async () => {
    const response = await getCountryListApi();
    return response.response;
});

export const getSellerVatDetails = createAsyncThunk('sellerVat/getSellerVatDetail', async (id, {dispatch}) => {
    const response = await getSelectedSellerVatApi(id);
    return response.response;
});

export const getAllSellers = createAsyncThunk('shippingZone/getAllSellers', async (data,{dispatch}) => {
    const response = await getAllSellersApi();
    return response.response;
});


export const addSellerVat = createAsyncThunk('sellerVat/addSellerVat', async (sellerVat, {dispatch}) => {
    try {
        const  response = await addSellerVatApi(sellerVat);
        const data = await response.response;
        toast.success("Seller vat added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

const sellerVatAdapter = createEntityAdapter({});

export const {selectAll: selectSellerVat, selectById: selectSellerVatById} = sellerVatAdapter.getSelectors(
    state => state.seller.sellerVat
);

const sellerVatSlice = createSlice({
    name: 'sellerVat',
    initialState: sellerVatAdapter.getInitialState({
        countryList: []
    }),
    reducers: {
        setCountryList: (state, action) => {
            state.countryList = action.payload
        }
    },
    extraReducers: {
        [getSellerVatDetails.fulfilled]: sellerVatAdapter.setAll
    }
});

export const {
    setCountryList
} = sellerVatSlice.actions;

export default sellerVatSlice.reducer;