import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Media, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap'

import {connect, useDispatch} from "react-redux";

import { updateProfileImage,
} from "../store/userSlice";

import UserChangePassword from "./content/userChangePassword";
import UserDetail from "./content/userDetail";
import UserLog from "./content/userLog";


const UserProfile = (props) => {
    const dataMenu = [
        {
            type: 0,
            text: "User Profile"
        }
    ];
    const [IconWithTab, setIconWithTab] = useState('1');

    const dispatch = useDispatch();

    const inputFile = useRef(null);

    const imageClick = (event)=>{
        event.preventDefault();
        inputFile.current.click();
    };

    const handleImageClick = (event)=>{
        const set={
            imageURL:props.userData.photoURL?props.userData.photoURL:null,
            ...event
        }
        dispatch(updateProfileImage({ ...set })).then(res => {});
    };

    return (
        <Fragment>
            <Breadcrumb title="User Profile" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="3">
                        <Card>
                            <CardHeader>
                                <Row className="mb-1">
                                    <div className="col-auto">
                                        {/*src={props.userData.photoURL}*/}

                                        <Media onClick={imageClick} className="img-70 rounded-circle" alt="user-image" src={props.userData.photoURL?props.userData.photoURL:require("../../../assets/images/user/user.png")}   />
                                        <input type='file' id='file' ref={inputFile} onChange={handleImageClick} style={{display: 'none'}}/>
                                    </div>
                                    <Col>
                                        <h3 className="mb-1">{props.userData.displayName}</h3>
                                        <p className="mb-4">{props.userData.user.role.name}</p>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink href="#javascript" className={IconWithTab === '1' ? 'active-element' : ''} onClick={() => setIconWithTab('1')}><i className="icofont icofont-man-in-glasses"></i>Details</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#javascript" className={IconWithTab === '2' ? 'active-element' : ''} onClick={() => setIconWithTab('2')}><i className="icofont icofont-key"></i>Change Password</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={IconWithTab}>
                                    <TabPane className="fade show" tabId="1">
                                        <UserDetail name={props.userData.displayName} email={props.userData.email} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <UserChangePassword />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="9">
                        <Card>
                            <CardHeader>
                                <h4>Activity Log</h4>
                            </CardHeader>
                            <CardBody>
                                <UserLog />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </Fragment>
    );

};
const mapStateToProps = ({authReducer}) => {
    return {
        userData: authReducer.data
    };
}
export default connect(mapStateToProps)(UserProfile)


