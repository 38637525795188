import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import {downloadOrderInvoice, downloadOrderPdf} from "../../store/orderSlice";
import {useDispatch} from "react-redux";
import {defaultValues} from "../create";


export const Details = (props) => {
    const {orderState,onAddTrackingOpen,handleTrackingInvoiceDownload}=props;

    return (
        <Fragment>
            <div className="table-responsive">
                <Table className="table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Date</th>
                        <th scope="col">Tracking Name</th>
                        <th scope="col">Tracking ID</th>
                        <th scope="col">Tracking URL</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderState.trackingDetails?(orderState.trackingDetails.map((item,index)=>{
                        return (
                            <tr key={index}>
                                <td>{item.invoice_no}</td>
                                <td>{item.invoice_date}</td>
                                <td>{item.tracking_name}</td>
                                <td>{item.tracking_id}</td>
                                <td>{item.tracking_url}</td>
                                <td>
                                    {item.tracking_name? '':<a onClick={()=>{onAddTrackingOpen(item.id)}} className="ml-1 btn btn-sm btn-warning p-2"><i  className="fa fa-check-square-o"></i></a>}
                                    {item.invoice_no? <a onClick={()=>{handleTrackingInvoiceDownload(item.id,item.invoice_no)}} className="ml-1 btn btn-sm btn-primary p-2"><i className="fa fa-file-pdf-o"></i> Download Invoice</a>:''}
                                </td>
                            </tr>)
                    })):null
                    }
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};