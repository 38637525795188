import {Form, TabContent, TabPane} from "reactstrap";
import React from "react";
import {PrimaryDetails} from "./primaryDetails";
import {BrandDetails} from "./brandDetails";
import {SellerStoreDescription} from "./sellerStoreDescription";
import {ExtraInformation} from "./extraInformation";
import {HeadingLinks} from "./headingLinks";
import {CustomerServiceContactDetails} from "./customerServiceContactDetails";
import {ReturnsWarehouseAddress} from "./returnsWarehouseAddress";
import {UploadImage} from "./uploadImage";
import {UploadBannerImage} from "./uploadBannerImage";

export const UpdateSellerForm = (props) => {
    const {VerticleTab,errors,register,sellerState,isPremium,setIsPremium,isTaxChargeable,setIsTaxChargeable,trigger,setValue,getValues,sellerSinceDate,setSellerSinceDate,setPhotoIndex,photoIndex}=props;
    return (

        <TabContent activeTab={VerticleTab}>
            <TabPane className="fade show" tabId="1">
                <PrimaryDetails {...props} />
            </TabPane>
            <TabPane tabId="2">
                <BrandDetails {...props} />
            </TabPane>
            <TabPane tabId="3">
                <SellerStoreDescription {...props}/>
            </TabPane>
            <TabPane tabId="4">
                <ExtraInformation {...props} />
            </TabPane>
            <TabPane tabId="5">
                <HeadingLinks {...props}/>
            </TabPane>
            <TabPane tabId="6">
                <CustomerServiceContactDetails {...props}/>
            </TabPane>
            <TabPane tabId="7">
                <ReturnsWarehouseAddress {...props}/>
            </TabPane>
            <TabPane tabId="8">
                <UploadImage {...props}/>
            </TabPane>
            <TabPane tabId="9">
                <UploadBannerImage {...props}/>
            </TabPane>
        </TabContent>
    );
};