import {Badge, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment, useState} from "react";
import moment from "moment";

export const CardRecentSellers = (props) => {
    const {dashboardState,history}=props;
    return (
        <Fragment>
            <Col style={{height:"min-content"}}>
                <Card className="h-75">
                    <CardHeader>
                        <h5>Recent Registered Sellers</h5>
                        {/*<p className="font-roboto">Sellers who have registered recently </p>*/}
                    </CardHeader>
                    <CardBody className="overflow-visible">
                        <div className="table-responsive">
                            <Table className="table table-borderless table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Shop Name</th>
                                    <th scope="col">View</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dashboardState.recentSellers? dashboardState.recentSellers.map((item, i) =>
                                    <tr key={i}>
                                        <th scope="row">{item.name}</th>
                                        <td>{item.shopName}</td>
                                        <td><button onClick={()=>{ history.push('/seller/'+item.id+'/view')}} className="btn btn-light"><i className= "icon-eye"></i></button></td>
                                    </tr>
                                ):null}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={()=>{history.push('/seller')}}  type="button" className="btn btn-primary">View All Seller</button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </Fragment>
    );
};