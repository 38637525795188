import React from 'react';
import {Button} from "reactstrap";

export default function ButtonLoader(props){
    const {disabled,btntext} = props;
    return(
        <Button
            {...props}
        >{disabled && <i  className="fa fa-spinner fa-lg spinner mr-1" /> }  {btntext}
        </Button>
    )
}