import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {defaultValues, schema} from "../settings";
import {Card, CardBody, CardFooter, Col, Container, Form, Row} from 'reactstrap'
import {UpdateSellerLeadForm} from "../form/updateSellerLeadForm";
import {UpdateSellerLeadNavItems} from "./updateSellerLeadNavItems";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedSellerLeadData, getSellerLeadSettings, setSettings} from "../store/sellerLeadSlice";

const dataMenu = [
    {
        type: 0,
        text: 'Seller Leads',
        link: ''
    },
    {
        type: 1,
        text: 'Update Seller Lead',
        link: ''
    },
];

const UpdateSellerLead = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [VerticleTab, setVerticleTab] = useState('1');
    const [formData, setFormData] = useState([]);
    const [buyer, setBuyer] = useState(false);

    const sellerLeadState = useSelector(({lead}) => lead.sellerLead);

    useEffect(() => {

        // if (buyer) {
             // dispatch()
        // } else {
            dispatch(getSelectedSellerLeadData(id)).then(res=>{
                setFormData(res.payload);
            } );
        // }



        dispatch(getSellerLeadSettings()).then(res => {
            dispatch(setSettings(res.payload))
        })
    }, [dispatch]);

    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Update Seller Lead"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="3" xs="12" className="border-right">
                                            <UpdateSellerLeadNavItems VerticleTab={VerticleTab}
                                                                      setVerticleTab={setVerticleTab}/>
                                        </Col>
                                        <Col sm="9" xs="12">
                                            <UpdateSellerLeadForm buyer={buyer} formData={formData} VerticleTab={VerticleTab} id={id} setVerticleTab={setVerticleTab}
                                                                  sellerLeadState={sellerLeadState}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default (UpdateSellerLead);