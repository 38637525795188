import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductCategoryApi, deleteProductCategoryApi,
    getProductCategoryApi,
    updateProductCategoryApi
} from "../../../services/manage-product/setting/productCategoryService";
import {toast} from "react-toastify";


export const getAllProductCategories = createAsyncThunk('productCategory/getProductCategory', async (filters, {dispatch}) => {
    const response = await getProductCategoryApi(filters);
    return response.response;
});

// export const getAllProductTypes = createAsyncThunk('productCategory/getProductTypes', async (filters, {dispatch}) => {
//     const response = await getAllTypesApi();
//     return response.response;
// });

export const addProductCategory = createAsyncThunk('productCategory/addProductCategory', async (productCategory, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductCategoryApi(productCategory)
        const data = await response.response;
        console.log(data)
        dispatch(getAllProductCategories());
        toast.success("Add product category successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductCategory = createAsyncThunk('productCategory/updateProductCategory', async (productCategory, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductCategoryApi(productCategory, productCategory.id);
        const data = await response.response;
        dispatch(getAllProductCategories());
        toast.success("Update product category successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductCategory = createAsyncThunk('productCategory/deleteProductCategory', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductCategoryApi(id);
        const data = await response.response;
        dispatch(getAllProductCategories());
        toast.success("Delete product category successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});



const productCategoryAdapter = createEntityAdapter({});

export const {
    selectAll: selectProductCategory,
    selectById: selectProductCategoryById
} = productCategoryAdapter.getSelectors(
    state => state.product.productCategory
);

const productCategorySlice = createSlice({
    name: 'productCategory',
    initialState: productCategoryAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productCategory',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductCategorySearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productCategory',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductCategories.fulfilled]: productCategoryAdapter.setAll
    }
});

export const {
    selectProductCategorySearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productCategorySlice.actions;

export default productCategorySlice.reducer;