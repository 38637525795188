import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addAgentsApi,
    deleteAgentsApi,
    getAgentsApi,
    getAllSellersApi,
    updateAgentsApi
} from "../../../services/manage-user/agent";
import {toast} from "react-toastify";


export const getAgents = createAsyncThunk('agents/getAgents', async (filters, {dispatch}) => {
    const response = await getAgentsApi(filters);
    return response.response;
});

export const addAgent = createAsyncThunk('agents/addAgents', async (agent, {dispatch, getState}) => {
    try {
        const response = await addAgentsApi(agent);
        const data = await response.response;
        dispatch(getAgents());
        toast.success("Add Agent successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const updateAgent =  createAsyncThunk('agents/updateAgents', async (agent, {dispatch, getStatus}) =>{
    try {
        const response = await updateAgentsApi(agent, agent.id);
        const data = await response.response;
        dispatch(getAgents());
        toast.success("Update Agent successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
export const deleteAgent = createAsyncThunk('agents/deleteAgents', async (id, {dispatch, getState})=> {
    try {
        const response = await deleteAgentsApi(id);
        const data = await response.response;
        dispatch(getAgents());
        toast.success("Delete Agent successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const getAllSellers = createAsyncThunk('agents/getAllSellers',async () =>{
    const response = await getAllSellersApi();
    return response.response;
});

const agentAdapter = createEntityAdapter({});

export const {selectAll: selectAgent, selectById: selectAgentById} = agentAdapter.getSelectors(
    state => state.user.agent
);

const agentSlice = createSlice({
    name: 'agents',
    initialState: agentAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'Agent',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        settings: {
            seller: []
        }
    }),
    reducers: {
        setAgentSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'Agent',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog:(state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setSettings: (state, action) => {
            state.settings = action.payload
        },
    },
    extraReducers: {
        [getAgents.fulfilled]: agentAdapter.setAll
    }
});

export const {setAgentSearchText,openNewDialog, closeNewDialog, openEditDialog, closeEditDialog} = agentSlice.actions;

export default agentSlice.reducer;