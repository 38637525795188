import {Button, Card, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {
    closeBrandDialog, deleteSellerBrands,
    getSellerBrands,
    openBrandDialog,
    updateSellerBrand,
    updateSellerImg
} from "../../store/sellerSlice";
import BrandForm from "./modal-form/brandForm";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert2';
import {toast} from "react-toastify";
import './brandDetailStyles.css';

const defaultValues = {
    brand: '',
    image: '',
    imageFile: '',
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    brand: yup.string().required('Brand is required field').typeError('Brand is a required field'),
});


export const BrandDetails = (props) => {
    const {sellerState}=props;

    const { handleSubmit, formState, reset, control, register,setValue ,trigger,getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const dispatch = useDispatch();
    const [btnDisabled,setBtnDisabled]=useState(false);

    const onButtonClick = () =>{
        openDialog();
    };
    const onSubmitForm = (dialog, data) => {
        if(data){
            const formData = new FormData();
            formData.append('id', sellerState.id);
            formData.append('old_id', sellerState.brandDialog.data.oldId?sellerState.brandDialog.data.oldId:null);
            formData.append('brand', data.brand);
            formData.append('image', data.image ? data.image : '');
            setBtnDisabled(true);
            dispatch(updateSellerBrand(formData)).then(res => {
                if (!res.error) {
                    setBtnDisabled(false);
                    dispatch(getSellerBrands(sellerState.id));
                    closeDialog();
                }
            });
        }
    };
    const openDialog = (param=null)=>{
        dispatch(openBrandDialog(param));
    }
    const deleteBrand = (item)=>{
        SweetAlert.fire({
            title: `Delete - ${item.name}`,
            text: "Once deleted, you will not be able to recover brand!",
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    const set={
                        id:sellerState.id,
                        brand_id:item.id
                    }
                    dispatch(deleteSellerBrands(set)).then(res => {
                        if (!res.error) {
                            toast.success("Brand has been deleted!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    });

                }
            })
    }

    const closeDialog = () =>{
        reset(defaultValues);
        dispatch(closeBrandDialog());
    };
    return (
        <Fragment>
            <Row className="mt-3 gridRow">
                {sellerState.sellerBrands? sellerState.sellerBrands.map((item, i) =>
                    <div className="col-md-3" key={i}>
                        <Card>
                            <div className="product-box border">
                                <div className="product-img">
                                    <img style={{height:'150px',width:'15vw',objectFit:'cover'}} src={item.image_path} alt="" />
                                    <div className="product-hover">
                                        <ul>
                                            <li onClick={() => openDialog(item)}>
                                                <Button type="button" color="default" >
                                                    <i className="icon-eye"></i>
                                                </Button>
                                            </li>
                                            <li onClick={() => deleteBrand(item)}>
                                                {/*onClick={() => addWishList(create)} >*/}
                                                <Button type="button" color="default" data-al={item.product}  >
                                                    <i className="icon-close"></i>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-details">
                                    <h4 className="font-primary" >{item.name}</h4>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : ''}
                <div className="col-md-3">
                    <Card className="product-box add_brand_btn" onClick={onButtonClick}>
                        <h5 className="text-center my-auto" ><i className="icofont icofont-ui-add"></i> Add Brands</h5>
                    </Card>
                </div>
            </Row>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Brand'
                selector="staff"
                loadingBtn
                btnDisabled={btnDisabled}
                dialog={sellerState.brandDialog}
                isUseSelector
                closeDialog={closeDialog}
            >
                <BrandForm control={control} errors={errors} register={register} setValue={setValue} trigger={trigger} getValues={getValues} />
            </ModalFormSubmit>
        </Fragment>
    );
};