import { combineReducers } from '@reduxjs/toolkit';
import order from "./orderSlice";
import draftOrder from "./draftOrderSlice";
import checkout from "./checkoutSlice";


const orderReducer = combineReducers({
    order,
    draftOrder,
    checkout
});

export default orderReducer;