import React from "react";
import {

    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";

const CommonModal = (props) => {

    const {closeDialog,modalProps,  size="md"} = props;




    return (
        <>
            <Modal className="modal,-lg modal-dialog-centered product-modal" size={size}   {...modalProps.props}>
                <ModalHeader toggle={() => {
                    closeDialog()
                }}>
                    {modalProps.title}
                </ModalHeader>
                <ModalBody>

                    {props.children}
                </ModalBody>
            </Modal>
        </>);
}
export default CommonModal;