import {combineReducers} from "redux";
import sellerLead from "./sellerLeadSlice";
import buyerLead from "./buyerLeadSlice"


const leadReducer = combineReducers({
    sellerLead,
    buyerLead
});

export default leadReducer;