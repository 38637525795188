import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addShippingZoneDataApi, deleteShippingZoneApi, getAllSellersApi,
    getCountryListApi, getCurrentSellerIdApi, getCurrentSellerIdForSellerVatApi,
    getSelectedShippingZoneDataApi,
    getShippingZoneDataApi, updateShippingZoneDataApi
} from "../../../services/shippingZone";
import {addSellerVatApi} from "../../../services/sellerVat";
import {toast} from "react-toastify";
import {mapShippingRateResponseToTable} from "../../../utils/mapper";
import se from "react-datepicker";



export const getCountryList = createAsyncThunk('shippingZone/getCountryList', async (seller_id,{dispatch}) => {
    const response = await getCountryListApi(seller_id)
    return response.response;
});

export const getShippingZoneData = createAsyncThunk('shippingZone/getShippingZoneData', async (shippingZone,{dispatch}) => {
    const response = await getShippingZoneDataApi(shippingZone)
    return response.response;
});

export const getSelectedShippingZoneData = createAsyncThunk('shippingZone/getSelectedShippingZoneData', async (shippingZone,{dispatch}) => {
    const response = await getSelectedShippingZoneDataApi(shippingZone.id,shippingZone.seller_id);
    return response.response;
});

export const getCurrentSellerId = createAsyncThunk('shippingZone/getCurrentSellerId', async (id,{dispatch}) => {
    const response = await getCurrentSellerIdApi(id);
    return response.response;
});

export const getCurrentSellerIdForSellerVat = createAsyncThunk('shippingZone/getCurrentSellerIdForSellerVat', async (id,{dispatch}) => {
    const response = await getCurrentSellerIdForSellerVatApi(id);
    return response.response;
});

export const getAllSellers = createAsyncThunk('shippingZone/getAllSellers', async (data,{dispatch}) => {
    const response = await getAllSellersApi();
    return response.response;
});


export const addShippingZone = createAsyncThunk('shippingZone/addShippingZone', async (shippingZone, {dispatch}) => {
    try {
        const  response = await addShippingZoneDataApi(shippingZone, shippingZone.seller_id);
        const data = await response.response;
        toast.success("Seller Shipping Zone added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const updateShippingZone = createAsyncThunk('shippingZone/updateShippingZone', async (shippingZone, {dispatch}) => {
    try {
        const  response = await updateShippingZoneDataApi(shippingZone,shippingZone.id)
        const data = await response.response;
        toast.success("Seller Shipping Zone updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const deleteShippingZone = createAsyncThunk('shippingZone/deleteShippingZone', async (shippingZone, {dispatch}) => {
    try {
        const  response = await deleteShippingZoneApi(shippingZone.id)
        const data = await response.response;
        getShippingZoneData(shippingZone.seller_id);
        toast.success("Seller Shipping Zone deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

const shippingZoneAdapter = createEntityAdapter({});

export const {
    selectAll: selectShippingZones,
    selectById: selectShippingZoneById
} = shippingZoneAdapter.getSelectors(
    state => state.seller.shippingZone
);

const shippingZoneSlice = createSlice({
    name: 'shippingZone',
    initialState: shippingZoneAdapter.getInitialState({
        countryList: '',
        rates: [{}],
        sellers: ''
    }),
    reducers: {
        setShippingRates: (state, action) => {
            state.rates = mapShippingRateResponseToTable(action.payload)
        },
        addNewShipping:(state, action) => {
            state.rates = [{}];
        },
        getAllSellersResponse: (state, action) =>{
            state.sellers = action.payload
        }
    },
    extraReducers:{
        [getShippingZoneData.fulfilled]: shippingZoneAdapter.setAll
    }
});

export const {
    setShippingRates,
    addNewShipping,
    getAllSellersResponse
} = shippingZoneSlice.actions;

export default shippingZoneSlice.reducer;