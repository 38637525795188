import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import {useDispatch, useSelector} from "react-redux";
import {addPartner, getPartnerSettings, setSettings} from "../store/partnerSlice";
import withReducer from "../../../store/withReducer";
import partnerReducer from "../store";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Switch from "react-switch";
import {editorConfiguration} from "../../../utils/common";


export const defaultValues = {
    name: '',
    email: '',
    number: '',
    pdCountry: '',
    isActive: false,
    isFormReturn: false,
};

/**
 * Form Validation Schema
 */
const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    email: yup.string().required('Email is required field'),
    number: yup.string().required('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    pdCountry: yup.object().required('Country is required field').typeError('Please select Country'),
    isActive: yup.bool().nullable(),
    isFormReturn: yup.bool().nullable(),
});


const AddPartner = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Partners',
            link: '/partner'
        },
        {
            type: 0,
            text: "Add Partner"
        }
    ];
    const { handleSubmit, formState, reset, register, setValue , getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const [storeDescription,setStoreDescription] = useState('');
    const [sellerDescription,setSellerDescription] = useState('');
    const [otherPolicy,setOtherPolicy] = useState('');
    const [sellerSinceDate, setSellerSinceDate] = useState(new Date());
    const [actionBtn,setActionBtn]=useState(0);
    const [isFormReturn,setIsFormReturn]=useState(false);
    const [isActive,setIsActive] = useState(false);
    const [basictooltip, setbasictooltip] = useState(false);
    const { errors } = formState;
    const formRef = useRef();
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const submitBtnSaveExitRef = useRef();
    const dispatch = useDispatch();
    let history = useHistory();
    useEffect(() => {
        dispatch(getPartnerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    const partnerState = useSelector(({ partner }) => partner.partner);


    const onSubmitForm = (data) => {
        const set={
            "name":data.name,
            "email":data.email,
            "number":data.number,
            "pdCountry":data.pdCountry.id,
            "status":isActive,
            "is_return_form":isFormReturn,
            };
        // console.log(set);
        submitBtnSaveRef.current.disabled=true;
        dispatch(addPartner({ ...set })).then(res => {
            if(!res.error){
                reset(defaultValues);               
                history.push('/partner');
            }else{
                // toast.error(res.error.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
            }
            submitBtnSaveRef.current.disabled=false;
        });
    };

    const handleChange = (nextChecked) => {
        console.log(nextChecked)
        setIsActive(nextChecked);
        // setValue('status',nextChecked)
    };

    const handleChangeReturnForm = (nextChecked) => {
        console.log(nextChecked)
        setIsFormReturn(nextChecked);
        // setValue('is_return_form',nextChecked)
    };

    const toggle = () => setbasictooltip(!basictooltip);
    return (
        <Fragment>
            <Breadcrumb title="Add Partner" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Partner Details</p>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Name<span style={{color:"red"}}>*</span></Label>
                                                <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="City Goddess Ltd" {...register('name')}  />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email<span style={{color:"red"}}>*</span></Label>
                                                <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.shopName?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Country<span style={{color:"red"}}>*</span></Label>
                                                <Select placeholder="United Kingdom" getOptionLabel ={(option)=>option.name} defaultValue={getValues('pdCountry')}
                                                        getOptionValue ={(option)=>option.id} {...register("pdCountry")} onChange={(e)=>{
                                                    setValue('pdCountry',e);
                                                    trigger('pdCountry');
                                                    setDialCode(e.dial_code)
                                                }} className={` ${errors.pdCountry ? 'is-invalid' : ''}`} options={partnerState.settings?partnerState.settings.countries:null} />
                                                <div className="invalid-feedback">{errors.pdCountry?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Phone Number<span style={{color:"red"}}>*</span></Label>
                                                <FormGroup className="input-group">
                                                    <span className="input-group-text">{dialCode}</span>
                                                    <input name="phone" type="number" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                                           className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                                           placeholder="Phone Number" {...register('number')}  />
                                                    <div className="invalid-feedback">{errors.number?.message}</div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Active</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChange} checked={isActive} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Return From</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChangeReturnForm} checked={isFormReturn} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={()=>{formRef.current.submit(); setActionBtn(0)}}  className="btn btn-primary btn-block">Save</button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{partner: partnerReducer}])(AddPartner);