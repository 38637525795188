import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import {downloadOrderInvoice, downloadOrderPdf, unSetTrackingData} from "../../store/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";


export const Item = (props) => {
    const {orderState,orderId,retrieveTrackingDetails}=props;
    const dispatch = useDispatch();
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const processPDFDownload =(name)=>{
        const data={
            id:orderId,
            name:name
        }
        console.log(data);
        dispatch(downloadOrderPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_${name.replace(/\s+/g, '-')}_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const handlePackageSlipDownload =()=>{
        processPDFDownload("Package Slip");
    }
    const handlePickingTicketDownload =()=>{
        processPDFDownload("Picking Ticket");
    }
    const handleInvoiceDownload =()=>{
        dispatch(downloadOrderInvoice(orderId)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_Invoice_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const noTracking =()=>{
        dispatch(unSetTrackingData());
        toast.warning("Tracking details are required to be added to this item", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h5 ribbon-dark">Items from Order {orderState.data?orderState.data.order_name:"-"}</div>
                        {orderState.data?orderState.data.customer_name!=="-" && <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={handleInvoiceDownload} className="btn btn-success btn-block">Invoice</button>
                            </div>
                            {(currentUser.permission).includes('Create Packing Slips') && <div className="p-2">
                                <button onClick={handlePackageSlipDownload} className="btn btn-danger btn-block">Packaging Slip</button>
                            </div>}
                            {(currentUser.permission).includes('Create Picking Ticket') && <div className="p-2">
                                <button onClick={handlePickingTicketDownload} className="btn btn-primary btn-block">Picking Ticket</button>
                            </div>}
                        </div>:""}

                        <div className="table-responsive">
                            <Table>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Color</th>
                                        <th scope="col">Size</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Product</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Price (£)</th>
                                        <th scope="col">Total (£)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {orderState.list?(orderState.list.map((item,index)=>{
                                    return (<tr key={index}>
                                        <td className="col-2">{item.sku}</td>
                                        <td className="col-4">
                                            {(() => {
                                                console.log(item.status_id);
                                                if (item.status_id===1) {
                                                    return (
                                                        <i onClick={noTracking} className="fa fa-truck mr-1 text-primary"></i>
                                                    )
                                                } else if (item.status_id===2) {
                                                    return (
                                                        <a onClick={() => {retrieveTrackingDetails(item.id)}} ><i className="fa fa-truck mr-1 text-success"></i></a>
                                                    )
                                                } else {
                                                    return (
                                                        <i className="fa fa-truck mr-1 text-dark"></i>
                                                    )
                                                }
                                            })()}
                                           {item.name}
                                        </td>
                                        <td className="col-1">{item.colour}</td>
                                        <td>{item.size}</td>
                                        <td>{item.status}</td>
                                        <td><img width={30} className="img-thumbnail" src={item.image}/></td>
                                        <td>{item.quantity}</td>
                                        <td>{item.price}</td>
                                        <td>{item.total_price}</td>
                                    </tr>)
                                    })):null
                                }
                                    <tr></tr>
                                    <tr>
                                        <td ></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="font-weight-bold">Sub Total:</td>
                                        <td>{orderState.data?orderState.data.total_line_items_amount:""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Shipping Charge:</td>
                                        <td>{orderState.data?orderState.data.shipping_charges.toFixed(2):""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Total:</td>
                                        <td>{orderState.data?orderState.data.total_price.toFixed(2):""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};