import API from "../../../utils/api";


export const getProductCategoryApi = async (filters) => {
    const response = await API.get(`product-category/product-category`, { params: filters });
    return { response };
};

export const addProductCategoryApi = async (location) => {
    const response = await API.post(`product-category/product-category`, location);
    return { response };
};

export const updateProductCategoryApi = async (location,id) => {
    const response = await API.put(`product-category/product-category/${id}`, location);
    return { response };
};

export const deleteProductCategoryApi = async (id) => {
    const response = await API.delete(`product-category/product-category/${id}`);
    return { response };
};

export const typeAutoSuggestionApi = async (text) => {
    const response = await API.get('product-category/type-auto-search', text);
    return { response };
};

export const categoryAutoSuggestionApi = async (text) => {
    const response = await API.post('product-category/category-auto-search', text);
    return { response };
};



