import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";
import CommonDataTable from "../../share-components/table/commonDataTable";
import {mapPartnerResponseToTable} from "../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../store/withReducer";
// import useNavigate from 'react-router-dom';

import partnerReducer from "./store";

import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../share-components/ui/preLoader";
import SweetAlert from "sweetalert2";
import { getPartners, selectPartner, updateStatus } from "./store/partnerSlice";
import SortableTable from './content/shortableTable';

const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Partner = (props) => {
    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [search,setSearch]=useState(null);
    const [loading, isSetLoading] = useState(true);
    let history = useHistory();
    const partnerState = useSelector(({ partner }) => partner.partner);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const partnerData = useSelector(selectPartner);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [isActive,setIsActive] = useState(false);
    const [status,setStatus] = useState(null);
    const dataMenu = [
        {
            type: 0,
            text: 'Partners',
        }
    ];

    useEffect(() => {
        getData();
    }, [page, search, isActive, status]);

    const getData = () =>{
        const data={
            page:page,
            pageSize:10,
            search:search,
            status:status
        }
        dispatch(getPartners(data)).then(res=>{
            if(!res.error)
                isSetLoading(false);
        });
    }

    const onEdit = (id) => {
        history.push('partner/'+id+'/edit');
    }
    const onView = (row) => {
        history.push('partner/'+row+'/view');
    }
    const onActiveDeactivate  = (data) => {
        dispatch(updateStatus(data));
    }
    const onAdd = () => {
        history.push('partner/add');
    }

    const statusOptions = [
        {id: 'active', label: 'Active'},
        {id: 'in_active', label: 'In-Active'},
    ]
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Partners" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        
                        <Card>
                            <Row>
                                <Col sm="12">
                                    <div className='float-sm-right p-3'>
                                        <a onClick={onAdd} className="btn btn-sm btn-primary"> <i className="fa fa-plus"></i> Add Partner</a>
                                    </div>
                                </Col>
                            </Row>  
                        </Card>

                        <Row>
                            <Col sm="6">
                                <Select
                                    className="select2-filter border"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles} placeholder="Select Status" 
                                    getOptionLabel ={(option)=>option.label}
                                    getOptionValue ={(option)=>option.id} 
                                    onChange={(e)=>{
                                        setStatus(e?e.id:null);
                                        setPage(1);
                                    }} 
                                    options={statusOptions} 
                                />
                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{setSearch(e.target.value)}}
                                            placeholder="Search partner Name/ Phone"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                

                                <SortableTable partnerState={partnerState} onEdit={onEdit} onView={onView}
                                onActiveDeactivate={onActiveDeactivate} 
                                isActive={isActive} setIsActive={setIsActive}/>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{partner: partnerReducer}])(Partner);