import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductSeasonApi, deleteProductSeasonApi,
    getProductSeasonsApi,
    updateProductSeasonApi
} from "../../../services/manage-product/setting/productSeasonService";
import {toast} from "react-toastify";


export const getAllProductSeasons = createAsyncThunk('productSeason/getProductSeason', async (filters, {dispatch})=> {
    const response = await getProductSeasonsApi(filters,'season');
    return response.response;
});

export const addProductSeason = createAsyncThunk('productSeason/addProductSeason', async (productSeason,{
    dispatch,
    getState
}) => {
    try {
        const response = await addProductSeasonApi(productSeason);
        const data = await response.response;
        dispatch(getAllProductSeasons());
        toast.success("Add product season successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductSeason = createAsyncThunk('productSeason/updateProductSeason', async (productSeason, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductSeasonApi(productSeason, productSeason.id);
        const data = await response.response;
        dispatch(getAllProductSeasons());
        toast.success("Update product season successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductSeason = createAsyncThunk('productSeason/deleteProductSeason', async (productSeason, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductSeasonApi(productSeason, productSeason.id);
        const data = await response.response;
        dispatch(getAllProductSeasons());
        toast.success("Delete product season successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productSeasonAdapter = createEntityAdapter({});

export const {selectAll:selectProductSeason, selectById: selectProductSeasonById} = productSeasonAdapter.getSelectors(
    state => state.product.productSeason
);

const productSeasonSlice = createSlice({
    name: 'productSeason',
    initialState: productSeasonAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productSeason',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductSeasonSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productSeason',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductSeasons.fulfilled]: productSeasonAdapter.setAll
    }
});

export const {
    selectProductSizeSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productSeasonSlice.actions;


export default productSeasonSlice.reducer;

