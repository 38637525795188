import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {
    addProductColorApi, deleteProductColorApi,
    getProductColorApi,
    updateProductColorApi
} from "../../../services/manage-product/setting/productColorService";


export const getAllProductColors = createAsyncThunk('productColor/getProductColor', async (filters, {dispatch}) => {
    const response = await getProductColorApi(filters);
    return response.response;
});

export const addProductColor = createAsyncThunk('productColor/addProductColor', async (productColor, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductColorApi(productColor);
        const data = await response.response;
        dispatch(getAllProductColors());
        toast.success("Add product color successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const updateProductColor = createAsyncThunk('productColor/updateProductColor', async (productColor, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductColorApi(productColor, productColor.id);
        const data = await response.response;
        dispatch(getAllProductColors());
        toast.success("Update product color successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const deleteProductColor = createAsyncThunk('productColor/deleteProductColor', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductColorApi(id);
        const data = await response.response;
        dispatch(getAllProductColors());
        toast.success("Delete product color successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

const productColorAdapter = createEntityAdapter({});

export const {selectAll: selectProductColor, selectById: selectProductColorById} = productColorAdapter.getSelectors(
    state => state.product.productColor
);

const productColorSlice = createSlice({
    name: 'productColor',
    initialState: productColorAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productColor',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductColorSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productColor',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductColors.fulfilled]: productColorAdapter.setAll
    }
});

export const {
    selectProductColorSearchText, openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productColorSlice.actions;

export default productColorSlice.reducer;