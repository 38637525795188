import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Form, Button,

} from 'reactstrap'
import withReducer from "../../../../store/withReducer";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {useHistory, useParams} from "react-router-dom";
import PreLoader from "../../../../share-components/ui/preLoader";
import draftOrderReducer from "../../store";
import Select from "react-select";
import {addDraftOrders, getDraftOrder, setSearchProductDialog, updateDraftOrder} from "../../store/draftOrderSlice";
import SearchProductModal from "./content/searchProductModal";
import OrderProductJqx from "./content/orderProductJqx";
import {mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {sellerBrandSuggestionApi} from "../../../../services/manage-product/productService";
import {customerAutoSuggestionApi} from "../../../../services/order";
import AsyncSelect from "react-select/async";
import TagsInput from "react-tagsinput";
import OrderPayment from "./content/orderPayment";
import {string} from "yup";
import {getProduct, setProductSubCategory} from "../../../manage-product/store/prodcutSlice";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import OrderDiscount from "./content/orderDiscount";
import OrderShipment from "./content/orderShipment";
import OrderTax from "./content/orderTax";


const defaultValues = {
    id: '',
    ordered_products: [],
    tags: [],
    notes: "",
    discount: "",
    shipping: "",
    tax:"",
    is_tax: false,
    is_paid:false,
    customer:"",
    shopify_draft_order_id:""


};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
});
const dataMenu = [
    {
        type: 1,
        text: 'Draft Order',
        link: '/order/draft'
    },
    {
        type: 0,
        text: "Draft Order Form"
    }
];

const DraftOrderForm = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    let searchProductRef = useRef();

    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors, isDirty} = formState;
    useEffect(() => {
         if(id) {
             dispatch(getDraftOrder(id)).then(res => {
                 if (!res.error) {
                     reset(res.payload);
                     setLoading(false);
                 }
             });
         }else{
             setLoading(false);
         }
    }, [dispatch]);

    const onSubmit = (data) => {
        setButtonDisable(true);
        let line_items=[];
        (data.ordered_products).map((item,index)=>{
           line_items.push({variant_id:item.variant_id,quantity:item.quantity,price:item.price});
        });
        data.ordered_products=line_items;
        data.customer=data.customer.value;
        if(data.id){
            dispatch(updateDraftOrder({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/draft')
                }
                ;
            });
        }else {
            dispatch(addDraftOrders({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/draft')
                }
                ;
            });
        }

    }
    const openSearchProductDialog = () => {
        const obj = {props: {isOpen: true}, data: {searchValue: searchProductRef.current.value}}
        dispatch(setSearchProductDialog(obj));
    }

    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(customerAutoSuggestionApi(data)))
            });
        });
    return (
        <Fragment>
            <Breadcrumb title={'Draft Order'} data={dataMenu}/>
            <Container fluid={true}>
                {loading ? <>
                        <PreLoader></PreLoader>
                    </> :

                    <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm="8">
                                <Row>
                                    <Col sm="12">
                                        <Card>

                                            <CardBody>
                                                <div className="form-row mb-3 ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Products</Label>
                                                            <Row>
                                                                <Col md="10 input-with-icon pr-1">
                                                                    <div>
                                                                        <FormGroup className="m-0">
                                                                            <i className="fa fa-search"></i>
                                                                            <input name="searchProduct" type="text"
                                                                                   placeholder="search Products"
                                                                                   ref={ref => {
                                                                                       searchProductRef.current = ref;
                                                                                   }}
                                                                                   onKeyUp={openSearchProductDialog}
                                                                                   className={`form-control`}/>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col md="2 pl-0">
                                                                    <Button color="primary" type="button"
                                                                            className="btn btn-sm btn-primary btn-block"
                                                                            onClick={openSearchProductDialog}
                                                                            style={{height: 38}}>Browse</Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <OrderProductJqx setValue={setValue}
                                                                                     getValues={getValues}
                                                                                     trigger={trigger}
                                                                                     orderedProducts={getValues('ordered_products')}
                                                                    ></OrderProductJqx>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {getValues('ordered_products').length > 0 && <><Row>
                                    <Col sm="12">
                                        <OrderPayment setValue={setValue} getValues={getValues}
                                                      trigger={trigger}></OrderPayment>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <div className="float-right" style={{display:"flex"}}>
                                                        <div className="checkbox checkbox-dark mr-5">
                                                            <input id="is_paid" type="checkbox"  onChange={(e)=>{
                                                                      setValue('is_paid',e.target.checked)
                                                            }} defaultChecked={getValues('is_paid')}/>
                                                            <Label for="is_paid">Mark As Paid</Label>
                                                        </div>
                                                        <ButtonLoader disabled={buttonDisable}  color="primary"  value="1"	type="submit"  btntext="Create Draft Order"></ButtonLoader>
                                                    </div>

                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </>
                                }

                            </Col>
                            <Col sm="4">
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Customer</Label>

                                                            <AsyncSelect
                                                                className={` ${errors.customer ? 'is-invalid' : ''}`}
                                                                isClearable
                                                                cacheOptions
                                                                defaultOptions
                                                                defaultValue={getValues('customer')}
                                                                loadOptions={typePromiseOption}
                                                                onChange={(e) => {
                                                                    setValue('customer', e);

                                                                }}

                                                            />
                                                            <div className="invalid-feedback">{errors.customer?.message}</div>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Notes</Label>
                                                            <input name="notes" type="text" {...register('notes')}
                                                                   className={`form-control`}/>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Tags</Label>
                                                            <TagsInput className="form-control tag-input-custom"
                                                                       onlyUnique={true} value={getValues('tags')}
                                                                       onChange={(value) => {
                                                                           // alert(JSON.stringify(value));
                                                                           setValue('tags', value)
                                                                           trigger('tags');
                                                                           // combineTags();
                                                                       }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Form>


                }
            </Container>
            <SearchProductModal setValue={setValue} getValues={getValues} trigger={trigger}></SearchProductModal>
            <OrderDiscount orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderDiscount>
            <OrderShipment orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderShipment>
            <OrderTax orderSetValue={setValue} orderTrigger={trigger}
                      orderGetValues={getValues}></OrderTax>



        </Fragment>
    );

}

export default withReducer([
    {
        order: draftOrderReducer
    }
])(DraftOrderForm);