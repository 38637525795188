import React, {Fragment, useEffect, useState} from 'react';
import {
    Form,
    FormGroup, Label
} from 'reactstrap'


import { useDispatch} from "react-redux";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import {
    updateProfile
} from "../../store/userSlice";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yup.string().required('Full name is required field'),
    email: yup.string(),
});

const UserDetail = (props) => {
    const {name,email}=props;

    const [btnLoading,setBtnLoading]=useState(false);
    const defaultValues = {
        name: name,
        email:email,
    };


    const { handleSubmit, formState, reset, setValue, register} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const dispatch = useDispatch();

    const onSubmitForm = (data) => {
        setBtnLoading(true);
        dispatch(updateProfile({ ...data })).then(res => {
            reset({...data});
            setBtnLoading(false);
        });
    };

    return (
        <Form className="mt-3" onSubmit={handleSubmit(onSubmitForm)}>
            <Fragment>
                <FormGroup>
                    <Label className="form-label">Full Name</Label>
                    <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('name')}  />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </FormGroup>
                <FormGroup>
                    <Label className="form-label">Email Address</Label>
                    <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="your-email@domain.com" {...register('email')}  />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </FormGroup>
                <div className="form-footer">
                    <ButtonLoader btntext="Update" className="btn btn-primary btn-block"     disabled={btnLoading} type="submit" />
                </div>
            </Fragment>
        </Form>
    );

};
export default (UserDetail)


