import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";
import CommonDataTable from "../../share-components/table/commonDataTable";
import {mapSellerRoleResponseToTable} from "../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../store/withReducer";
// import useNavigate from 'react-router-dom';

import sellerReducer from "./store";
import {
    emailNewPassword,
    getSellers,
    getSellerSettings,
    selectSeller,
    selectSellerById,
    setSettings
} from "./store/sellerSlice";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../share-components/ui/preLoader";
import SweetAlert from "sweetalert2";


const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Seller = (props) => {
    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [brand,setBrand]=useState(null);
    const [search,setSearch]=useState(null);
    const [loading, isSetLoading] = useState(true);
    let history = useHistory();
    const sellerState = useSelector(({ seller }) => seller.seller);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const sellerData=useSelector(selectSeller);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const dataMenu = [
        {
            type: 0,
            text: 'Sellers',
        }
    ];

    const tableColumns = [
        {
            name: 'Brand Name',
            // selector: row => row.brandName,
            selector: row => (row.brandList? (<PopoverBasicItem id={row.id} Popoverbody={row.brandList} btntext={row.brandName}/>):row.brandName),
            sortable: true,
            center: false,
        }, {
            name: 'Brand List',
            selector: row => row.brandList,
            sortable: true,
            center: false,
            omit:true
        },
        {
            name: 'Shop Name',
            selector: row => row.shopName,
            sortable: true,
            center: false,
        },
        {
            name: 'Seller Name',
            selector: row => row.sellerName,
            sortable: true,
            center: false,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
            center: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row =>
                <div>
                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Update Seller")!==-1?
                            <a onClick={() => {
                                onEdit(row);
                            }}><i className="fa fa-pencil"
                                  style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>:
                            "")
                    }

                    {
                        userState.user &&
                        (userState.user.permission.indexOf("View Seller")!==-1?
                        <a onClick={() => {
                            onView(row);
                        }}><i className="fa fa-eye"
                              style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>:
                            "")
                    }

                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Reset Seller Password")!==-1?
                        <a onClick={() => {
                            onPasswordReset(row);
                        }}><i className="fa fa-key"
                              style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>:
                            "")
                    }

                </div>,
            sortable: false,
            center: true,
        }
    ];

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);


    useEffect(() => {
        getData();
    }, [page,brand,search]);

    const getData = () =>{
        const data={
            page:page,
            pageSize:10,
            brand:brand,
            search:search
        }
        dispatch(getSellers(data)).then(res=>{
            if(!res.error)
                isSetLoading(false);
        });
    }

    const onEdit = (row) => {
        history.push('seller/'+row.id+'/edit');
    }
    const onView = (row) => {
        history.push('seller/'+row.id+'/view');
    }
    const onAdd = () => {
        history.push('seller/add');
    }
    const onPasswordReset = (row) => {
        SweetAlert.fire({
            title: `Password Reset - ${row.sellerName}`,
            text: "New password credentials will be sent to the registered email!",
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if(result.isConfirmed){
                    dispatch(emailNewPassword(row.user)).then( res=>{
                        if (!res.error) {

                        }
                    });
                }
            });
    }
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Sellers" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        {(currentUser.permission).includes('Create Seller') ?
                        <Card>
                            {
                                userState.user && (userState.user.permission.indexOf("Add Seller")!==-1?
                                    <Row>
                                        <Col sm="12">
                                            <div className='float-sm-right p-3'>
                                                <a onClick={onAdd} className="btn btn-sm btn-primary"> <i className="fa fa-plus"></i> Add Seller</a>
                                            </div>
                                        </Col>
                                    </Row>
                                    :"")
                            }
                        </Card>
                            :''}
                        <Row>
                            <Col sm="6">
                                <Select         
                                    // components={{
                                    //     IndicatorSeparator: () => null
                                    // }} 
                                    className="select2-filter border"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles} placeholder="Select Brand" 
                                    getOptionLabel ={(option)=>option.name}
                                    getOptionValue ={(option)=>option.id} 
                                    onChange={(e)=>{
                                        setBrand(e?e.id:null);
                                        setPage(1);
                                    }} 
                                    options={sellerState.settings?sellerState.settings.brands:null} 
                                />
                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{setSearch(e.target.value)}}
                                            placeholder="Search Seller Name/ Phone"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <CommonDataTable
                                        headerColumns={tableColumns}
                                        gridData={mapSellerRoleResponseToTable(sellerData)}
                                        onEdit={(currentUser.permission).includes('Update Seller') ? onEdit:''}
                                        onView={(currentUser.permission).includes('View Seller') ? onView:''}
                                        noAction
                                        paginationServer
                                        paginationTotalRows={sellerState.sellerTableRow}
                                        paginationPerPage={10}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPage(page)}
                                    />

                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{seller: sellerReducer}])(Seller);