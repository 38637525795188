import {Row} from "react-bootstrap";

const {useFieldArray} = require("react-hook-form");
const {Label, FormGroup} = require("reactstrap");
const React = require("react");
const {Col} = require("react-bootstrap");


const CountryList = ({nestIndex, control, register}) => {

    const {fields} = useFieldArray({
        control,
        name: `countryList[${nestIndex}].children`
    });

    return (
        <div>
            <Row>
                {fields?.map((childItem, childIndex) =>
                    <Col md="4" key={childItem.id}>

                            <input type="checkbox"
                                className="" disabled={childItem.status}
                                {...register(`countryList.${nestIndex}.children[${childIndex}].value`)}
                                defaultValue={childItem.value}
                                style={{ marginRight: "25px" }}
                            /><Label>{childItem.name}</Label>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default (CountryList)