import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import CKEditors from "react-ckeditor-component";
import './ckeditorStyles.css';

const editorConfiguration = {
    toolbarGroups: [

        '/',
        '/'
    ],
    readOnly:true,
    removeButtons: 'Maximize,Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles'
};


export const ViewHeadingLinks = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-dark">Heading & Links</div>
                        <Row>
                            <Col md={12}>
                                {
                                    sellerDetails.returnCancellationPolicyHeading &&
                                    <FormGroup>
                                        <Label className="form-label font-weight-bold">Heading</Label>
                                        <p>{sellerDetails.returnCancellationPolicyHeading}</p>
                                    </FormGroup>
                                }
                                <p className="sub-title">Return & Cancellation Policy</p>

                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Description</Label>
                                    <CKEditors
                                        config={editorConfiguration}
                                        disabled={true}
                                        activeclassName="p10"
                                        content={sellerDetails.returnCancellationPolicy}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <div className="table-responsive">
                                    <Table hover>
                                        <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">{"#"}</th>
                                            <th scope="col">{"Heading"}</th>
                                            <th scope="col">{"Link"}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">{"Seller Profile"}</th>
                                            <td>
                                                <p>{sellerDetails.sellerProfileHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.sellerProfileLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Seller Shop"}</th>
                                            <td>
                                                <p>{sellerDetails.sellerShopHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.sellerShopLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Brand Size Chart"}</th>
                                            <td>
                                                <p>{sellerDetails.brandSizeChartHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.brandSizeChartLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Size Chart"}</th>
                                            <td>
                                                <p>{sellerDetails.sizeChartHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.sizeChartLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Promotion"}</th>
                                            <td>
                                                <p>{sellerDetails.promotionHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.promotionLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Trade Show"}</th>
                                            <td>
                                                <p>{sellerDetails.tradeShowHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.tradeShowLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Designed & Made In"}</th>
                                            <td>
                                                <p>{sellerDetails.designedMadeInHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.designedMadeInLink}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{"Shipping Validity"}</th>
                                            <td>
                                                <p>{sellerDetails.shippingValidityHeading}</p>
                                            </td>
                                            <td>
                                                <p>{sellerDetails.shippingValidityLink}</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};