import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {showPartner} from "../store/partnerSlice";
import withReducer from "../../../store/withReducer";
import sellerReducer from "../store";

import "react-datepicker/dist/react-datepicker.css";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ViewDetails} from "./viewDetails";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import PreLoader from "../../../share-components/ui/preLoader";


const ViewPartner = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Partner',
            link: '/partner'
        },
        {
            type: 0,
            text: "View Partner"
        }
    ];
    const { id } = useParams();
    const dispatch = useDispatch();
    const [partnerDetails,setPartnerDetails] = useState(null);
    const [loading, isSetLoading] = useState(true);

    useEffect(() => {
        if(id){
            dispatch(showPartner(id)).then(res => {
                if (!res.error) {
                    setPartnerDetails(res.payload);
                    isSetLoading(false);
                }
            });
        }
    },[dispatch]);

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb title="View Partner" data={dataMenu}/>
                    <Container fluid={true}>
                        <Row>
                            <ViewDetails partnerDetails={partnerDetails} />
                        </Row>
                    </Container>
            </>
            }
        </Fragment>
    );
}

export default withReducer([{seller: sellerReducer}])(ViewPartner);