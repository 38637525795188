import React from 'react';
import CommonDataTable from "../../../share-components/table/commonDataTable";
;



function BackInStockTable(props) {
    const {handleChange,tableColumns,backStockData,backInRequestState,onView,pageChange,onPageSizeChange,toggleRowSelected, idArray}=props;

    return (
        <CommonDataTable
            headerColumns={tableColumns}
            gridData={backStockData}
            paginationServer
            paginationTotalRows={backInRequestState.backInStockTableRow}
            noAction
            selectableRows
            onSelectedRowsChange={handleChange}
            onView={onView}
            onChangePage={pageChange}
            onChangeRowsPerPage={onPageSizeChange}
            toggleRowSelected={toggleRowSelected}
            selectableRowSelected={(row)=>{
                return (idArray.idArray).includes(row.id)
            }}
        />
    );
}
function areEqual(prevProps, nextProps) {
    // console.log(prevProps.remove, nextProps.remove)
    return JSON.stringify(prevProps.backStockData) == JSON.stringify(nextProps.backStockData) && prevProps.remove == nextProps.remove;
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(BackInStockTable, areEqual);