import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useState} from "react";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";
import DatePicker from "react-datepicker";

export const SellerStoreDescription = (props) => {
    const {errors,register,trigger,setValue,getValues,sellerSinceDate,setSellerSinceDate}=props;


    const editorConfiguration = {
        toolbarGroups: [
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
            {name: 'forms', groups: ['forms']},
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
            {name: 'links', groups: ['links']},
            {name: 'insert', groups: ['insert']},
            {name: 'styles', groups: ['styles']},
            {name: 'colors', groups: ['colors']},
            {name: 'tools', groups: ['tools']},
            {name: 'others', groups: ['others']},
            {name: 'about', groups: ['about']},
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            '/',
            '/'
        ],
        removeButtons: 'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles'

    };

    const onChangeStoreDescription = (evt) => {
        const newContent = evt.editor.getData();
        setValue('storeDescription',newContent);
    }


    const onChangeSellerDescription = (evt) => {
        const newContent = evt.editor.getData();
        setValue('sellerDescription',newContent);
    }

    const onChangeOtherInformation = (evt) => {
        const newContent = evt.editor.getData();
        setValue('otherInformation',newContent);
    }

    const handleSellerSinceChange = date => {
        // setValue('sellerSince',date);
        setValue('sellerSince',`${date.getFullYear()}-${(date.getMonth()+1)<2?'0'+(date.getMonth()+1):(date.getMonth()+1)}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`);
        trigger('sellerSince');
        setSellerSinceDate(date);
    };

    const onChangeOtherPolicy = (evt) => {
        const newContent = evt.editor.getData();
        setValue('otherPolicy',newContent);
    }


    return (
        <Fragment>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Store Description Heading</Label>
                        <input name="storeDescriptionHeading" type="text" className={`form-control ${errors.storeDescriptionHeading ? 'is-invalid' : ''}`} placeholder="" {...register('storeDescriptionHeading')}  />
                        <div className="invalid-feedback">{errors.storeDescriptionHeading?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Seller Description Heading</Label>
                        <input name="sellerDescriptionHeading" type="text" className={`form-control ${errors.sellerDescriptionHeading ? 'is-invalid' : ''}`} placeholder="" {...register('sellerDescriptionHeading')}  />
                        <div className="invalid-feedback">{errors.sellerDescriptionHeading?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Store Description<span style={{color:"red"}}>*</span></Label>

                        <CKEditors
                            config={editorConfiguration}
                            activeclassName="p10"
                            content={getValues('storeDescription')}
                            {...register('storeDescription')}
                            events={{
                                "change": onChangeStoreDescription
                            }}
                        />
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.storeDescription?.message}</div>
                    </FormGroup>
                </Col>


                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Seller Description<span style={{color:"red"}}>*</span></Label>

                        <CKEditors
                            config={editorConfiguration}
                            activeclassName="p10"
                            content={getValues('sellerDescription')}
                            {...register('sellerDescription')}
                            events={{
                                "change": onChangeSellerDescription
                            }}
                        />
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.sellerDescription?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Other Information Heading</Label>
                        <input name="otherInformationHeading" type="text" className={`form-control ${errors.otherInformationHeading ? 'is-invalid' : ''}`} placeholder="" {...register('otherInformationHeading')}  />
                        <div className="invalid-feedback">{errors.otherInformationHeading?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Other Policy Heading</Label>
                        <input name="otherPolicyHeading" type="text" className={`form-control ${errors.otherPolicyHeading ? 'is-invalid' : ''}`} placeholder="" {...register('otherPolicyHeading')}  />
                        <div className="invalid-feedback">{errors.otherPolicyHeading?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Other Information</Label>

                        <CKEditors
                            config={editorConfiguration}
                            activeclassName="p10"
                            content={getValues('otherInformation')}
                            {...register('otherInformation')}
                            events={{
                                "change": onChangeOtherInformation
                            }}
                        />
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.otherInformation?.message}</div>
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Other Policy<span style={{color:"red"}}>*</span></Label>

                        <CKEditors
                            activeclassName="p10"
                            content={getValues('otherPolicy')}
                            {...register('otherPolicy')}
                            events={{
                                "change": onChangeOtherPolicy
                            }}
                        />
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.otherPolicy?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Delivery Days</Label>
                        <input name="deliveryDays" type="text" className={`form-control ${errors.deliveryDays ? 'is-invalid' : ''}`} placeholder="10" {...register('deliveryDays')}  />
                        <div className="invalid-feedback">{errors.deliveryDays?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Delivery Method Name</Label>
                        <input name="deliveryMethodName" type="text" className={`form-control ${errors.deliveryMethodName ? 'is-invalid' : ''}`} placeholder="" {...register('deliveryMethodName')}  />
                        <div className="invalid-feedback">{errors.deliveryMethodName?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Delivery Time</Label>
                        <input name="deliveryTime" type="text" className={`form-control ${errors.deliveryTime ? 'is-invalid' : ''}`} placeholder="" {...register('deliveryTime')}  />
                        <div className="invalid-feedback">{errors.deliveryTime?.message}</div>
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Return Days</Label>
                        <input name="returnDays" type="number" className={`form-control ${errors.returnDays ? 'is-invalid' : ''}`} placeholder="" {...register('returnDays')}  />
                        <div className="invalid-feedback">{errors.returnDays?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Refund Days</Label>
                        <input name="refundDays" type="number" className={`form-control ${errors.refundDays ? 'is-invalid' : ''}`} placeholder="" {...register('refundDays')}  />
                        <div className="invalid-feedback">{errors.refundDays?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Seller Since</Label>
                        <DatePicker showMonthDropdown showYearDropdown name="sellerSince" className={`form-control digits ${errors.sellerSince ? 'is-invalid' : ''}`} dateFormat="dd-MM-yyyy" selected={sellerSinceDate} maxDate={new Date()} onChange={handleSellerSinceChange} />
                        <div className="invalid-feedback">{errors.sellerSince?.message}</div>
                    </FormGroup>
                </Col>


            </Row>
        </Fragment>
    );
};