import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../redux/auth/userSlice";
import {notificationMarkAsRead} from "../../services/auth";
import Breadcrumb from "../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Media, Row} from "reactstrap";
import withReducer from "../../store/withReducer";
import notificationReducer from "./store";
import {getReadNotifications, getUnreadNotifications} from "./store/notificationSlice";
import InitialsAvatar from "react-initials-avatar";
import {useHistory} from "react-router-dom";
import ReactPaginate from "react-paginate";
import PreLoader from "../../share-components/ui/preLoader";


function AllNotification() {

    const dispatch = useDispatch();
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [readNotifications, setReadNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [unreadPage, setUnreadPage] = useState(0);
    const [read, setRead] = useState(false);
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    let readNotificationData = useSelector(({notification}) => notification.notifications);

    console.log(readNotificationData)

    const dataMenu = [
        {
            type: 1,
            text: 'All Notifications',
            link: '/view-all-notifications'
        }
    ];

    const pageChange = (event) => {
        setPage(event.selected+1)
    }

    const unreadPageChange = (event) => {
        setUnreadPage(event.selected+1)
    }



    useEffect(() => {
        const data1 = {
            page_size: 8,
            page:page
        }

        const data2 = {
            page_size: 8,
            page:unreadPage
        }
        dispatch(getReadNotifications({...data1})).then(res => {
            if (!res.error) {
                setReadNotifications(res.payload);
            }
        });
        dispatch(getUnreadNotifications({...data2})).then(res => {
            if (!res.error) {
                setUnreadNotifications(res.payload);
                setLoading(false);
            }
        });
        setRead(false);
    }, [dispatch, isSelected, page, unreadPage, read]);




    async function markAsRead(id, url) {
        await notificationMarkAsRead(id).then(res => {
            if (!res.errors) {
                setRead(true);
                const newArray = unreadNotifications.filter(item => item.id !== id)
                dispatch(setNotification(newArray));
                url ? history.push(url) : history.push('/dashboard');

            }
        });

    }

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="All Notifications" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={12}>
                                                <h5 className="f-w-700">Read Notifications</h5>
                                                <ul className="list-group">
                                                    {readNotifications && readNotifications.map(item => (

                                                        <li className="list-group-item-action d-flex align-items-center justify-content-between list-group-item" key={item.id}>
                                                            <div className="d-flex align-items-center flex-grow-1">
                                                                <div className="mr-2">
                                                                    {item.image != '' ? <Media style={{height: 46, width: 46}} className="rounded-circle" alt="user-image" src={item.image} /> : <InitialsAvatar name={item.name}/> }


                                                                </div>
                                                                <div className="d-flex flex-column flex-grow-1">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="font-weight-bold">{item.name}</span>
                                                                    </div>
                                                                    <span className="text-muted font-size-sm">{item.message}</span>
                                                                </div>
                                                            </div>
                                                            <div className="text-muted" style={{fontSize: 12, marginTop:21}}>{item.time}</div>
                                                        </li>


                                                    ))
                                                    }
                                                </ul>
                                            </Col>
                                            <Col md={12}>
                                                <div style={{float: "right", marginTop:10}}>
                                                    <div>
                                                        <ReactPaginate
                                                            className="pagination"
                                                            activeClassName="pagination-active"
                                                            breakLabel="..."
                                                            nextLabel=">"
                                                            onPageChange={pageChange}
                                                            pageRangeDisplayed={5}
                                                            pageCount={readNotificationData.pageCount}
                                                            forcePage={0}
                                                            previousLabel="<"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/*<Col md={1}></Col>*/}
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={12}>
                                                <h5 className="f-w-700">Unread Notifications</h5>
                                                <ul className="list-group">
                                                    {unreadNotifications && unreadNotifications.map(item => (

                                                            <li style={{cursor: "pointer"}} onClick={event => {
                                                                markAsRead(item.id, item.url)
                                                            }} className="list-group-item-action d-flex align-items-center justify-content-between list-group-item" key={item.id}>
                                                                <div className="d-flex align-items-center flex-grow-1">
                                                                    <div className="mr-2">
                                                                        {item.image != '' ? <Media style={{height: 46, width: 46}} className="rounded-circle" alt="user-image" src={item.image} /> : <InitialsAvatar name={item.name}/> }
                                                                    </div>
                                                                    <div className="d-flex flex-column flex-grow-1">
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="font-weight-bold">{item.name}</span>
                                                                        </div>
                                                                        <span className="text-muted font-size-sm">{item.message}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-muted" style={{fontSize: 12, marginTop:21}}>{item.time}</div>
                                                            </li>
                                                    ))
                                                    }
                                                </ul>
                                            </Col>
                                            <Col md={12}>
                                                <div style={{float: "right", marginTop:10}}>
                                                    <div>
                                                        <ReactPaginate
                                                            className="pagination"
                                                            activeClassName="pagination-active"
                                                            breakLabel="..."
                                                            nextLabel=">"
                                                            onPageChange={unreadPageChange}
                                                            pageRangeDisplayed={5}
                                                            pageCount={readNotificationData.unreadPageCount}
                                                            forcePage={0}
                                                            previousLabel="<"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
                </>
            }
        </Fragment>
    );

};

export default withReducer([{notification: notificationReducer}])(AllNotification)