import React, {Fragment, useEffect, useRef, useState} from "react";

import {Badge, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";


const ProductSeo = (props) => {
    const {setValue, trigger, getValues, errors, register} = props;
    const metaTitleLength=getValues('meta_title')?getValues('meta_title').length:0;
    const metaDescriptionLength=getValues('meta_description')?getValues('meta_description').length:0



    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-info ribbon-left mb-5">Seo Related</div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <FormGroup>
                                <Label className="col-form-label">Meta Title <span className="text-limit">(Character Limit: {65}, Remaining: {65-metaTitleLength})</span></Label>
                                <input name="meta_title" type="text" {...register('meta_title')}
                                    maxLength={65}
                                       onKeyUp={()=>{
                                           trigger('meta_title');
                                       }}
                                       className={`form-control ${errors.meta_title ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.meta_title?.message}</div>
                            </FormGroup>
                        </Col>
                    </div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <FormGroup>
                                <Label className="col-form-label">Meta Description <span className="text-limit">(Character Limit: {160}, Remaining: {160-metaDescriptionLength})</span></Label>
                                <textarea {...register('meta_description')} className={`form-control`} rows={3}    maxLength={160}    onKeyUp={()=>{
                                    trigger('meta_description');
                                }}></textarea>
                            </FormGroup>
                        </Col>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default ProductSeo;