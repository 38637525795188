export function editorConfiguration(data = 150) {
    return {
        toolbarGroups: [
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
            {name: 'forms', groups: ['forms']},
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
            {name: 'links', groups: ['links']},
            {name: 'insert', groups: ['insert']},
            {name: 'styles', groups: ['styles']},
            {name: 'colors', groups: ['colors']},
            {name: 'tools', groups: ['tools']},
            {name: 'others', groups: ['others']},
            {name: 'about', groups: ['about']},
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            '/',
            '/'
        ],
        height: data,
        removeButtons: 'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles'
    }
};

export function getFileExtension(url) {
    const uri = url.split('?')[0]
    return uri.substring(uri.lastIndexOf("."))
}

export function getProductRibbonColor(status){
    let statuscolor=[];
    statuscolor[1]="ribbon-warning";
    statuscolor[2]="ribbon-primary";
    statuscolor[3]="ribbon-success";
    statuscolor[4]="ribbon-info";
    statuscolor[5]="ribbon-danger";
    return statuscolor[status];
}
export function numberWithCommas(x) {
    x= x?x.toFixed(2):"";
    return x? "£ "+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"£ 0.00";
}