import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {Fragment} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {openNewDialog} from "../store/prodcutSlice";


const Collection = () => {
    const dispatch = useDispatch();
    let history = useHistory();


    return (
        <Fragment>
            <Breadcrumb parent="Product" title="Collection"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                <div className='float-sm-right m-b-30'>
                                    <a onClick={()=>{dispatch(openNewDialog())}} className="btn btn-sm btn-primary"> <i className="fa fa-plus"></i> Add Collection</a>
                                </div>

                                <div className="table-responsive product-table">


                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default (Collection);