import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import {useDispatch} from "react-redux";


export const ReturnItem = (props) => {
    const {orderState,orderId}=props;
    const dispatch = useDispatch();
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h6 ribbon-dark">Items from Order {orderState.data?orderState.data.order_name:"-"}</div>
                        <div className="table-responsive">
                            <Table>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Color</th>
                                        <th scope="col">Size</th>
                                        <th scope="col">Product</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Price (£)</th>
                                        <th scope="col">Total (£)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {orderState.list?
                                    <tr>
                                        <td className="col-2">{orderState.list.sku}</td>
                                        <td className="col-4">{orderState.list.name}</td>
                                        <td className="col-1">{orderState.list.colour}</td>
                                        <td>{orderState.list.size}</td>
                                        <td><img width={50} className="img-thumbnail" src={orderState.list.product}/></td>
                                        <td>{orderState.list.qty}</td>
                                        <td>{orderState.list.price}</td>
                                        <td>{orderState.list.total_price}</td>
                                    </tr>:null
                                }
                                    <tr></tr>
                                    <tr>
                                        <td ></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="font-weight-bold">Sub Total:</td>
                                        <td>{orderState.list?orderState.list.total_price:""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Shipping Charge:</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Total:</td>
                                        <td>{orderState.list?orderState.list.total_price:""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};