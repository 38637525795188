import { combineReducers } from '@reduxjs/toolkit';
import seller from "./sellerSlice";
import sellerStaff from "./sellerStaffSlice";
import sellerVat from "./sellerVatSlice";
import shippingZone from "./shippingZoneSlice"


const sellerReducer = combineReducers({
    seller,
    sellerStaff,
    sellerVat,
    shippingZone
});

export default sellerReducer;