import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Label, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapOrderResponseToTable, mapReturnResponseToTable, mapSellerRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    getReturns,
    selectOrder, sendReturnItemShopify, setOrderData, setReturnData,

    setSettings
} from "../store/orderSlice";
import {useHistory} from "react-router-dom";
import orderReducer from "../store";
import SweetAlert from "sweetalert2";
import PreLoader from "../../../share-components/ui/preLoader";


const Return = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [page,setPage]=useState(1);
    const [loading, isSetLoading] = useState(true);
    const orderState = useSelector(({ order }) => order.order);
    const dataMenu = [
        {
            type: 0,
            text: 'Returns',
        }
    ];

    const tableColumns = [
        {
            name: 'Order ID',
            width:"9%",
            selector: row => row.orderName,
            sortable: true,
            cell:(row)=><a className="text-primary" style={{cursor:"pointer"}} onClick={onView} id={row.id}>{row.orderName}</a>,
            center: false,
        },
        {
            name: 'SKU',
            width:"15%",
            selector: row => row.sku,
            sortable: true,
            center: false,
        },
        {
            name: 'Seller',
            width:"10%",
            selector: row => row.seller,
            sortable: true,
            center: false,
        },      {
            name: 'Return Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },      {
            name: 'Order Refund Date',
            selector: row => row.orderRefundDate,
            sortable: true,
            center: false,
        },
        {
            name: 'Total (£)',
            width:"7%",
            selector: row => row.total,
            sortable: true,
            center: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            width:"10%",
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            width:"7%",
            selector: row => row.id,
            cell:(row)=>row.status_id===1?<a className="text-primary" style={{cursor:"pointer"}} onClick={()=>{onAction(row)}} id={row.id}><i className="fa fa-reply"></i></a>:"",
            sortable: false,
            center: false,
        }
    ];

    useEffect(() => {
        getData();
    }, [page]);

    const getData = () =>{
        const data={
            page:page,
            pageSize:10,
        }
        dispatch(getReturns(data)).then(res => {
            if (!res.error) {
                dispatch(setReturnData(res.payload));
                isSetLoading(false);
            }
        });

    }


    const onAction = (row) => {
        SweetAlert.fire({
            title: `Refund Order - ${row.orderName}`,
            text: "Once refunded, you will not be able to cancel beyond this process!",
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if(result.isConfirmed){
                    dispatch(sendReturnItemShopify({order_refund:row.id})).then( res=>{
                        if (!res.error) {
                            getData();
                        }
                    });
                }
            });
    }
    const onView = (state) => {
        history.push('/order/return/'+state.target.id+'/view');
    }
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Returns" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>

                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={orderState.returnData?mapReturnResponseToTable(orderState.returnData):""}
                                    noAction
                                    paginationServer
                                    paginationTotalRows={orderState.orderTableRow}
                                    paginationPerPage={10}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(Return);