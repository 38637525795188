import API from "../../../utils/api";

export const getProductStyleApi = async (filters) => {
    const response = await API.get(`product-style/product-style`, { params: filters });
    return { response };
};

export const addProductStyleApi = async (location) => {
    const response = await API.post(`product-style/product-style`, location);
    return { response };
};

export const updateProductStyleApi = async (location,id) => {
    const response = await API.put(`product-style/product-style/${id}`, location);
    return { response };
};

export const deleteProductStyleApi = async (id) => {
    const response = await API.delete(`product-style/product-style/${id}`);
    return { response };
};

export const categoryAutoSuggestionApi = async (text) => {
    const response = await API.post('product-style/product-style', text);
    return { response };
};