import React, {Fragment, useState} from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import DatePicker from "react-datepicker";


const InvoiceForm = (props) => {
    const {
        errors,
        setValue,
        trigger,

        orderState,
        productVariants,
        setProductVariants
    } = props;

    const [invoiceDate,setInvoiceDate]=useState(new Date());
    const productCheck = (index,value,itemListId)=>{
        let newArr = productVariants;
        if(value==true){
            newArr.push(itemListId);
        }else{
            let findIndex=newArr.indexOf(itemListId);
            if (findIndex > -1) {
                newArr.splice(findIndex, 1);
            }
        }
        // setProductVariants(newArr);
    };
    const handleInvoiceDateChange = date => {
        setValue('invoiceDate',date);
        trigger('invoiceDate');
        setInvoiceDate(date);
    };
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Invoice Date</Label>
                        <DatePicker name="invoiceDate" className={`form-control digits ${errors.invoiceDate ? 'is-invalid' : ''}`} dateFormat="dd-MM-yyyy" selected={invoiceDate} maxDate={new Date()} onChange={handleInvoiceDateChange} />
                        <div className="invalid-feedback">{errors.invoiceDate?.message}</div>
                    </FormGroup>
                </Col>
                {orderState.list?orderState.list.map((res,index) => {
                    return (
                        <Col sm={6} key={index}>
                            <FormGroup>
                                <Label className="form-label">
                                    <Input onChange={(e)=>{productCheck(index,e.target.checked,res.id)}} className="checkbox_animated" type="checkbox" /> (#{res.sku}) - {res.name}
                                </Label>
                                {/*<Label className="form-label">Tracking Name<span style={{color:"red"}}>*</span></Label>*/}
                                {/*<input name="trackingName" type="text" className={`form-control ${errors.trackingName ? 'is-invalid' : ''}`} placeholder="DHL" {...register('trackingName')}  />*/}
                                {/*<div className="invalid-feedback">{errors.trackingName?.message}</div>*/}
                            </FormGroup>
                        </Col>
                    )
                }):""}
            </Row>
        </Fragment>
    );
};

export default InvoiceForm;