import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Card, CardBody, Col, Container, Form, FormGroup, Row} from 'reactstrap'
import withReducer from "../../../store/withReducer";
import userReducer from "../store";
import {useDispatch, useSelector} from "react-redux";
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form'
import {
    addUser,
    closeEditDialog,
    deleteUser,
    getUsers,
    getUserSettings,
    openEditDialog,
    openNewDialog,
    selectUser,
    setSettings,
    updateUser
} from "../store/userSlice";
import {mapUserRoleResponseToTable} from "../../../utils/mapper";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import UserForm from "./userForm";
import Select from "react-select";
import '../user-setting.css'
import PreLoader from "../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    email: '',
    role: ''
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yup.string().required('You must enter a name'),
    email: yup.string().required('You must enter an email'),
    role: yup.object().required('You must select a role')
});
const User = () => {

    const dispatch = useDispatch();
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const user = useSelector(selectUser);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        dispatch(getUserSettings()).then(res => {
            if (!res.error) dispatch(setSettings(res.payload));
        });
    }, [dispatch]);

    useEffect(() => {
        console.log(role)
        const data = {
            name,
            email,
            role,
        };
        dispatch(getUsers({...data})).then(res => {
            setLoading(false);
        });
    }, [dispatch, name, email, role]);


    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState

    const tableColumns = [
        {
            name: 'User Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            left: true,
        },
        {
            name: 'Role',
            selector: row => row.roleName,
            sortable: true,
            left: true,
        }
    ]
    const userState = useSelector(({user}) => user.user);

    const onEdit = (row) => {

        dispatch(openEditDialog(row));
        setValue('role', row.role);
    }
    const onDelete = (row) => {
        dispatch(deleteUser(row.id));
    }
    const closeDialog = () => {
        setValue('role', {});
        dispatch(closeEditDialog());
    }

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        data['role'] = data.role.name;
        if (dialog.type === 'new') {
            dispatch(addUser({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        } else {
            dispatch(updateUser({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    return (
        <Fragment>
            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb parent="Manage Users" title="Users"/>
                    <div className="product-grid">
                        <div className="feature-products">
                    <Row>
                        <Col sm="12">
                            {(currentUser.permission).includes('Create Staff') ?
                            <Card>
                                <Row>
                                    <Col sm="12">
                                        <div className='float-sm-right p-3'>
                                            <a className="btn btn-sm btn-primary" onClick={() => {
                                                dispatch(openNewDialog())
                                            }}> <i className="fa fa-plus"></i> Add User</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                                :''}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="4" sm="12">
                            <Form>
                                <FormGroup>
                                    <input onChange={(e) => setName(e.target.value)} name="name"
                                           placeholder="Search By Name" type="text"
                                           className="form-control"/>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xl="4" sm="12">
                            <Form>
                                <FormGroup>
                                    <input onChange={(e) => setEmail(e.target.value)} name="name"
                                           placeholder="Search By Email" type="text"
                                           className="form-control"/>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm="12" xl="4">
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter border"
                                    getOptionValue={(option) => option.id}
                                    placeholder={<div>Search By Role</div>}
                                    onChange={(e) => setRole(e?e.id:null)}
                                    options={userState.settings.role}
                                    isClearable
                                    cacheOptions
                                    defaultOptions
                                    styles={customStyles}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={mapUserRoleResponseToTable(user)}
                                            pagination
                                            onEdit={(currentUser.permission).includes('Update Staff') ? onEdit:''}
                                            onDelete={(currentUser.permission).includes('Delete Staff') ? onDelete:''}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                        </div>
                    </div>
                </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='User'
                selector='staff'
                dialog={({user}) => user.user.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <UserForm control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                          roles={userState.settings.role} getValues={getValues}></UserForm>
            </ModalFormSubmit>
        </Fragment>
    );

};

export default withReducer([{user: userReducer}])(User)


