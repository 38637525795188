import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getBackStockRequestData, getCustomerListApi, getSelectedProductDataApi} from "../../../services/backStock";


export const getBackStockProductRequestData = createAsyncThunk('back-stock/get-back-stock-slice', async (data, {dispatch})=>{
    const response = await getBackStockRequestData(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const getCustomerList = createAsyncThunk('back-stock/get-customer-list', async (data, {dispatch})=>{
    const response = await getCustomerListApi();
    return response.response;
});

export const getSelectedProductData = createAsyncThunk('back-stock/get-selected-product-data', async (data, {dispatch})=>{
    const response = await getSelectedProductDataApi(data);
    return response.response;
});

const backStockAdapter = createEntityAdapter({});

export const {selectAll: selectBackStore, selectById: selectBackStoreById} = backStockAdapter.getSelectors(
    state => state.backStore.backStoreRequest
);

const backStoreRequestSlice = createSlice({
    name: 'backStoreRequest',
    initialState: backStockAdapter.getInitialState({
        id: null,
        searchText: '',
        customers:'',
        status:'',
        viewDialog: {
                component: 'Product Details',
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
        }
    }),
    reducers: {
        selectBackStoreRequestSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload:event.target.value || ''})
        },
        openDialog: (state, action) => {
            state.viewDialog ={
                component: 'Product Details',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: action.payload.data
            };
        },
        closeDialog: (state, action) => {
            state.viewDialog ={
                component: 'Product Details',
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setTableRowCount: (state, action) => {
            state.backInStockTableRow = action.payload;
        },
        setCustomers: (state, action) => {
            state.customers = action.payload.customers;
            state.status = action.payload.status
        }
    },
    extraReducers: {
        [getBackStockProductRequestData.fulfilled]: backStockAdapter.setAll
    }
});

export const {
    selectBackStoreRequestSearchText,openDialog, closeDialog,setTableRowCount, setCustomers
} = backStoreRequestSlice.actions;

export default backStoreRequestSlice.reducer