import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Accordion, Card, useAccordionButton} from "react-bootstrap";
import "../../../assets/scss/components/accordian.scss"
import {CardBody, Col, Container, Form, Label, Row} from "reactstrap";
import {addSellerVat, getAllSellers, getSellerVatDetails} from "../store/sellerVatSlice";
import {useDispatch, useSelector} from "react-redux";
import {useFieldArray, useForm} from "react-hook-form";
import CountryList from "./countryList";
import {getCurrentSellerId, getCurrentSellerIdForSellerVat} from "../store/shippingZoneSlice";
import Select from "react-select";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import PreLoader from "../../../share-components/ui/preLoader";


const dataMenu = [
    {
        type: 0,
        text: 'Seller',
        link: '/seller'
    },
    {
        type: 1,
        text: 'Seller VAT',
        link: ''
    },
];

function CustomToggle({children, eventKey}) {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        setIsOpen(!isOpen)
    );

    return (
        <button
            type="button"
            className="btn btn-primary-outline pull-right"
            onClick={decoratedOnClick}
        >
            {isOpen ? <i className="fa fa-chevron-circle-up"></i> : <i className="fa fa-chevron-circle-down"></i>}
        </button>
    );
}

const SellerVat = () => {
    const dispatch = useDispatch();

    const {handleSubmit, formState, reset, register, setValue, getValues, trigger, control} = useForm({});


    const formRef = useRef();

    const [dataList, setDataList] = useState([]);
    const [seller, setSeller] = useState([]);
    const [sellerId, setSellerId] = useState(null);
    const [sellerList, setSellerList] = useState(null);
    const [sellerVisibility, setSellerVisibility] = useState(true);
    const [btnLoading,setBtnLoading]=useState(false);
    const [loading, isSetLoading] = useState(true);

    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "countryList", // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    const id = 1


    // console.log(currentUser)

    useEffect(() => {
        dispatch(getCurrentSellerIdForSellerVat(currentUser.id)).then(res => {
            if (res.payload) {
                if (!res.error)
                isSetLoading(false);
                dispatch(getSellerVatDetails(seller)).then(result => {
                    console.log("fff")
                    let data = JSON.parse(JSON.stringify(result.payload));
                    setValue('countryList', data)
                    setDataList(result.payload);

                });
            } else {
                if (!res.error)
                isSetLoading(false);
                // dispatch(getSellerVatDetails(seller)).then(result => {
                //     console.log("fff")
                //     let data = JSON.parse(JSON.stringify(result.payload));
                //     setValue('countryList', data)
                //     setDataList(result.payload)
                // });
            }

        });
        setSellerVisibility(true);
        dispatch(getAllSellers()).then(result => {
            setSellerList(result.payload)
        });
    }, [dispatch, seller]);

    const onSubmitForm = (data) => {
        setBtnLoading(true);
        const finalData = {
            data: data,
            seller_id: seller
        };
        dispatch(addSellerVat(finalData));
        setBtnLoading(false);
    }

    const onChangeCountryValue = (index, value) => {
        for (let i = 0; i < dataList[index].children.length; i++) {
            setValue(`countryList.${index}.children[${i}].value`, value)
        }

    }

    const loadCountries = (seller) => {
        // alert("gg")
        setValue('countryList', [])
        if(seller){
            dispatch(getSellerVatDetails(seller.id)).then(res => {
                console.log(res.payload)
                let data = JSON.parse(JSON.stringify(res.payload));
    
                setValue('countryList', data)
                setDataList(res.payload)
            });
        }
        
    }

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb data={dataMenu} title="Seller VAT"/>
            <Container fluid={true}>

                <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                    <Card>
                        <CardBody>
                            <Row className="m-b-25">
                                {sellerVisibility ? <Col sm={12}>
                                    <Label className="form-label">Seller Name</Label>
                                    <Select getOptionLabel={(seller) => `${seller.name} - ${seller.shop_name}`}
                                            // defaultValue={getValues('seller')}
                                            isClearable
                                            cacheOptions
                                            getOptionValue={(seller) => seller.id} {...register("seller")}
                                            onChange={(e) => {
                                                setValue('seller', e?e:null);
                                                setSeller(e?e.id:null);
                                                setSellerId(e?e.id:null);
                                                loadCountries(e?e:null)
                                                trigger('seller');
                                            }} options={sellerList}/>

                                </Col> : ''}
                            </Row>
                            <Row>
                                <Col sm="12" xl="12">
                                    <Accordion defaultActiveKey="0">
                                        {fields && fields.map((field, index) =>

                                            <Card key={index} style={{margin: 0}}>
                                                <Card.Header style={{padding: 10}}>
                                                    <Row>
                                                        <Col sm={2}>
                                                            <Label className="form-label m-t-15">{field.name}</Label>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <input
                                                                type="text"
                                                                className="form-control w-50 m-t-10 float-right"
                                                                key={field.id} // important to include key with field's id
                                                                {...register(`countryList.${index}.value`)}
                                                                onChange={(e) => onChangeCountryValue(index, e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col className="mt-3" sm={4}>
                                                            <CustomToggle eventKey={index}>Click me!</CustomToggle>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={index}>
                                                    <Card.Body>
                                                        <CountryList nestIndex={index} {...{control, register}}/>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )}
                                    </Accordion>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {(currentUser.permission).includes('Create Seller Vat') ?
                        <Card>
                            <CardBody>
                                <ButtonLoader btntext={id ? "Save" : "Save"} color="primary"
                                              className="button-color btn w-25 float-right" disabled={btnLoading}
                                              type="submit"/>
                                {/*<button className="btn btn-primary float-right">Save</button>*/}
                            </CardBody>
                        </Card>
                    :''}

                </Form>

            </Container>
            </> }
        </Fragment>
    )
}

export default (SellerVat)