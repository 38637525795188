import API from "../../../utils/api";


export const getProductColorApi = async (filters) => {
    const response = await API.get(`settings/color`, { params: filters });
    return { response };
};

export const addProductColorApi = async (location) => {
    const response = await API.post(`settings/color`, location);
    return { response };
};

export const updateProductColorApi = async (location,id) => {
    const response = await API.put(`settings/color/${id}`, location);
    return { response };
}

export const deleteProductColorApi = async (id) => {
    const response = await API.delete(`settings/color/${id}`);
    return { response };
}