import React, {Fragment} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";


const ProductSizeForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;


    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Product Size Name</Label>
                        <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Netsuite ID</Label>
                        <input name="name" type="text" {...register('netsuite_id')}
                               className={`form-control ${errors.netsuite_id ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.netsuite_id?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ProductSizeForm;