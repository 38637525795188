import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ViewCustomerContactDetails = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="4">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-secondary">Customer Service Contact Details</div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Full Name</Label>
                                    <p>{sellerDetails.customerFullName}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Email</Label>
                                    <p>{sellerDetails.customerEmail}</p>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Mobile / Landline Number</Label>
                                    <p>{sellerDetails.customerMobileNumber}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};