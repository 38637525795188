import API from "../../../utils/api";


export const getProductCompositionsApi = async (filters) => {
    const response = await API.get('product-composition/product-composition',{ params: filters });
    return { response };
};

export const addProductCompositionsApi = async (location) => {
    const response = await API.post('product-composition/product-composition',location);
    return { response };
};

export const updateProductCompositionsApi = async (location,id) => {
    const response = await API.put(`product-composition/product-composition/${id}`,location);
    return { response };
};

export const deleteProductCompositionsApi = async (id) => {
    const response = await API.delete(`product-composition/product-composition/${id}`);
    return { response };
};

export const compositionAutoSuggestionApi = async (text) => {
    const response = await API.post('product-composition/composition-group-auto-search',text);
    return { response };
};