import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {
    mapOrderReasonsResponseToTable,
    mapOrderResponseToTable,
    mapOrderReturnResponseToTable,
    mapSellerRoleResponseToTable
} from "../../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    addReturnItems,
    getOrders, getOrderSettings,
    setOrderData, setOrderListPackQty,
    setSettings, showOrder, showReturnList
} from "../../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";

import orderReducer from "../../store";
import JqxGrid, {IGridProps, jqx} from './../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import {CheckboxSelection} from "../../order/table/checkboxSelection";
import JqxTable from "../../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import {forEach} from "react-bootstrap/ElementChildren";
import {toast} from "react-toastify";
import SweetAlert from "sweetalert2";
import * as ReactDOM from "react-dom";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import PreLoader from "../../../../share-components/ui/preLoader";


const ReturnOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();

    let gridRef = useRef(null);
    let subGrid = useRef([]);
    const [subGridHeight, setSubGridHeight] = useState(200);
    const [loading, isSetLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [packList, setPackList] = useState([]);

    const orderState = useSelector(({ order }) => order.order);
    // console.log(orderState);
    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(res.payload));
            }
        });
    },[dispatch]);
    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Return Order"
        }
    ];


    useEffect(() => {
        dispatch(showReturnList(id)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
                isSetLoading(false);
            }
        });
    }, [dispatch]);

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
        }
    };

    const ReasonSource: any = {
        datatype: 'array',
        datafields: [
            { name: 'id', type: 'string' },
            { name: 'name', type: 'string' }
        ],
        localdata: orderState.settings.returnReasons,
    };
    //
    const reasonAdapter: any = new jqx.dataAdapter(ReasonSource, { autoBind: true });
    //
    const dataFields = [
        {name: 'id', type: 'id'},
        {name: 'productId', type: 'string'},
        {name: 'rowExpand', type: 'bool'},
        {name: 'productVariantId', type: 'string'},
        {name: 'quantity', type: 'number'},
        {name: 'availableQuantity', type: 'number'},
        {name: 'name', type: 'string'},
        {name: 'price', type: 'string'},
        {name: 'isRestock', type: 'bool' },
        {name: 'isPack', type: 'bool' },
        {name: 'packSinglePrice', type: 'number'},
        {name: 'image', type: 'string'},
        {name: 'total', type: 'string'},
        {name: 'returnTotal', type: 'number'},
        {name: 'reason_id', type: 'string'},
        {name: 'reason', value: 'reason_id', values: { source: reasonAdapter.records, value: 'id', name: 'name' } },

    ];

    const columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'productId', hidden: true },
        { datafield: 'availableQuantity', hidden: true },
        { datafield: 'isPack', hidden: true },
        { datafield: 'packSinglePrice', hidden: true },
        { datafield: 'productVariantId', hidden: true },
        {
            text: '#',
            width: '2%',
            datafield: 'rowExpand',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                let html="";
               if(value){
                   html = ReactDOMServer.renderToString(

                       <div className="jqx-delete-button-div">
                           <a>{value===1 ? <i className="fa fa fa-chevron-up"></i> :<i className="fa fa-chevron-down"></i>}</a>
                           {/*<img className="img-fluid" src={value?value:'../../../../../assets/images/default-medium-product.jpeg'} width="40" height="40" alt="product-img" />*/}
                       </div>
                   );
               }
                return html;
            },
        },
        {
            text: 'Product Name',
            datafield: 'name',
            width: '33%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Product',
            datafield: 'image',
            width: '10%',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const html = ReactDOMServer.renderToString(
                    <div style={{ textAlign: 'center', marginTop: 3 }}>
                        <img className="img-fluid" src={value?value:'../../../../../assets/images/default-medium-product.jpeg'} width="40" height="40" alt="product-img" />
                    </div>
                );
                return html;
            },
        },
        {
            text: 'Price (£)',
            datafield: 'price',
            width: '10%',
            editable: false,
            columntype: 'numberinput',
            cellsformat: 'c2',
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Quantity',
            datafield: 'quantity',
            editable: true,
            width: '7.3%',
            align: 'center',
            cellsalign: 'right',
            cellsformat: 'n',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            cellendedit(row, datafield, columntype, oldvalue,value) {
                let totalAmount=gridRef.getcellvalue(row,'returnTotal');
                if(oldvalue===undefined){
                    totalAmount+=value*gridRef.getcellvalue(row,'price');
                    gridRef.setcellvalue(row,'returnTotal',totalAmount);
                }else{
                    if(totalAmount!==0)
                        totalAmount-=oldvalue*gridRef.getcellvalue(row,'price');
                    if(value!=="" || value!==0){
                        totalAmount+=value*gridRef.getcellvalue(row,'price');
                        gridRef.setcellvalue(row,'returnTotal',totalAmount);
                        let calSubQty=0;
                        let availableQty=0;
                        // console.log(totalAmount);
                        if(subGrid.current[row]){
                            for (let i = 0; i < subGrid.current[row].getdatainformation().rowscount; i++) {
                                calSubQty=value * parseInt(subGrid.current[row].getcellvalue(i,'ratio'));
                                availableQty=subGrid.current[row].getcellvalue(i,'availableQty');
                                if(availableQty>calSubQty){
                                    subGrid.current[row].setcellvalue(i,'subQty',calSubQty);
                                    calculateRestockQty(row,i,calSubQty);
                                }else{
                                    subGrid.current[row].setcellvalue(i,'subQty',availableQty);
                                    calculateRestockQty(row,i,availableQty);
                                }
                            }
                        }

                    }
                }
                // console.log(orderState);
                // calculateSubQty(row);

                // // eslint-disable-next-line array-callback-return
                // packList.map((data, index) => {
                //     packList[index] = { ...packList[index], subQty: 0 };
                // });
                //

            },
            validation(cell, value) {

                if(value!=="") {
                    if (value > gridRef.getcellvalue(cell.row, 'availableQuantity')) {
                        toast.error(`Return quantity cannot exceed the ordered quantity - (${gridRef.getcellvalue(cell.row, 'availableQuantity')})`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return false;
                    }
                    if(parseFloat(value)===0 && !gridRef.getcellvalue(cell.row, 'isPack')){
                        toast.error(`Please enter valid quantity`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return false;
                    }

                    if (!Number.isInteger(parseFloat(value))) {
                        toast.error(`Quantity cannot be decimal`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return false;
                    }
                }else{
                    toast.error(`Please enter valid quantity`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return false;
                }
                return true;
            }
        },
        {
            text: 'Return Total (£)',
            datafield: 'returnTotal',
            width: '13%',
            editable: false,
            cellsformat: 'd',
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Is Restock',
            datafield: 'isRestock',
            editable: true,
            width: '7%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            cellendedit(row, datafield, columntype, oldvalue,value) {
                calculateRestockQty(row,null,null);
            }
        },
        {
            text: 'Reason',
            datafield: 'reason_id',
            displayfield: 'reason',
            width: '15%',
            align: 'center',
            columntype: 'dropdownlist',
            editable: true,
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            createeditor: (row: number, value: any, editor: any): void => {
                editor.jqxDropDownList({ width: '99%', height: 50, source: reasonAdapter, displayMember: 'name', valueMember: 'id' });
            },
        },
    ];

    const calculateSubQty = (row) => {
        // const packListArray = orderState.list;

        // console.log(packListArray[0]);
        // packListArray.map((data, index) => {
        //     packListArray[index].pack = { ...packListArray[index].pack, subQty: 0 };
        // });
        // dispatch(setOrderListPackQty(packListArray));
    }

    const submitData = () => {
        let foundErrors=0;
        let data=[];
        let subData=[];
        gridRef.getselectedrowindexes().forEach(element =>{

            if(gridRef.getcellvalue(element,'reason_id')===undefined || gridRef.getcellvalue(element,'reason_id')===null || (parseFloat(gridRef.getcellvalue(element,'quantity'))===0 && !gridRef.getcellvalue(element, 'isPack'))){
                foundErrors++;
            }
            subData=[];
            if(subGrid.current[element])
                for (let i = 0; i < subGrid.current[element].getdatainformation().rowscount; i++) {
                    subData.push({
                        product_id:gridRef.getcellvalue(element,'productId'),
                        product_variant_id:subGrid.current[element].getcellvalue(i,'id'),
                        quantity:subGrid.current[element].getcellvalue(i,'subQty')?subGrid.current[element].getcellvalue(i,'subQty'):undefined,
                        restock_qty:subGrid.current[element].getcellvalue(i,'restockQty'),
                    });
                }
            data.push({
                id:gridRef.getcellvalue(element,'id'),
                order_id:id,
                quantity:gridRef.getcellvalue(element,'quantity')?gridRef.getcellvalue(element,'quantity'):0,
                return_total:gridRef.getcellvalue(element,'returnTotal'),
                total_price:gridRef.getcellvalue(element,'total'),
                restock:gridRef.getcellvalue(element,'isRestock')?gridRef.getcellvalue(element,'isRestock'):false,
                reason_id:gridRef.getcellvalue(element,'reason_id'),
                sub_grid:subData
            });
        });

        if(data.length>0){
            if(foundErrors>0){
                toast.error("Invalid submission, please check all fields", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }else{
                SweetAlert.fire({
                    title: 'Are you sure want to return this product?',
                    text: "Once confirmed, the return request will be sent to the system",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Proceed',
                    cancelButtonText: 'No, cancel',
                    reverseButtons: true
                })
                    .then((result) => {
                        if(result.isConfirmed) {
                            setButtonDisabled(true);
                            dispatch(addReturnItems({data: data, orderNo: orderState.data.order_no})).then(res => {
                                if (!res.error) {
                                    setButtonDisabled(false);
                                    history.push('/order');
                                    console.log(res);
                                }
                            });
                        }
                    })
            }
        }else{
            toast.error("Please select at least one create", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }


    }
    const cellEdit = event => {
        // console.log(event.args);
    };
    const onSelectRow = event => {
        expandCollapseRowDetails(event.args.rowindex,true);

        // if(event.args.rowindex && (event.args.rowindex).length>0){
        //     // gridRef.setcellvalue(event.args.rowindex, 'reason', '');
        // }else{
        //     const gridInformation = gridRef.getdatainformation();
        //     for (let i = 0; i < gridInformation.rowscount; i++) {
        //         gridRef.setcellvalue(i, 'reason', '');
        //         gridRef.setcellvalue(i, 'reason_id', null);
        //         if(!gridRef.getcellvalue(i, 'quantity'))
        //             gridRef.setcellvalue(i, 'quantity', 0);
        //     }
        // }
    };

    const onRowunselect = event => {
        gridRef.setcellvalue(event.args.rowindex, 'reason', '');
        gridRef.setcellvalue(event.args.rowindex, 'reason_id', null);
        // gridRef.setcellvalue(event.args.rowindex, 'returnQuantity', gridRef.getcellvalue(event.args.rowindex,'oldQuantity'));
        gridRef.setcellvalue(event.args.rowindex, 'quantity', 0);
    };

    const nestedGrids: any[] = [];

    const expandCollapseRowDetails = (row,qtySelect=false) =>{
        if(gridRef.getcellvalue(row,'isPack')){
            let rowVal=gridRef.getcellvalue(row,'rowExpand');
            if(!qtySelect || rowVal!==1 ){
                if(rowVal===true)
                    rowVal=2;

                gridRef.setcellvalue(row,'rowExpand',rowVal===1?2:1);

                if(rowVal===2){
                    gridRef.showrowdetails(row);
                }else{
                    gridRef.hiderowdetails(row);
                }
            }
        }
    }

    const onRowDetailsClick = args =>{
        expandCollapseRowDetails(args.rowindex);
    }
    const calculateRestockQty = (parentRow,currentSubRow,newValue)=>{
        let returnQtyVal=null;
        for (let i=0; i<subGrid.current[parentRow].getdatainformation().rowscount;i++){
            returnQtyVal=gridRef.getcellvalue(parentRow,'quantity')!==0?((currentSubRow===i?newValue:subGrid.current[parentRow].getcellvalue(i,'subQty'))-(subGrid.current[parentRow].getcellvalue(i,'ratio')*gridRef.getcellvalue(parentRow,'quantity'))):(currentSubRow===i?newValue:subGrid.current[parentRow].getcellvalue(i,'subQty'));
            subGrid.current[parentRow].setcellvalue(i,'restockQty',0);

            if(gridRef.getcellvalue(parentRow,'isRestock'))
                subGrid.current[parentRow].setcellvalue(i,'restockQty',returnQtyVal);
        }
    }

    const initrowdetails = (index: number, parentElement: any, gridElement: any, record: any) => {
        if(gridRef.getcellvalue(record.boundindex,'isPack')){

            const nestedGridContainer = parentElement.children[0];
            nestedGrids[index] = nestedGridContainer;

            const returnSubGridSource = {
                localdata: orderState.list[index].pack,
                datafields: [
                    { name: 'id', type: 'id' },
                    { name: 'sku', type: 'string' },
                    { name: 'size', type: 'string' },
                    { name: 'subQty', type: 'number' },
                    { name: 'availableQty', type: 'number' },
                    { name: 'restockQty', type: 'number' },
                    { name: 'ratio', type: 'string' },
                    { name: 'colour', type: 'string' },
                ],
            }

            let subGridColumns = [
                { datafield: 'id', hidden: true },
                { datafield: 'availableQty', hidden: true },
                { datafield: 'restockQty', hidden: true },
                { text: 'SKU', datafield: 'sku', width: '35%',editable: false },
                { text: 'Size', datafield: 'size', width: '15%',editable: false },
                { text: 'Pack Size', datafield: 'ratio', width: '15%',editable: false },
                { text: 'Colour', datafield: 'colour', width: '10%',editable: false },
                {
                    text: 'Return Quantity',
                    datafield: 'subQty',
                    cellsformat: 'n',
                    width: '25%',
                    cellbeginedit(row, datafield, columntype, val) {

                        let found=0;
                        gridRef.getselectedrowindexes().forEach(element => element===record.boundindex?found++:"");
                        gridRef.endcelledit(record.boundindex, 'quantity');
                        return (found>0);

                    },
                    cellendedit(row, datafield, columntype, oldvalue,value) {

                        for(let i=0;i<subGrid.current[record.boundindex].getdatainformation().rowscount;i++){
                            const rowData = subGrid.current[record.boundindex].getrowdata(i);
                        }

                        let totalAmount=gridRef.getcellvalue(record.boundindex,'returnTotal');
                        if(oldvalue===undefined){
                            totalAmount+=value*gridRef.getcellvalue(record.boundindex,'packSinglePrice');
                            gridRef.setcellvalue(record.boundindex,'returnTotal',totalAmount);
                        }else{
                            if(totalAmount!==0)
                                totalAmount-=oldvalue*gridRef.getcellvalue(record.boundindex,'packSinglePrice');
                            if(value!=="" || value!==0){
                                totalAmount+=value*gridRef.getcellvalue(record.boundindex,'packSinglePrice');
                                gridRef.setcellvalue(record.boundindex,'returnTotal',totalAmount);
                            }
                        }

                        calculateQuantity(record.boundindex,row,value);
                    },
                    validation(cell, value) {
                        if(value!==""){
                            if(!Number.isInteger(parseFloat(value))){
                                return { result: false, message: 'Quantity cannot be decimal' };
                            }else{
                                let returnQtyValidation=subGrid.current[record.boundindex].getcellvalue(cell.row,"availableQty");
                                if(value>returnQtyValidation){
                                    return {result: false,message:`Pack size cannot exceed (${returnQtyValidation})`}
                                }
                            }
                        }else{
                            subGrid.current[record.boundindex].setcellvalue(cell.row,"subQty",null);
                        }

                        return true;
                    }
                },
            ];

            const calculateQuantityRow = (subGridData) =>{
                console.log(subGridData);
            }



            const calculateQuantity = (parentRow,currentSubRow,newValue) =>{

                let subGridInfo=subGrid.current[parentRow].getdatainformation();
                let smallest=0;
                let currentValue=null;
                for (let i=0; i<subGridInfo.rowscount;i++){
                    currentValue=Math.floor((currentSubRow===i?newValue:subGrid.current[parentRow].getcellvalue(i,'subQty'))/subGrid.current[parentRow].getcellvalue(i,'ratio'));
                    if(currentValue<=smallest || i===0)
                        smallest=currentValue;
                }
                gridRef.setcellvalue(parentRow,'quantity',smallest);
                calculateRestockQty(parentRow,currentSubRow,newValue);



            }

            const returnNestedGridAdapter = new jqx.dataAdapter(returnSubGridSource);
            if (nestedGridContainer != null) {
                const columns: IGridProps['columns'] = subGridColumns;
                ReactDOM.render(
                    <JqxGrid onCellendedit={cellEdit} columns={subGridColumns} id="jqx_sub" ref={jqxRef => {
                        subGrid.current[index] = jqxRef;
                    }} editable={true} theme='material'
                             onCellbeginedit={() => {
                                 return false;
                             }}
                             height={'100%'}
                             width={'100%'} source={returnNestedGridAdapter} showtoolbar={false}
                             selectionmode='singlecell' columnsheight={5}/>,
                    document.getElementById(nestedGridContainer.id)
                );

            }
        }else{
            gridRef.hiderowdetails(record.boundindex);
        }
    }
console.log(orderState.settings.returnReasons, orderState);
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Select Order Items for Returns" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <h5 className="mb-2">Items from Order {orderState.data?orderState.data.order_name:""}</h5>

                                {(orderState.settings.returnReasons).length>0 &&
                                <div>
                                    <JqxTable
                                    datafields={dataFields}
                                    initrowdetails={initrowdetails}
                                    columns={columns}
                                    cellClickFunction={onRowDetailsClick}
                                    cellClickDataField={['rowExpand']}
                                    data={orderState.list ? mapOrderReturnResponseToTable(orderState.list) : null}
                                    myGrid={gridRef}
                                    isDelete={false}
                                    disableCreateRow
                                    rowsheight={50}
                                    getJqxRef={getJqxRef}
                                    cellEdit={cellEdit}
                                    onRowselect={onSelectRow}
                                    onRowunselect={onRowunselect}
                                    selectionmode={'checkbox'}
                                    rowdetails={true}
                                    showrowdetailscolumn={false}
                                    rowdetailstemplate={{
                                        rowdetails: '<div id="nestedGrid" style="margin: 1px; border: 1px solid black"></div>',
                                        rowdetailsheight: subGridHeight,
                                        rowdetailshidden: true
                                    }}
                                />
                                <div className="mt-2 d-flex justify-content-end">
                                    <div className="p-2">
                                        <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Return" className="btn btn-primary btn-block"/>
                                    </div>
                                </div>
                                </div>}
                            </CardBody>
                        </Card>
                    </Col>


                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(ReturnOrder);