import React, {useState} from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import '../navItems.scss'


export const UpdateSellerLeadNavItems = (props) => {
    const {VerticleTab, setVerticleTab} = props;
    const [companyProfile, setCompanyProfile] = useState(false);
    return (
        <Nav className="nav flex-column nav-pills lead-nav">
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '1' ? 'active' : ''}
                         onClick={() => setVerticleTab('1')}>Company Profile {companyProfile ?
                    <i className="icofont icofont-warning-alt"></i> : ""}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>Business Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Warehouse Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Contact Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Customize Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '6' ? 'active' : ''} onClick={() => setVerticleTab('6')}>Additional Details</NavLink>
            </NavItem>
        </Nav>
    );
}