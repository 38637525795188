import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";
import {Container, Form} from "react-bootstrap";
import {Card, CardBody, Col, FormGroup, Nav, Row} from "reactstrap";
import {
    getBackStockProductRequestData,
    getCustomerList,
    getSelectedProductData,
    openDialog,
    selectBackStore,
    setCustomers
} from "./store/backStockSlice";
import withReducer from "../../store/withReducer";
import backStoreReducer from "./store";
import ViewBackInStockRequestDataDialog from "./viewBackInStockRequestDataDialog";
import PreLoader from "../../share-components/ui/preLoader";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {mapBackInStockResponseToTable} from "../../utils/mapper";
import BackInStockTable from "./content/backInStockTable";
import {toast} from "react-toastify";


const dataMenu = [
    {
        type: 0,
        text: 'Pre Order requests',
        link: '/product/back-in-stock'
    },
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};


const BackInStock = () => {

    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [name, setName] = useState(null);
    const [status, setStatus] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [checkBoxData, setCheckBox] = useState({idArray: []});
    const [timer, setTimer] = useState(null);
    const [removed, setRemoved] = useState(false);
    const [count, setCount] = useState(null);
    const [button, setButton] = useState(false);
    const [backStockData, setBackStockData] = useState([]);
    const backInStockData = useSelector(selectBackStore);
    const backInRequestState = useSelector(({backStore}) => backStore.backStoreRequest)
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 30);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);

    useEffect(() => {
        setBackStockData(mapBackInStockResponseToTable(backInStockData, checkBoxData));
    }, [backInStockData]);

    const rowSelectCritera = row => row.selected;


    let orderArray = [];

    const [startDate, endDate] = dateRange;

    useEffect(() => {
        getData();
    }, [dispatch, name, page, pageSize, startDate, endDate, customer, status]);

    useEffect(() => {
        setBackStockData(mapBackInStockResponseToTable(backInStockData, checkBoxData));

        dispatch(getCustomerList()).then(res => {
            dispatch(setCustomers(res.payload))
        });
    }, [dispatch])

    const getData = () => {
        const data = {
            name: name,
            page: page,
            pageSize: pageSize,
            customer: customer ? customer.id : null,
            status: status ? status.id : null,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        dispatch(getBackStockProductRequestData({...data})).then(res => {
            if (!res.error)
                setLoading(false);
        })
    }

    const pageChange = (e) => {

        setPage(e)
    }

    const onPageSizeChange = (e) => {
        setPageSize(e)
    }

    const onView = (row) => {
        dispatch(getSelectedProductData(row.id)).then(res => {
            const dat = {
                isOpen: true,
                data: res.payload
            };
            dispatch(openDialog(dat));
        });
    };

    let idArray = [];
    const handleChange = (state) => {
        for (let i = 0; i < state.selectedRows.length; i++) {
            const orderData = {
                id: state.selectedRows[i].id,
                customer_id: state.selectedRows[i].customer_id
            };
            if (orderArray.length == 0) {
                orderArray.push(orderData);
                idArray.push(state.selectedRows[i].id);
                console.log(checkBoxData)
                setCheckBox(prevState => ({
                    idArray:[...prevState.idArray, ...idArray],

                }));
            } else {
                if (orderArray[0].customer_id === state.selectedRows[i].customer_id) {
                    for (let j = 0; j < orderArray.length; j++) {
                        if (orderArray[j].id != state.selectedRows[i].id) {
                            const orderData = {
                                id: state.selectedRows[i].id,
                                customer_id: state.selectedRows[i].customer_id
                            };
                            orderArray.push(orderData)
                            idArray.push(state.selectedRows[i].id)
                        }
                    }
                    console.log(checkBoxData)
                    setCheckBox(prevState => ({
                        idArray:[...prevState.idArray, ...idArray],
                    }));
                } else {

                    setRemoved(!removed);

                    toast.error('Can not add multiple customer orders', {position: toast.POSITION.TOP_RIGHT});
                }
            }
        }
        let finalArr = orderArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        const tempArray = [];
        const tempArray2 = [];

        if (finalArr.length > state.selectedRows.length && finalArr.length != 1) {
            for (let i = 0; i < state.selectedRows.length; i++) {
                tempArray.push({id: state.selectedRows[i].id, customer_id: state.selectedRows[i].customer_id});
            }
            for (let i = 0; i < finalArr.length; i++) {
                const index = tempArray.findIndex(element => element.id === finalArr[i].id);
                if (index !== -1) {
                    tempArray2.push(finalArr[i])
                }
            }
            finalArr = tempArray2;
        }

        if (orderArray.length>0 || finalArr.length>0) {
            setButton(true)
        }
        orderArray = finalArr;

    }


    const toggleRowSelected = (id) => {
        console.log(id)
    }

    const tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "130px"
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
            center: true,
        },
        {
            name: 'Product',
            width: "350px",
            // selector: row => row.product,
            selector: params => (
                <Row>
                    <Col sm={2}>
                        <img
                            height="30"
                            width="40"
                            src={params.image}
                            alt=""
                        />
                    </Col>
                    <Col sm={10}>
                        <p>{params.product}</p>
                    </Col>
                </Row>
            )
        },
        {
            name: 'Customer',
            width: "200px",
            selector: row => row.customer,
            sortable: true,
            left: true,
        },
        {
            name: 'Quantity Count',
            selector: row => row.qty,
            sortable: true,
            right: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            right: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_date,
            sortable: true,
            left: true,
        },
        {
            name: 'Action',
            width: "130px",
            selector: row =>
                <div>
                    <a onClick={() => {
                        onView(row);
                    }}><i className="fa fa-eye"
                          style={{width: 35, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>
                </div>,
            sortable: false,
            center: true,
        }
    ];
    return (
        <Fragment>
            <Container fluid={true}>
                {loading ? <><PreLoader/></> : <>
                    <Breadcrumb data={dataMenu} title="Pre Order requests"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            {button ? <Row>
                                <Col sm="12">
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           // onAdd();
                                                       }}
                                                    > <i className="fa fa-plus"></i> Create Draft
                                                        Order {count != null ? count : ''}</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row> : ''}
                            <Row>
                                <Col sm="12" xl="3">
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            selectsRange={true}
                                            className="form-control hide-border-control"
                                            dateFormat="dd/MM/yyyy"
                                            startDate={startDate}
                                            maxDate={TodayDate}
                                            endDate={endDate}
                                            styles={customStyles}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            placeholderText="Search by Date Range"
                                            isClearable={false}
                                        />
                                    </div>
                                </Col>
                                <Col sm="12" xl="3">
                                    <Form>
                                        <FormGroup>
                                            <input
                                                onChange={(e) => {
                                                    clearTimeout(timer)
                                                    const newTimer = setTimeout(() => {
                                                        setName(e.target.value)
                                                    }, 500)
                                                    setTimer(newTimer)
                                                }}
                                                name="name"
                                                type="text" className="form-control"
                                                placeholder="Search By Product Name / SKU"/>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col sm="12" xl="3">
                                    <Select
                                        placeholder={<div>Customer</div>}
                                        className="select2-filter"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        // cacheOptions
                                        styles={customStyles}
                                        // defaultValue={backInRequestState.filter.country}
                                        options={backInRequestState.customers}
                                        onChange={(e) => {
                                            setCustomer(e ? e : null);
                                        }}

                                    />
                                </Col>
                                <Col sm="12" xl="3">
                                    <Select
                                        placeholder={<div>Status</div>}
                                        className="select2-filter"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        // cacheOptions
                                        styles={customStyles}
                                        // defaultValue={backInRequestState.filter.country}
                                        options={backInRequestState.status}
                                        onChange={(e) => {
                                            setStatus(e ? e : null);
                                        }}

                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table">
                                                <BackInStockTable
                                                    idArray={checkBoxData}
                                                    remove={removed}
                                                    tableColumns={tableColumns}
                                                    backStockData={backStockData}
                                                    backInRequestState={backInRequestState}
                                                    onView={onView}
                                                    pageChange={pageChange}
                                                    onPageSizeChange={onPageSizeChange}
                                                    toggleRowSelected={toggleRowSelected}
                                                    handleChange={handleChange}
                                                >

                                                </BackInStockTable>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>}
            </Container>
            <ViewBackInStockRequestDataDialog backInRequestState={backInRequestState}/>
        </Fragment>
    );
}

export default withReducer([{backStore: backStoreReducer}])(BackInStock);