import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {showSeller} from "../store/sellerSlice";
import withReducer from "../../../store/withReducer";
import sellerReducer from "../store";

import "react-datepicker/dist/react-datepicker.css";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ViewPrimaryDetails} from "./section/viewPrimaryDetails";
import {ViewBrandDetails} from "./section/viewBrandDetails";
import {ViewCustomerContactDetails} from "./section/viewCustomerContactDetails";
import {ViewExtraInformation} from "./section/viewExtraInformation";
import {ViewReturnWarehouse} from "./section/viewReturnWarehouse";
import {ViewSellerStoreDescription} from "./section/viewSellerStoreDescription";
import {ViewHeadingLinks} from "./section/viewHeadingLinks";
import {ViewUploadedImages} from "./section/viewUploadImages";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import PreLoader from "../../../share-components/ui/preLoader";
import {ViewBannerImages} from "./section/viewBannerImages";


const ViewSeller = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Sellers',
            link: '/seller'
        },
        {
            type: 0,
            text: "View Seller"
        }
    ];
    const { id } = useParams();
    const dispatch = useDispatch();
    const [sellerDetails,setSellerDetails] = useState(null);
    const [loading, isSetLoading] = useState(true);

    //View Uploaded Images
    const [images,setImage] = useState([]);
    const initilindex = {index:0,isOpen:false}
    const[photoIndex,setPhotoIndex] = useState(initilindex);

    const onMovePrev = () => {
        const prev = (photoIndex.index + images.length - 1) % images.length
        setPhotoIndex({...photoIndex,index:prev})
    }

    const  onMoveNext = () => {
        const next = (photoIndex.index + 1) % images.length
        setPhotoIndex({...photoIndex,index:next})
    }

    //View Banner Images
    const [bannerImages,setBannerImages] = useState([]);

    const[bannerIndex,setBannerIndex] = useState(initilindex);

    const onBannerMovePrev = () => {
        const prev = (bannerIndex.index + bannerImages.length - 1) % bannerImages.length
        setBannerIndex({...bannerIndex,index:prev})
    }

    const  onBannerMoveNext = () => {
        const next = (bannerIndex.index + 1) % bannerImages.length
        setBannerIndex({...bannerIndex,index:next})
    }

    useEffect(() => {
        if(id){
            dispatch(showSeller(id)).then(res => {
                if (!res.error) {
                    setSellerDetails(res.payload);
                    isSetLoading(false);
                }
            });
        }
    },[dispatch]);

    useEffect(() => {
        if(sellerDetails){
            let data=[];
            if(sellerDetails.seller_images){
                sellerDetails.seller_images.map((item, i) =>{
                    if(item.path){
                        data.push(item);
                    }
                })
                setImage(data);
            }
            data=[];
            if(sellerDetails.seller_banners){
                sellerDetails.seller_banners.map((item, i) =>{
                    if(item.path){
                        data.push(item);
                    }
                })
                setBannerImages(data);
            }
        }

    },[sellerDetails])

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb title="View Seller" data={dataMenu}/>
                    <Container fluid={true}>
                        <Row>
                            <ViewPrimaryDetails sellerDetails={sellerDetails} />
                            <ViewCustomerContactDetails sellerDetails={sellerDetails} />
                            <ViewExtraInformation sellerDetails={sellerDetails} />
                            <ViewReturnWarehouse sellerDetails={sellerDetails} />
                            <ViewSellerStoreDescription sellerDetails={sellerDetails} />
                            <ViewHeadingLinks sellerDetails={sellerDetails}  />
                            <ViewBrandDetails sellerDetails={sellerDetails} />
                            <ViewUploadedImages photoIndex={photoIndex} setPhotoIndex={setPhotoIndex} images={images} />

                            <ViewBannerImages bannerIndex={bannerIndex} setBannerIndex={setBannerIndex} bannerImages={bannerImages} />
                        </Row>
                    </Container>
                    {photoIndex.isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex.index].path}
                            nextSrc={images[(photoIndex.index + 1) % images.length].path}
                            prevSrc={images[(photoIndex.index + images.length - 1) % images.length].path}
                            imageTitle={photoIndex.index + 1 + "/" + images.length}
                            onCloseRequest={() => setPhotoIndex({ ...photoIndex,isOpen:false})}
                            onMovePrevRequest={onMovePrev}
                            onMoveNextRequest={onMoveNext}
                        />
                    )}

                    {bannerIndex.isOpen && (
                        <Lightbox
                            mainSrc={bannerImages[bannerIndex.index].path}
                            nextSrc={bannerImages[(bannerIndex.index + 1) % bannerImages.length].path}
                            prevSrc={bannerImages[(bannerIndex.index + bannerImages.length - 1) % bannerImages.length].path}
                            imageTitle={bannerIndex.index + 1 + "/" + bannerImages.length}
                            onCloseRequest={() => setBannerIndex({ ...bannerIndex,isOpen:false})}
                            onMovePrevRequest={onBannerMovePrev}
                            onMoveNextRequest={onBannerMoveNext}
                        />
                    )}
            </>
            }
        </Fragment>
    );
}

export default withReducer([{seller: sellerReducer}])(ViewSeller);