import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {setCheckoutModal} from "../../store/checkoutSlice";
import {numberWithCommas} from "../../../../utils/common";


const CheckoutCart = (props) => {
    const {setValue, trigger, getValues, imageSelectOnClick} = props;
    const dispatch = useDispatch();
    const checkoutState = useSelector(({order}) => order.checkout);


    const closDialog = () => {
        dispatch(setCheckoutModal(false));
    }

    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...checkoutState.mediaDialogProps.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        View Customer Cart
                    </ModalHeader>
                    <ModalBody>
                        <div style={{maxHeight:500,overflowY:"auto",overflowX:'hidden',position:"relative"}}>
                        {checkoutState.checkOutData.variants.map((item, i) =>
                            <div key={i} className="mb-1">
                                <Row>
                                    <Col md={12}><strong>Sold By {item.seller}</strong> </Col>
                                </Row>
                                <hr/>

                                {item.items.map((subItem, subIndex) =><Row className="mb-1" key={subIndex}>
                                    <Col md={8}>
                                        <Row >
                                            <Col md={2}>
                                          <img width={75} src={subItem.image}/>
                                            </Col>
                                            <Col md={9}>
                                                <div style={{display:"table-cell", verticalAlign: "middle",height:100}}>
                                                <div><strong>{subItem.name}</strong></div>
                                                <div className="mt-2"><span>{subItem.option}</span></div>
                                                <div className="mt-2"><span>SKU : {subItem.sku}</span></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2}>
                                        <div style={{display:"table-cell", verticalAlign: "middle",height:100}}>
                                            {numberWithCommas(subItem.price)} X {subItem.quantity}
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div style={{display:"table-cell", verticalAlign: "middle",height:100}}>
                                            {numberWithCommas(subItem.price * subItem.quantity)}
                                        </div>
                                    </Col>
                                </Row>)}
                            </div>)}
                        </div>
                        <hr/>
                        <Row>
                            <Col md={8}>
                                <div><strong>Customer info</strong></div>
                                <Row>
                                    <Col md={3}>Name</Col>
                                    <Col md={3}>{checkoutState.checkOutData.customer?.name}</Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Email</Col>
                                    <Col md={3}>{checkoutState.checkOutData.customer?.email}</Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Phone #</Col>
                                    <Col md={3}>{checkoutState.checkOutData.customer?.phone_number}</Col>
                                </Row>

                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col md={6}>
                                    <strong>Sub Total</strong>
                                    </Col>
                                    <Col md={6}>
                                        <span>{numberWithCommas(checkoutState.checkOutData.sub_total_price)}</span>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={6}>
                                    <strong> Total</strong>
                                    </Col>
                                    <Col md={6}>
                                        <span>{numberWithCommas(checkoutState.checkOutData.total_price)}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </ModalBody>

                </Modal>
            </div>
        </Fragment>
    );
}

export default CheckoutCart;