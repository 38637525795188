import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {
    addSellerApi,
    getSellerApi,
    getSellersApi,
    updateSellerApi
} from "../../../services/seller";
import {
    addDraftOrderApi,
    getDraftOrderApi,
    getDraftOrdersApi,
    searchProduct,
    updateDraftOrderApi
} from "../../../services/order";


export const getDraftOrders = createAsyncThunk('orders/draft/get', async (data, {dispatch,}) => {
    const response = await getDraftOrdersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});
export const productSearch = createAsyncThunk('orders/draft/search-product', async (data, {dispatch,}) => {
    const response = await searchProduct(data);
    return response.response;
});


export const addDraftOrders = createAsyncThunk('orders/draft/add', async (orderData, {dispatch, getState}) => {
    try {
        const response = await addDraftOrderApi(orderData);
        const data = await response.response;
        toast.success("Draft Order created successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
export const getDraftOrder = createAsyncThunk('orders/draft/show', async (id, {dispatch}) => {
    const response = await getDraftOrderApi(id);
    return response.response;
});


export const updateDraftOrder = createAsyncThunk('orders/draft/update', async (orderData, {dispatch}) => {
    const response = await updateDraftOrderApi(orderData,orderData.id);
    toast.success(`Draft Order updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});


const draftOrderAdapter = createEntityAdapter({});

export const {selectAll: selectDraftOrder, selectById: selectDraftOrderById} = draftOrderAdapter.getSelectors(
    state => state.order.draftOrder
);

const draftOrderSlice = createSlice({
    name: 'draftOrder',
    initialState: draftOrderAdapter.getInitialState({
        id: null,
        sellerTableRow: 0,
        searchText: '',
        data: null,
        products: {
            data:[],
            pagination:{
                count:0,
                next_page:1,
                total:0,
                has_more:true,
            }
        },
        searchProductDialogProps: {
            props: {
                isOpen: false
            },
            data:{searchValue:""}
        },
        discountDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        shippingDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        taxDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.sellerTableRow = action.payload;
        },
        setSearchProductDialog: (state, action) => {
            state.searchProductDialogProps = action.payload
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOrderDiscountDialog: (state, action) => {
            state.discountDialogProps = action.payload;
        },
        setShippingDiscountDialog: (state, action) => {
            state.shippingDialogProps = action.payload;
        },
        setTaxDialog: (state, action) => {
            state.taxDialogProps = action.payload;
        }
    },
    extraReducers: {
        [getDraftOrders.fulfilled]: draftOrderAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setProducts, setTableRowCount, setSearchProductDialog,setOrderDiscountDialog,setShippingDiscountDialog,setTaxDialog} =
    draftOrderSlice.actions;

export default draftOrderSlice.reducer;
