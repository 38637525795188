import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapSettingResponseToTable} from "../../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import productReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import SettingForm from "../settingForm";
import {
    addProductTag,
    closeEditDialog,
    deleteProductTag,
    getAllProductTags,
    openEditDialog,
    openNewDialog,
    selectProductTag,
    updateProductTag
} from "../../store/productTagSlice";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Tag',
        link: ''
    }
];

const defaultValues = {
    id: '',
    name: '',
    tageName: '',
    publish_at: ''
};

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
    // tageName: yup.string().required('You must enter a tag name'),
});

const ProductTag = () => {

    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [loading, setLoading] = useState(true);
    const productTag =useSelector(selectProductTag);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        const data = {
            name
        }
        dispatch(getAllProductTags({...data})).then(res => {
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name])

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addProductTag({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductTag({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductTag(row.id));
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    const tableColumns = [
        {
            name: 'Tag Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
    ];

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb data={dataMenu} title="Product Tag"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">
                                {(currentUser.permission).includes('Create Tag') ?
                                <Card>
                                    <Row>
                                        <Col sm="6">
                                            <Nav tabs className="border-tab">

                                            </Nav>
                                        </Col>
                                        <Col sm="6">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={() => {
                                                       dispatch(openNewDialog())
                                                   }}
                                                > <i className="fa fa-plus"></i> Add Product Tag</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                    :''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12" sm="12">
                                <Form>
                                        <FormGroup className="m-0">
                                            <input onChange={(e) => setName(e.target.value)} name="name"
                                                   type="text" className="form-control border" placeholder="Search By Product Tag Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapSettingResponseToTable(productTag)}
                                pagination
                                onEdit={(currentUser.permission).includes('Update Tag') ? onEdit:''}
                                onDelete={(currentUser.permission).includes('Delete Tag') ? onDelete:''}
                            />
                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Tag'
                selector='product'
                dialog={({product}) => product.productTag.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <SettingForm
                    control={control} formText="Tag" errors={errors} register={register} setValue={setValue}
                    trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{tag: productReducer}])(ProductTag)