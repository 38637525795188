import React, {useRef} from "react";
import JqxTable from "../../../share-components/table/JqxTable";

const RateJqx = (props) => {

    const {data,getJqxRef,myGrid,minValue,maxValue} = props;

    const datafields = [
        { name: 'id', type: 'string' },
        { name: 'rate_name', type: 'string' },
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
        { name: 'shipping_price', type: 'number' },

    ];

    const columns = [
        { datafield: 'id', hidden: true },
        {
            text: 'Rate Name',
            datafield: 'rate_name',
            width: '35%',
            editable: true,
            columntype: 'text',
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: minValue,
            datafield: 'min',
            width: '15%',
            editable: true,
            cellsalign: 'right',
            type: 'number',
            align: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: maxValue,
            datafield: 'max',
            width: '15%',
            editable: true,
            cellsalign: 'right',
            type: 'number',
            align: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Shipping Price',
            datafield: 'shipping_price',
            width: '30%',
            editable: true,
            cellsalign: 'right',
            type: 'number',
            align: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },
    ];

    const cellEdit = event => {
        // console.log(event.args);
    };

    return (
        <JqxTable
            datafields={datafields}
            columns={columns}
            data={data}
            myGrid={myGrid}
            getJqxRef={getJqxRef}
            isDelete={true}
            onDelete
            onCellendedit={cellEdit}
            disableCreateRow={false}

        />
    )
}

export default (RateJqx)