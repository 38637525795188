import {useDispatch} from "react-redux";
import React,{useEffect, useState} from "react";
import {closeDialog} from "./store/backInReportSlice";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";



export default function BackInStockReportDataDialog(props) {
    const {backInRequestState, size = 'lg' } = props;
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const closeModalDialog = () => {
        dispatch(closeDialog())
    }

    useEffect(() => {
        setData(backInRequestState.viewDialog.data ? backInRequestState.viewDialog.data : '');
    }, [backInRequestState.viewDialog.props.isOpen]);

    const tableColumns = [
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            left: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            right: true,
        },
    ];

    return (
        <div className="flex flex-col modal-lg">
            <Modal size="lg" {...backInRequestState.viewDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    List of Back in stock requests for {data[0]?data[0].product: ''}
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive product-table">
                        <CommonDataTable
                            headerColumns={tableColumns}
                            pagination
                            noAction
                            gridData={data}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </div>
    );
}