import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapReturnRequestResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import {getAllPendingReturnRequest, setReturnRequestData} from "../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import orderReducer from "../store";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PreLoader from "../../../share-components/ui/preLoader";


const PendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [page, setPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [loading, isSetLoading] = useState(true);
    const [search,setSearch]=useState(null);
    const orderState = useSelector(({ order }) => order.order);
    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Pending Return Requests"
        }
    ];

    useEffect(() => {;
        getData();
    }, [page,search]);

    useEffect(() => {
        if(dateRange[0]!==null && dateRange[1]!==null)
            getData();
    }, [dateRange]);


    const getData = () => {
        const data = {
            page:page,
            pageSize: 10,
            dateRangeX:dateRange[0]!==null?moment(dateRange[0]).format('YYYY-MM-DD'):null,
            dateRangeY:dateRange[1]!==null?moment(dateRange[1]).format('YYYY-MM-DD'):null,
            search:search
        }
        dispatch(getAllPendingReturnRequest(data)).then(res => {
            if (!res.error) {
                dispatch(setReturnRequestData(res.payload));
                isSetLoading(false);
            }
        });

    }

    const tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.orderName,
            sortable: true,
            center: false,
        },
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => row.id,
            cell:(row)=>row.status_id===1?<a className="text-primary" style={{cursor:"pointer"}} onClick={()=>{onEdit(row)}} id={row.id}><i className="fa fa-reply"></i></a>:"",
            sortable: false,
            center: false,
        }
    ];

    const onEdit = (row) => {
        history.push(`/order/${row.id}/pending-return-request`);
    }

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Pending Return Requests" data={dataMenu}/>
            <Container fluid={true}>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="6">
                                <Form>
                                    <FormGroup className="row">
                                        <Col sm="12">
                                            <DatePicker
                                            selectsRange={true}
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            startDate={startDate}
                                            maxDate={(new Date())}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            placeholderText="Search by Date Range"
                                            isClearable={true}
                                        /></Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{setSearch(e.target.value)}}
                                            placeholder="Search Order/ Customer Name"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                    <div className="table-responsive product-table">
                        <CommonDataTable
                            headerColumns={tableColumns}
                            gridData={orderState.returnRequestData?mapReturnRequestResponseToTable(orderState.returnRequestData):""}
                            paginationServer
                            noAction
                            paginationTotalRows={orderState.orderTableRow}
                            paginationPerPage={10}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                            }}
                            onChangePage={page => setPage(page)}
                        />

                    </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(PendingReturn);