import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapSearchAutoSuggestion, mapSellerRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import sellerReducer from "../store";
import {
    downloadAdminCommissionExport, EmailAdminCommissionExport,
    emailNewPassword, getCommissions,
    getSellers,
    getSellerSettings,
    selectSeller,
    selectSellerById,
    setSettings, setTableDataCommission
} from "../../seller/store/sellerSlice";
import {useHistory, useParams} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../../share-components/ui/preLoader";
import SweetAlert from "sweetalert2";
import moment from "moment";
import DatePicker from "react-datepicker";
import orderReducer from "../store";
import {getOrderCommissions, setCommissionData} from "../store/orderSlice";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const OrderCommission = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const seller_id=queryParams.get("seller");
    const seller_name=queryParams.get("seller_name");
    const start_date=queryParams.get("start_date");
    const end_date=queryParams.get("end_date");

    const dispatch = useDispatch();
    const [page,setPage]=useState(1);

    const TodayDate=end_date?Date.parse(end_date):new Date();
    const sevenDays=start_date?Date.parse(start_date):new Date().setDate(TodayDate.getDate()-7);
    const orderState = useSelector(({order}) => order.order);
    const sellerState = useSelector(({seller}) => seller.seller);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [brand,setBrand]=useState(null);
    const [search,setSearch]=useState(null);
    const [order,setOrder]=useState(null);
    const [loading, isSetLoading] = useState(false);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [seller, setSeller] = useState(seller_id?seller_id:null);
    const [agent, setAgent] = useState(seller_id?seller_id:null);
    let sellerData=null;
    if(seller_id){
        sellerData={
            label:seller_name,
            value:seller_id
        }
    }

    const [dateRange, setDateRange] = useState([ sevenDays,TodayDate]);

    const [startDate, endDate] = dateRange;

    let history = useHistory();




    const dataMenu = [
        {
            type: 0,
            text: "Detail Commission"
        }
    ];
    const tableColumns = [
        {
            name: '#',
            selector: row => row.order,
            sortable: true,
            center: false,
            width:"7%"
        }, {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
            center: false,
        },
        {
            name: 'Product',
            selector: row => row.product,
            sortable: true,
            center: false,
        },
        {
            name: 'Qty',
            selector: row => row.quantity,
            sortable: true,
            center: false,
            width:"7%"
        },
        {
            name: 'Price (£)',
            selector: row => row.price,
            sortable: true,
            center: false,
            width:"10%"
        },
        {
            name: 'Price Excl Vat (£)',
            selector: row => row.price_exc_vat,
            sortable: true,
            center: false,
            width:"15%"
        },
        {
            name: 'Admin Percentage',
            selector: row => row.admin_percentage+"%",
            sortable: true,
            center: false,
        },{
            name: 'Admin Commission Paid (£)',
            selector: row => row.commission,
            sortable: true,
            center: false,
        }
    ];

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data ={
                value: inputValue
            }
            setTimeout(() =>{
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    useEffect( ()=>{
        const timer=setTimeout(() => {
            setOrder(search);
        },1500);

        return () => clearTimeout(timer);

    },[search])

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    useEffect(() => {
        getData();
    }, [page,startDate,endDate,order,seller,agent]);

    const getData = () =>{
        const data={
            page:page,
            pageSize:10,
            order:order,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            seller_id: seller,
            agent_id:agent
        }
        isSetTableLoading(true);
        dispatch(getOrderCommissions(data)).then(res=>{
            if(!res.error){
                dispatch(setCommissionData(res.payload))
                isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    }

    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Detail Commission" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm={userState.user.role.name==="Seller"?4:3}>
                                <div className="dashboard-datepicker">
                                    <DatePicker
                                        selectsRange={true}
                                        className="form-control hide-border-control"
                                        dateFormat="dd/MM/yyyy"
                                        startDate={startDate}
                                        maxDate={TodayDate}
                                        endDate={endDate}
                                        styles={customStyles}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        placeholderText="Search by Date Range"
                                        isClearable={false}
                                    />
                                </div>
                            </Col>
                            <Col sm={userState.user.role.name==="Seller"?4:3}>
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{setSearch(e.target.value)}}
                                            placeholder="Order Number"
                                        />
                                        <i className={tableLoading?"fa fa-spinner fa-spin":"fa fa-search"}></i>

                                    </FormGroup>
                                </Form>
                            </Col>
                            {userState.user.role.name!=="Seller" && <Col sm="3">
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    defaultValue={seller_id?sellerData:null}
                                    styles={customStyles}
                                    placeholder="Select Seller"
                                    loadOptions={sellerPromiseOption}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        setSeller(e?e.value:null);
                                    }}
                                />
                            </Col>}
                            <Col sm="3">
                                <Select
                                    placeholder="Agent/Sales Person"
                                    className="select2-filter"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isClearable
                                    cacheOptions
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={customStyles}
                                    options={sellerState.settings.agents}
                                    onChange={(e) => {
                                        console.log(e)
                                        setAgent(e?e.id:null)
                                    }}

                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <CommonDataTable
                                        headerColumns={tableColumns}
                                        gridData={orderState.commissionData?orderState.commissionData:[]}
                                        noAction
                                        progressPending={tableLoading}
                                        paginationServer
                                        paginationTotalRows={orderState.orderTableRow}
                                        paginationPerPage={10}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPage(page)}
                                    />

                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(OrderCommission);