import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {ButtonGroup} from "react-bootstrap";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {defaultValues} from "../settings";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {addSellerLead} from "../store/sellerLeadSlice";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {mapSellerLeadResponseToExtraDetailsForm} from "../../../utils/mapper";
import {addBuyerLead} from "../store/buyerLeadSlice";
import {toast} from "react-toastify";

export const schema = yup.object().shape({
    // sources: yup.object(),
});

export const ExtraDetails = (props) => {
    const {sellerLeadState, id, formData, buyer} = props;
    const dispatch = useDispatch();

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;
    const submitBtnRef = useRef();
    const submitBtnSaveRef = useRef();


    let womenCat = [];
    let menCat = [];
    let childCat = [];
    const giftCategories = [];
    let history = useHistory();
    const [womenChecked, setWomenChecked] = useState(false);
    const [womenCategories, setWomenCategories] = useState([]);
    const [menCategories, setMenCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [menChecked, setMenChecked] = useState(false);
    const [childChecked, setChildChecked] = useState(false);
    const [source, setSource] = useState(false);
    const [enable, setEnable] = useState(true);

    useEffect(() => {
        if (id) {
            if (formData.length != 0) {
                reset(mapSellerLeadResponseToExtraDetailsForm(formData));
                setWomenCategories(JSON.parse(formData.women_categories)?? []);
                // setMenCategories(JSON.parse(formData.men_categories));
                // setChildCategories(JSON.parse(formData.children_categories));
                // console.log()
            }
        }

    }, [formData]);


    // formData.how_know.id?(formData.how_know.id==5?setSource(true):''):'';


    const onSubmitForm = (data) => {
        submitBtnSaveRef.current.disabled=true;
        data.sources = data.sources?data.sources.id:'';
        const finalData = {
            id: id,
            formData: data,
            type: 6
        }
        if (buyer) {
            dispatch(addBuyerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled=false;
                if (!res.errors)
                    if (!res.payload.error) {
                        toast.success("Additional Details Updated successfully",{position:toast.POSITION.TOP_RIGHT});
                        history.push({
                            pathname: '/leads/buyer-lead',
                            state: { from: "submit" }
                        });
                    } else {
                        toast.error(res.payload.data,{position:toast.POSITION.TOP_RIGHT});
                    }

            });
        } else {
            dispatch(addSellerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled=false;
                if (!res.errors)
                    if (!res.payload.error) {
                        toast.success("Additional Details Updated successfully",{position:toast.POSITION.TOP_RIGHT});
                        history.push('/leads/seller-lead/');
                    } else {
                        toast.error(res.payload.data,{position:toast.POSITION.TOP_RIGHT});
                    }
            });
        }
    }

    const sourceForTheCompany = data => {
        if (data.id == 5) {
            setSource(true);
        } else {
            setSource(false);
        }
    }
        const womenCats = [];
    const setWomenCategoriesFunc = (e, id) => {

        // setValue("targetAudience", womenCats);
        if (e.target.checked) {
            if (id !==1) {
                if (womenCategories.length>0) {
                    setWomenCategories(prevState => prevState.concat(...prevState,[id]))
                } else {
                    setWomenCategories([id])
                }

            }

        }
        console.log(womenCategories)
        // if (e.target.checked) {
        //     if (id == 1) {
        //         let vals = sellerLeadState.settings.womenCategories.map((value, index) => value.id);
        //         womenCat = vals
        //         setWomenCategories(vals);
        //         setWomenChecked(true);
        //     } else {
        //         womenCat.push(id)
        //         setWomenCategories(prevState => {
        //             console.log(prevState)})
        //     }
        // } else {
        //     if (id == 1) {
        //         setWomenCategories([]);
        //         setWomenChecked(false);
        //     } else {
        //         setWomenCategories(prevState => prevState.filter(i => i != id));
        //     }
        // }
        // setValue("women_categories", womenCat);
    }

    const singleItemChecked = (e, id) => {
        const selectedWomensCategoryArray = [...womenCategories];
        let womenCategoryIndex = selectedWomensCategoryArray.findIndex(aItem => aItem === id);
        if (womenCategoryIndex !== -1) {
            if (!e.target.checked) {
                selectedWomensCategoryArray.splice(womenCategoryIndex, 1);
            }
        } else {
            selectedWomensCategoryArray.push(id);
        }
        setWomenCategories(selectedWomensCategoryArray,...womenCategories);

        setValue("women_categories", selectedWomensCategoryArray)
    }

    // const setMenCategoriesFunc = (e, id) => {
    //     if (e.target.checked) {
    //         if (id == 1) {
    //             let menVals = sellerLeadState.settings.menCategories.map((value, index) => value.id)
    //             setMenCategories(menVals);
    //             menCat = menVals
    //             setMenChecked(true);
    //         } else {
    //             setMenCategories(prevState => prevState.concat([id]))
    //         }
    //     } else {
    //         if (id == 1) {
    //             setMenCategories([]);
    //             setMenChecked(false);
    //         } else {
    //             setMenCategories(prevState => prevState.filter(i => i != id));
    //         }
    //     }
    //     setValue("men_categories", menCat);
    // }
    //
    // const setChildCategoriesFunc = (e, id) => {
    //     if (e.target.checked) {
    //         if (id == 1) {
    //             let childsh = sellerLeadState.settings.childCategories.map((value, index) => value.id)
    //                 childCat = childsh
    //             setChildCategories(childsh);
    //             setChildChecked(true);
    //
    //         } else {
    //             setChildCategories(prevState => prevState.concat([id]))
    //         }
    //     } else {
    //         if (id == 1) {
    //             setChildCategories([])
    //             setChildChecked(false);
    //         } else {
    //             setChildCategories(prevState => prevState.filter(i => i != id))
    //         }
    //     }
    //     setValue("children_categories", childCat);
    // }
    //
    // const setGiftCategories = (e, id) => {
    //     if (e.target.checked) {
    //         giftCategories.push(id)
    //     } else {
    //         const index = giftCategories.indexOf(id);
    //         if (index > -1) {
    //             giftCategories.splice(index, 1); // 2nd parameter means remove one item only
    //         }
    //     }
    //     setValue("giftCategories", giftCategories);
    // }
    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                <Row>
                    <Label
                        className="form-label m-l-10">{buyer ? "Categories of Interest" : "Product Categories"}</Label>
                    <br/>

                    <Col md={12}>
                        {/*<FormGroup className="m-checkbox-inline">*/}
                        {/*    <div className="checkbox checkbox-dark">*/}
                        {/*        <input onChange={(e) => {*/}
                        {/*            setWomenCategoriesFunc(e, 1)*/}
                        {/*        }} id="inline-3" type="checkbox" checked={sellerLeadState.settings.womenCategories?.length!=0 && sellerLeadState.settings.womenCategories?.length == womenCategories?.length || womenCategories?.includes(1)}/>*/}
                        {/*        <Label for="inline-3"><span className="digits">Women</span></Label>*/}
                        {/*    </div>*/}
                        {/*</FormGroup>*/}
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <Row>
                                {sellerLeadState.settings.womenCategories?.map((item, index) =>
                                    <Col md={3} key={'women-'+index}>
                                        <div className="checkbox checkbox-dark">
                                            <input
                                                checked={!!womenCategories?womenCategories?.find(i => i == item.id):''}
                                                // defaultChecked={getValues("womenCategories")?.includes(item.id)}
                                                onChange={(e) => {
                                                    singleItemChecked(e,item.id);
                                                }} id={item.value} type="checkbox"/>
                                            <Label for={item.value}><span
                                                className="digits"> {item.name}</span></Label>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </FormGroup>
                    </Col>

                    {/*<Col md={12}>*/}
                    {/*    <FormGroup className="m-checkbox-inline mt-1">*/}
                    {/*        <div className="checkbox checkbox-dark">*/}
                    {/*            <input onChange={(e) => {*/}
                    {/*                setMenCategoriesFunc(e, 1)*/}
                    {/*            }} id="inline-4" type="checkbox" checked={sellerLeadState.settings.menCategories?.length == menCategories?.length}/>*/}
                    {/*            <Label for="inline-4"><span className="digits">Mens</span></Label>*/}
                    {/*        </div>*/}
                    {/*    </FormGroup>*/}
                    {/*    <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">*/}
                    {/*        <Row>*/}
                    {/*            {sellerLeadState.settings.menCategories?.map((item, index) =>*/}
                    {/*                <Col md={3} key={'man-'+index}>*/}
                    {/*                    <div className="checkbox checkbox-dark">*/}
                    {/*                        <input*/}
                    {/*                            checked={!!menCategories?.find(i => i == item.id)}*/}
                    {/*                            // defaultChecked={getValues("menCategories")?.includes(item.id)}*/}
                    {/*                            onChange={(e) => {*/}
                    {/*                                setMenCategoriesFunc(e, item.id)*/}
                    {/*                            }} id={"men-" + item.value} type="checkbox"/>*/}
                    {/*                        <Label for={"men-" + item.value}><span*/}
                    {/*                            className="digits"> {item.name}</span></Label>*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*            )}*/}
                    {/*        </Row>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}

                    {/*<Col md={12}>*/}
                    {/*    <FormGroup className="m-checkbox-inline mt-1">*/}
                    {/*        <div className="checkbox checkbox-dark">*/}
                    {/*            <input onChange={(e) => {*/}
                    {/*                setChildCategoriesFunc(e, 1)*/}
                    {/*            }} id="inline-5" type="checkbox" checked={sellerLeadState.settings.childCategories?.length == childCategories?.length}/>*/}
                    {/*            <Label for="inline-5"><span className="digits">Children</span></Label>*/}
                    {/*        </div>*/}
                    {/*    </FormGroup>*/}
                    {/*    <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">*/}
                    {/*        <Row>*/}
                    {/*            {sellerLeadState.settings.childCategories?.map((item, index) =>*/}
                    {/*                <Col md={3} key={'child-'+index}>*/}
                    {/*                    <div className="checkbox checkbox-dark">*/}
                    {/*                        <input checked={!!childCategories?.find(i => i == item.id)}*/}
                    {/*                               // defaultChecked={getValues("childCategories")?.includes(item.id)}*/}
                    {/*                               onChange={(e) => {*/}
                    {/*                                   setChildCategoriesFunc(e, item.id)*/}
                    {/*                               }} id={"child-" + item.value} type="checkbox"/>*/}
                    {/*                        <Label for={"child-" + item.value}><span*/}
                    {/*                            className="digits"> {item.name}</span></Label>*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*            )}*/}
                    {/*        </Row>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}

                    {/*<Col md={12}>*/}
                    {/*    <Label>Gifts & Homeware</Label>*/}
                    {/*    <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">*/}
                    {/*        {sellerLeadState.settings.giftCategories?.map((item, index) =>*/}
                    {/*            <div key={'gift-'+index} className="checkbox checkbox-dark">*/}
                    {/*                <input onChange={(e) => {*/}
                    {/*                    setGiftCategories(e, item.id)*/}
                    {/*                }} id={"gift-" + item.value} type="checkbox"/>*/}
                    {/*                <Label for={"gift-" + item.value}><span*/}
                    {/*                    className="digits"> {item.name}</span></Label>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}

                    <Col md={12} className="m-t-15 m-b-15">
                        <Label className="form-label">Average spend per piece</Label>
                        <br/>
                        <ButtonGroup className="m-l-5">
                            {sellerLeadState.settings.averageSpends?.map((item, index) =>
                                <div key={'avg-'+index} className="radio radio-primary m-l-20">
                                    <input
                                        {...register(`averageSpends`)} id={item.value} type="radio" name="averageSpends"
                                        value={String(item.id)}/>
                                    <Label htmlFor={item.value}>{item.price_range}</Label>
                                </div>
                            )}
                        </ButtonGroup>
                    </Col>

                    <Col md={12} className="m-t-15 m-b-15">
                        <Label className="form-label">Average monthly sales (wholesale value)</Label>
                        <br/>
                        <ButtonGroup className="m-l-5">
                            {sellerLeadState.settings.averageSales?.map((item, index) =>
                                <div key={'sale-'+index} className="radio radio-primary m-l-20">
                                    <input
                                        {...register(`averageSales`)} id={item.value} type="radio" name="averageSales"
                                        value={String(item.id)}/>
                                    <Label htmlFor={item.value}>{item.sale_range}</Label>
                                </div>
                            )}
                        </ButtonGroup>
                    </Col>

                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">How did you hear about {formData?formData.tenant:""}?</Label>
                            <Select placeholder="Online Search" getOptionLabel={(option) => option.name}
                                    value={getValues('sources')}
                                    getOptionValue={(option) => option.id} {...register("sources")}
                                    onChange={(e) => {
                                        sourceForTheCompany(e)
                                        setValue('sources', e);
                                        trigger('sources');
                                    }} className={` ${errors.sources ? 'is-invalid' : ''}`}
                                    options={sellerLeadState.settings.sources}/>
                            <div className="invalid-feedback">{errors.sources?.message}</div>
                        </FormGroup>
                    </Col>
                    {source ? <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Please Specify</Label>
                            <input name="sourceReason" type="text"
                                   className={`form-control ${errors.sourceReason ? 'is-invalid' : ''}`}
                                   placeholder="Please Specify" {...register('sourceReason')}/>
                        </FormGroup>
                    </Col> : ''}

                    <Col md={12}>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="checkbox checkbox-dark">
                                <input defaultChecked={getValues('agree_tc')} id="inline-1" type="checkbox" onChange={(e)=>{
                                    !e.target.checked?setEnable(true):setEnable(false);
                                }}/>
                                <Label for="inline-1"><span className="digits"> Agree T&C/Privacy</span></Label>
                            </div>
                        </FormGroup>

                    </Col>
                </Row>
                <Col className="col-md-4" style={{color: "red"}}>
                    {errors.name ? "Detail Name Error Found" : ""}
                </Col>
                <Col className="col-md-2 offset-md-10">
                    <button ref={submitBtnSaveRef} type="submit"
                            className="btn btn-primary btn-block">Save
                    </button>
                </Col>
            </Form>
        </Fragment>
    );
}