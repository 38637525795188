import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductTagApi, deleteProductTagApi,
    getProductTagApi,
    updateProductTagApi
} from "../../../services/manage-product/setting/productTagService";
import {toast} from "react-toastify";


export const getAllProductTags = createAsyncThunk('productTag/getProductTag', async (filters,{dispatch}) => {
    const response = await getProductTagApi(filters);
    return response.response;
});

export const addProductTag = createAsyncThunk('productTag/addProductTag', async (productTag,{
    dispatch,
    getState
}) => {
    try {
        const response = await addProductTagApi(productTag);
        const data = await response.response;
        dispatch(getAllProductTags());
        toast.success("Add product tag successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductTag = createAsyncThunk('productTag/updateProductTag', async (productTag, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductTagApi(productTag, productTag.id);
        const data = await response.response;
        dispatch(getAllProductTags());
        toast.success("Update product tag successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductTag = createAsyncThunk('productTag/deleteProductTag', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductTagApi(id);
        const data = await response.response;
        dispatch(getAllProductTags());
        toast.success("Delete product tag successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productTageAdapter = createEntityAdapter({});

export const {selectAll:selectProductTag, selectById: selectProductTagById} = productTageAdapter.getSelectors(
    state => state.product.productTag
);

const productTagSlice = createSlice({
    name: 'productTag',
    initialState: productTageAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productTag',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductTagSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productTag',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductTags.fulfilled]: productTageAdapter.setAll
    }
});

export const {
    selectProductTagSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productTagSlice.actions;

export default productTagSlice.reducer;