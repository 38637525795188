import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Badge, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select from 'react-select'
import {SellerImageUploader} from "../sellerImageUploader";
import ImageUpload from "image-upload-react";

const BrandForm = (props) => {
    const { errors, control, userRoles, register,setValue,trigger,getValues,brands } = props;
    const [selectedImage,setSelectedImage]=useState(getValues('imageFile'));
    const imageUploaderRef= useRef(null);

    useEffect( ()=>{
        //.background="#fff"
        if(selectedImage) console.log(imageUploaderRef.current);
    },[selectedImage])
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="col-form-label">Brand</Label>
                        <input name="brand" type="text" {...register('brand')} className={`form-control ${errors.brand ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.brand?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Brand Image</Label>
                        <div className="text-black-50">{errors.image?.message}</div>
                        <div className="text-black-50">{errors.imageFile?.message}</div>
                        <ImageUpload
                            handleImageSelect={e => {
                                setValue('image',e.target.files[0]);
                                setValue('imageFile',URL.createObjectURL(e.target.files[0]));
                                setSelectedImage(URL.createObjectURL(e.target.files[0]));
                            }}
                            ref={imageUploaderRef}
                            imageSrc={selectedImage}
                            setImageSrc={setSelectedImage}
                            style={{
                                width: 'auto',
                                height: '350px',
                                marginTop:0,
                                backgroundColor:'#fff'
                            }}
                            deleteIcon={
                                <span className="mt-3 mr-2">
                                    <i aria-hidden="true" className="fa fa-times-circle fa-2x"></i>
                                </span>
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>


        </Fragment>
    );
}

export default BrandForm;