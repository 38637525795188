import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {
    addSellerStaff,
    getSelectedSellerStaffData,
    getSellerNavigationMenu, setSellerStaffInfo,
    updateSellerStaff
} from "../store/sellerStaffSlice";
import {useHistory, useParams} from "react-router-dom";
import {mapStaffSeller} from "../../../utils/mapper";
import {logger} from "redux-logger/src";

export const defaultValues = {
    name: '',
    email: '',
    mobile_number: '',
    permission: [],
}

const dataMenu = [
    {
        type: 0,
        text: 'Seller',
    },
    {
        type: 1,
        text: 'Seller Staff',
    },
    {
        type: 1,
        text: 'Add Seller Staff',
    }
];

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    email: yup.string().email('Must be a valid email').required('Email is required field'),
    number: yup.string().nullable(),
});

const AddSellerStaff = () => {

    const { id } = useParams();
    const {handleSubmit, formState, reset, register, setValue, getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    let history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const {errors} = formState;
    const [LeftLineTab, setLeftLineTab] = useState('1');
    const [orderNavigation, setOrderNavigation] = useState([]);
    const [ProductNavigation, setProductNavigation] = useState([]);
    const [userPermission, setUserPermission] = useState([]);
    const submitBtnSaveRef = useRef();


    const onSubmitForm = (data) => {

        const formData = {

            name: data.name,
            mobile_number: data.mobile_number,
            email: data.email,
            permission: data.permission,
            seller_id: 1,
            id:data.id
        };
        if (data.id) {
            console.log(data)
            dispatch(updateSellerStaff({...data})).then(res => {
                if (!res.error) {
                    history.push('/seller/staff')
                }
            });
        } else {

            dispatch(addSellerStaff({...formData})).then(res => {
                if(!res.error){
                    history.push('/seller/staff')
                }
            });
        }

    }

    useEffect(() => {
        dispatch(getSellerNavigationMenu(1)).then(res => {
            setOrderNavigation(res.payload.Orders)
            setProductNavigation(res.payload.Product)
        });
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(getSelectedSellerStaffData(id)).then(res=> {
                reset(mapStaffSeller(res.payload.staff_data));
                setUserPermission(res.payload.permission_data)
            },);
        }
    },[dispatch])

    console.log(userPermission)
    // if (userPermission.length === 0) {
        const dataArr = [];
    // }



    userPermission.map(item=> {
        dataArr.push(item.name)
    })

    const setPermissionData = (value) => e => {
        if (e.target.checked) {
            dataArr.push(value)
        } else {
            for (var i = 0; i < dataArr.length; i++) {

                if (dataArr[i] === value) {

                    dataArr.splice(i, 1);
                }

            }
        }
        setValue('permission', dataArr)
    }


    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Add Seller Staff"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Seller Staff Details</p>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Name<span style={{color: "red"}}>*</span></Label>
                                                <input name="name" type="text"
                                                       className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                       placeholder="John Doe" {...register('name')}  />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Mobile/Telephone No<span
                                                    style={{color: "red"}}>*</span></Label>
                                                <input name="mobile_number" type="text"
                                                       className={`form-control ${errors.mobile_number ? 'is-invalid' : ''}`}
                                                       placeholder="0094716699874" {...register('number')}  />
                                                <div className="invalid-feedback">{errors.mobile_number?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Email<span style={{color: "red"}}>*</span></Label>
                                                <input name="email" type="text"
                                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                       placeholder="john.joe@user.com" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col className="mb-3" md={12}>
                                            <p className="">Seller Staff Permission</p>
                                        </Col>
                                        <Row>
                                            <Col sm="3" className="tabs-responsive-side">
                                                <Nav className="flex-column nav-pills border-tab nav-left">
                                                    <NavItem>
                                                        <NavLink href="#javascript"
                                                                 className={LeftLineTab === '1' ? 'active' : ''}
                                                                 onClick={() => setLeftLineTab('1')}>Orders</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#javascript"
                                                                 className={LeftLineTab === '2' ? 'active' : ''}
                                                                 onClick={() => setLeftLineTab('2')}>Products</NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                            <Col sm="9">
                                                <TabContent activeTab={LeftLineTab}>
                                                    <TabPane className="fade show" tabId="1">
                                                        <Card className="b-r-0">
                                                            <CardBody>
                                                                <Row>

                                                                    {orderNavigation?.map((item, i) =>
                                                                        <Col md="4" key={i}>
                                                                            <FormGroup check className="m-t-10 m-l-45"
                                                                                       row key={item.name} inline>
                                                                                <Label check className="mb-0">
                                                                                    <Input
                                                                                        onClick={setPermissionData(item.name)}
                                                                                        type="checkbox" {...register('permission[]')}
                                                                                        defaultChecked={(userPermission?.findIndex(x => x.name === item.name) != -1)?true:false}
                                                                                    /> {item.name}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <Card className="b-r-0 w-full">
                                                            <CardBody>
                                                                <Row>
                                                                    {ProductNavigation?.map((item, i) =>
                                                                        <Col md="4" key={item.name}>
                                                                            <FormGroup check className="m-t-10 m-l-45"
                                                                                        inline>
                                                                                <Label check className="mb-0">
                                                                                    <Input
                                                                                        onClick={setPermissionData(item.name)}
                                                                                        name="permission"
                                                                                        type="checkbox" {...register('permission')}
                                                                                        defaultChecked={(userPermission?.findIndex(x => x.name === item.name) != -1)?true:false}
                                                                                    /> {item.name}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={() => {
                                                formRef.current.submit();
                                            }} className="btn btn-primary btn-block">Save
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    );
}

export default (AddSellerStaff)