import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";

// import useNavigate from 'react-router-dom';

import draftOrderReducer from "./../store";
import {getDraftOrders, selectDraftOrder} from "./../store/draftOrderSlice";
import {useHistory} from "react-router-dom";

import withReducer from "../../../store/withReducer";
import {mapSellerRoleResponseToTable} from "../../../utils/mapper";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import PreLoader from "../../../share-components/ui/preLoader";

const PopoverBasicItem = (props) => {
    const {id, btntext, Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p className="example-popover" id={"Popover-" + id}>{btntext}</p>
            <Popover
                placement="right"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const DraftOrder = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [brand, setBrand] = useState(null);
    const [search, setSearch] = useState(null);
    const [loading, setLoading] = useState(true);
    let history = useHistory();
    const sellerState = useSelector(({order}) => order.draftOrder);
    const dataMenu = [
        {
            type: 0,
            text: 'Draft Order',
        }
    ];

    const tableColumns = [
        {
            name: 'Order #',
            selector: row => row.draft_order_no,
            sortable: true,
            center: false,
        }, {
            name: 'Shopify ID',
            selector: row => row.shopify_id,
            sortable: true,
            center: false,

        },
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            center: false,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Created Date',
            selector: row => row.order_date,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => (row.is_action) ?
                <div><a onClick={() => {
                    onEdit(row);
                }}><i className="fa fa-pencil"
                      style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>
                </div> : "",
            sortable: false,
            center: true,
        }
    ];


    useEffect(() => {
        getData();
    }, [page, brand, search, dispatch]);

    const getData = () => {
        const data = {
            page: page,
            pageSize: 10,

        }
        dispatch(getDraftOrders(data)).then(res => {
            setLoading(false);
        });
    }

    const onEdit = (row) => {
        history.push('/order/draft/' + row.id + '/edit');
    }
    const onView = (row) => {
        //history.push('seller/' + row.id + '/view');
    }
    const onAdd = () => {
        history.push('/order/draft/create');
    }
    const draftOrders = useSelector(selectDraftOrder);
    return (
        <Fragment>
            <Breadcrumb title="Draft Order" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                {loading ? <>
                        <PreLoader></PreLoader>
                    </> :
                    <>
                        <div className="product-grid">
                            <div className="feature-products">
                                <Card>
                                    <Row>
                                        <Col sm="12">
                                            <div className='float-sm-right p-3'>
                                                <a onClick={onAdd} className="btn btn-sm btn-primary"> <i
                                                    className="fa fa-plus"></i> Add Order</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <CommonDataTable
                                                headerColumns={tableColumns}
                                                gridData={draftOrders}
                                                noAction
                                                paginationServer
                                                paginationTotalRows={sellerState.sellerTableRow}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                onChangePage={page => setPage(page)}
                                            />

                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{order: draftOrderReducer}])(DraftOrder);