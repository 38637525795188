import React, {useEffect} from 'react';
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import SizeMetrixSortModal
    from "../../components/manage-product/product-setting/size-matrix-configuration/sizeMetrixSortModal";
import {useSelector} from "react-redux";
import {selectSellerById} from "../../components/seller/store/sellerSlice";

export default function CommonDataTable(props) {
    const {
        headerColumns,
        gridData,
        onEdit,
        onView,
        onDelete,
        noAction=null,
        onSort
    } = props;

    const onConfirmDialogOpen = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this Record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    onDelete(row)
                }
            })
    }


    const columns = [
        ...headerColumns.map(item => {
            return {
                ...item,

            };
        })
    ];


    if(noAction==null){
        columns.push(
        {
            name: 'Action',
            selector: row => (row.status == undefined) ?
                <div>
                    {onEdit ? <a onClick={() => {
                        onEdit(row);
                    }}><i className="fa fa-pencil"
                          style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>:''}

                    {onView && row.icon_status &&
                        <a onClick={() => {
                            onView(row);
                        }}>
                            <i className="fa fa-eye"
                               style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>
                    }
                    {
                        onDelete && <a onClick={() => {
                            onConfirmDialogOpen(row);
                        }}><i className="fa fa-trash" style={{width: 25, fontSize: 16, padding: 11, color: '#e4566e'}}></i></a>
                    }

                    {onSort && row.size_matrix_response &&
                        <a onClick={() => {
                            onSort(row)
                        }}><i className="fa fa-sort"
                              style={{width: 25, fontSize: 16, padding: 11, color: '#204081'}}></i></a>

                    }
            </div> :
                row.status ?
                    <div> {onEdit && <a onClick={() => {
                        onEdit(row);
                    }}><i className="fa fa-pencil"
                          style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>}

                        {onView && row.icon_status &&
                            <a onClick={() => {
                                onView(row);
                            }}><i className="fa fa-eye"
                                  style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>
                        }
                        {onDelete && <a onClick={() => {
                            onConfirmDialogOpen(row);
                        }}><i className="fa fa-trash" style={{width: 25, fontSize: 16, padding: 11, color: '#e4566e'}}></i></a> }

            </div> :
                    ''
            ,
            visible: true,
            sortable: true,
            center: true,
        });
    }


    return (
        <div className="w-full flex flex-col">
            <DataTable
                columns={columns}
                data={gridData}
                highlightOnHover
                pagination
                {...props}
            />
        </div>
    );
}
