import {Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ViewBrandDetails = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-warning">Brand Details</div>
                        <Row>
                            {sellerDetails.seller_brands? sellerDetails.seller_brands.map((item, i) =>
                                <div className="col-md-2" key={i}>
                                    <Card>
                                        <div className="product-box border">
                                            <div className="product-img">
                                                <img style={{height:'150px',width:'15vw',objectFit:'cover'}} src={item.image_path} alt="" />
                                            </div>
                                            <div className="product-details">
                                                <h4 className="font-primary" >{item.name}</h4>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ) : ''}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};