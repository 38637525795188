import React, {Fragment, useEffect} from 'react';
import {FormGroup, Input, Label} from "reactstrap";
import Select from 'react-select'
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {typeAutoSuggestionApi} from "../../../../services/manage-product/setting/productCategoryService";
import {sellerAutoSuggestionApi} from "../../../../services/manage-product/productService";

const CreateForm = (props) => {
    const { errors, control, userRoles, register,setValue,trigger,getValues,brands,getSellerBrands,currentUser, typePromiseOption} = props;
    //const roleRegister = register("role", { required: true });


    useEffect(()=>{
        if(currentUser.role.id == 5){
            getSellerBrands("",2);
        }else{
            getSellerBrands(1,2);
        }
    },[]);

    return (
        <Fragment>
            {currentUser.role.id != 5 && <FormGroup>
                <Label className="col-form-label">Seller</Label>
                <AsyncSelect
                    isClearable
                    cacheOptions
                    defaultOptions
                    defaultValue={{value:1,label:"City Goddess Ltd"}}
                     loadOptions={typePromiseOption}
                    onChange={(e) => {
                        getSellerBrands(e?.value,2);
                        setValue('seller', e);
                        trigger('seller');
                    }}

                />
                <div className="invalid-feedback">{errors.seller?.message}</div>
            </FormGroup>}
            <FormGroup>
                <Label htmlFor="col-form-label">Brand</Label>
                <Select    getOptionLabel ={(option)=>option.name} defaultValue={getValues('brand')}
                           getOptionValue ={(option)=>option.id} {...register("brand")}    onChange={(e)=>{
                    setValue('brand',e);
                    trigger('brand');
                }} className={` ${errors.brand ? 'is-invalid' : ''}`} options={brands} />

                <div className="invalid-feedback">{errors.brand?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="col-form-label">Style Number</Label>
                <input name="code" type="text" {...register('code')} className={`form-control ${errors.code ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.code?.message}</div>

            </FormGroup>
            <FormGroup>
                <Label className="col-form-label">Product Title</Label>
                <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.name?.message}</div>

            </FormGroup>
        </Fragment>
    );
}

export default CreateForm;