import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addSellerLeadApi, editSellerLeadApi,
    getAllSellerLeadsApi,
    getSellerLeadSettingsApi, updateSellerLeadApi
} from "../../../services/manage-lead/sellerLead";

export const getSellerLeadSettings = createAsyncThunk('leads/seller-lead/getSellerLeadsSettings', async (data, {dispatch,}) => {
    const response = await getSellerLeadSettingsApi();
    return response.response;
});

export const getAllSellerLeads = createAsyncThunk('leads/seller-lead/getAllSellerLeads', async (data, {dispatch,}) => {
    const response = await getAllSellerLeadsApi(data);
    return response.response;
});

export const addSellerLead = createAsyncThunk('leads/seller-lead/addSellerLeads', async (data, {dispatch,}) => {
    const response = await updateSellerLeadApi(data,data.id);
    // dispatch(getAllSellerLeads())
    return response.response;
});

export const getSelectedSellerLeadData = createAsyncThunk('leads/seller-lead/getSellerLeadData', async (data, {dispatch,}) => {
    const response = await editSellerLeadApi(data);
    // dispatch(getAllSellerLeads())
    return response.response;
});


const sellerLeadAdapter = createEntityAdapter({});

export const {selectAll: selectSellerLead, selectById: selectSellerLeadById} = sellerLeadAdapter.getSelectors(
    state => state.lead.sellerLead
);

const sellerLeadSlice = createSlice({
    name: 'sellerLead',
    initialState: sellerLeadAdapter.getInitialState({
        id: null,
        searchText: '',
        settings:{
            countries:[],
            sources:[],
            titles:[],
            styleTimeGaps:[],
            sellingPlan:[],
            lineOfBusiness:[],
            averageSales:[],
            averageSpends:[],
            businessModels:[],
            designations:[],
            manufacturingPatterns:[],
            womenCategories:[],
            menCategories:[],
            targetAudience:[],
            childCategories:[],
            giftCategories:[],
        },
        data:null,
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.countries = action.payload.countries;
            state.settings.sources = action.payload.sources;
            state.settings.titles = action.payload.titles;
            state.settings.styleTimeGaps = action.payload.styleTimeGap;
            state.settings.sellingPlan = action.payload.sellingPlan;
            state.settings.lineOfBusiness = action.payload.lineOfBusiness;
            state.settings.averageSales = action.payload.averageSales;
            state.settings.averageSpends = action.payload.averageSpends;
            state.settings.designations = action.payload.designations;
            state.settings.manufacturingPatterns = action.payload.manufacturingPatterns;
            state.settings.womenCategories = action.payload.womenCategories;
            state.settings.menCategories = action.payload.menCategories;
            state.settings.childCategories = action.payload.childCategories;
            state.settings.giftCategories = action.payload.giftCategories;
            state.settings.targetAudience = action.payload.targetAudience;
            state.settings.businessModels = action.payload.businessModels;
        },
    },
    extraReducers: {
        [getAllSellerLeads.fulfilled]: sellerLeadAdapter.setAll
    }
});

export const {setSettings} = sellerLeadSlice.actions;

export default sellerLeadSlice.reducer;