import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Label, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    setOrderData,
    showReturn
} from "../../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import orderReducer from "../../store";

import {ReturnItem} from "./section/returnItem";
import {ShippingInformation} from "../../view-order/section/shippingInformation";
import {BillingInformation} from "../../view-order/section/billingInformation";


const ViewReturnOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const dataMenu = [
        {
            type: 1,
            text: 'Returns',
            link: '/order/return'
        },
        {
            type: 0,
            text: "Return Order Details"
        }
    ];


    useEffect(() => {
        dispatch(showReturn(id)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
            }
        });
    }, [dispatch]);

    return (
        <Fragment>
            <Breadcrumb title="Return Order Details" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <ReturnItem orderId={id} orderState={orderState}/>
                    </Col>
                </Row>
                <Row className="equal-height-lg">
                    <ShippingInformation colSize={6} orderState={orderState}/>
                    <BillingInformation colSize={6} orderState={orderState}/>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(ViewReturnOrder);