import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {
    addReturnApi,
    addTrackingApi,
    createTrackingInvoiceApi,
    downloadBulkPdfApi,
    downloadInvoiceApi,
    downloadPdfApi,
    downloadTrackingInvoiceApi, generateOrderPdf, generateOrderPdfApi,
    generateOrderShippingDetails,
    generateVatDetails,
    getCheckoutApi,
    getOrderCommissionsApi,
    getOrdersApi,
    getOrderSettingsApi,
    getReturnsApi,
    getTrackingItemApi,
    sendReturnItemShopifyApi,
    showOrderApi,
    showPendingReturnRequestListApi,
    showPendingReturnReturn,
    showReturnApi,
    showReturnListApi,
    showTrackingInvoiceApi,
    storePendingReturnRequest
} from "../../../services/order";
import {updateSellerApi} from "../../../services/seller";


export const getOrders = createAsyncThunk('orders/getAllOrders', async (data,{dispatch,}) => {
    const response = await getOrdersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});



export const showOrder = createAsyncThunk('orders/getOrder', async (id,{dispatch})=>{
    const response = await showOrderApi(id);
    return response.response;
});

export const downloadOrderPdf = createAsyncThunk('orders/downloadPdfSlip', async (data,{dispatch})=>{
    const response = await downloadPdfApi(data.id,data.name);
    return response.response;
});

export const downloadBulkOrderPdf = createAsyncThunk('orders/downloadBulkPdfSlip', async (data,{dispatch})=>{
    const response = await downloadBulkPdfApi(data);
    return response.response;
});


export const downloadOrderInvoice = createAsyncThunk('orders/downloadInvoice', async (id,{dispatch})=>{
    const response = await downloadInvoiceApi(id);
    return response.response;
});

export const getOrderSettings = createAsyncThunk('order/getOrderSettings', async () => {
    const response = await getOrderSettingsApi();
    return response.response;
});

export const exportOrderShippingDetails = createAsyncThunk('order/exportShoppingData', async (data, {dispatch}) => {
    const response = await generateOrderShippingDetails(data);
    return response.response;
});

export const exportVatDetails = createAsyncThunk('order/exportVatData', async (data, {dispatch}) => {
    const response = await generateVatDetails(data);
    return response.response;
});

export const generateOrderPdfData = createAsyncThunk('order/generateOrderPdf', async (data, {dispatch}) => {
    const response = await generateOrderPdfApi(data);
    return response.response;
});

export const addReturnItems = createAsyncThunk('order/addReturnItems', async (returnItems, { dispatch, getState }) => {
    try {
        const response = await addReturnApi(returnItems);
        const data = await response.response;
        toast.success("Returns added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const sendReturnItemShopify = createAsyncThunk('orders/sendReturnItemShopify', async (data,{dispatch})=>{

    try {
        const response = await sendReturnItemShopifyApi(data);
        toast.success(`Return sent to shopify successfully`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }


});


export const createTrackingInvoice = createAsyncThunk('order/createInvoiceTracking', async (trackingItems, { dispatch, getState }) => {
    try {
        const response = await createTrackingInvoiceApi(trackingItems);
        const data = await response.response;
        toast.success("Invoice created successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const addTrackingItems = createAsyncThunk('order/addTracking', async (trackingItems, { dispatch, getState }) => {
    try {
        const response = await addTrackingApi(trackingItems);
        toast.success("Tracking added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return await response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const downloadTrackingInvoice = createAsyncThunk('orders/downloadTrackingInvoice', async (id,{dispatch})=>{
    const response = await downloadTrackingInvoiceApi(id);
    return response.response;
});


export const showTrackingInvoice = createAsyncThunk('orders/getTrackingInvoice', async (id,{dispatch})=>{
    const response = await showTrackingInvoiceApi(id);
    return response.response;
});

export const getTrackingItem = createAsyncThunk('orders/getOrderPacking', async (id,{dispatch,}) => {
    const response = await getTrackingItemApi(id);
    return response.response;
});

export const getReturns = createAsyncThunk('orders/getAllReturns', async (data,{dispatch,}) => {
    const response = await getReturnsApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const showReturnList = createAsyncThunk('orders/getReturnList', async (id,{dispatch})=>{
    const response = await showReturnListApi(id);
    return response.response;
});

export const showReturn = createAsyncThunk('orders/getReturn', async (id,{dispatch})=>{
    const response = await showReturnApi(id);
    return response.response;
});

export const getAllPendingReturnRequest = createAsyncThunk('orders/getAllPendingReturnRequest', async (data,{dispatch,}) => {
    const response = await showPendingReturnRequestListApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const showPendingReturnRequestList = createAsyncThunk('orders/getPendingReturnReturn', async (id,{dispatch})=>{
    const response = await showPendingReturnReturn(id);
    return response.response;
});

export const storeReturnRequest = createAsyncThunk('orders/storePendingReturnRequest', async (data,{dispatch}) => {

    try {
        const response = await storePendingReturnRequest(data);
        toast.success(`Return Request updated successfully`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }

});

export const getOrderCommissions = createAsyncThunk('orders/getOrderCommissions', async (data,{dispatch,}) => {
    const response = await getOrderCommissionsApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

const ordersAdapter = createEntityAdapter({});
const checkoutAdapter = createEntityAdapter({});

export const { selectAll: selectOrder, selectById: selectOrderById } = ordersAdapter.getSelectors(
    state => state.order.order
);

export const { selectAll: selectCheckouts, selectById: selectCheckoutById } = checkoutAdapter.getSelectors(
    state => state.order.checkout
);


const orderSlice = createSlice({
    name: 'orders',
    initialState: ordersAdapter.getInitialState({
        id:null,
        orderTableRow:0,
        checkoutTableRow:0,
        searchText: '',
        settings:{
          returnReasons:[],
          countries: [],
        },
        data:null,
        tracking:null,
        selectedInvoiceID:null,
        commissionData:null,
        newInvoiceDialog: {
            component: 'Invoice',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        addTrackingDialog: {
            component: 'Tracking',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        returnData:null,
        returnRequestData:null,
        list:null,
        trackingDetails:null,
    }),
    reducers: {
        setSettings: (state, action) => {
            // state.setting= action.payload.settings;
            state.settings.returnReasons = action.payload.returnReasons;
            state.settings.countries = action.payload.countries;
        },
        setOrderData: (state, action) => {
            state.data = action.payload.data;
            state.list = action.payload.list;
            state.trackingDetails=action.payload.tracking?action.payload.tracking:null;
            state.tracking=null;
            state.address=action.payload.address;
        },
        setTrackingData: (state, action) => {
            state.tracking = action.payload;
        },
        unSetTrackingData: (state, action) => {
            state.tracking = null;
        },
        setReturnData: (state, action) => {
            state.returnData = action.payload;
        },
        setCommissionData: (state, action) => {
            state.commissionData = action.payload;
        },
        setTableRowCount: (state, action) => {
            state.orderTableRow = action.payload;
        },

        setOrderListPackQty: (state, action) => {
            // state.list[action.payload.index].pack=action.payload.pack;
        },
        openNewInvoiceDialog: (state, action) => {
            state.newInvoiceDialog = {
                component: 'Invoice',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeNewInvoiceDialog:(state, action) => {
            state.newInvoiceDialog = {
                component: 'Invoice',
                type: 'new',
                props: {
                    isOpen: false,
                },
                data: null
            };
        },
        openNewTrackingDialog: (state, action) => {
            state.addTrackingDialog = {
                component: 'Tracking',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
            state.selectedInvoiceID=action.payload;
        },
        closeNewTrackingDialog:(state, action) => {
            state.addTrackingDialog = {
                component: 'Tracking',
                type: 'new',
                props: {
                    isOpen: false,
                },
                data: null
            };
            state.selectedInvoiceID=null;
        },
        setReturnRequestData: (state, action) => {
            state.returnRequestData = action.payload;
        },
    },
    extraReducers: {
        [getOrders.fulfilled]: ordersAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const { setSettings,setTableRowCount,setOrderData,setReturnData,setTrackingData,unSetTrackingData,openNewInvoiceDialog,setCheckoutTableRowCount,
    closeNewInvoiceDialog,openNewTrackingDialog,closeNewTrackingDialog,setOrderListPackQty,setReturnRequestData,setCommissionData } =
    orderSlice.actions;

export default orderSlice.reducer;
