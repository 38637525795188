import React, {PropTypes} from 'react';
import {bindActionCreators} from '@reduxjs/toolkit';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";



export default function (ComposedComponent) {
    class Authenticate extends React.Component {
        constructor(props, context) {
            super(props);

        }
        componentDidMount() {

            this.redirectRoute();
        }


        redirectRoute() {
            const {location, userData, history} = this.props;
            const {pathname, state} = location;
            if(userData.email) {
                return history.push({
                    pathname: '/'
                });
            }
        }

        render() {


            return (
                <ComposedComponent></ComposedComponent>
            );
        }

    }

    // const mapStateToProps = (state) => {
    //     return {
    //         isAuthenticated: state.auth.isAuthenticated
    //     };
    // };
    const mapStateToProps = ({authReducer}) => {
        return {
            userData: authReducer.data
        };
    }
    return withRouter(connect(mapStateToProps)(Authenticate));
}