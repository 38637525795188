import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  const {data}=props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="8">
              <h3>{props.title}</h3>
            </Col>
            <Col xs="4">
              <Breadcrumb>
                <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/dashboard`}><Home /></Link></BreadcrumbItem>
                {data?(data.map((item,index)=>{
                  document.title="Marketplace - "+item.text;
                  return item.type===0?
                      (<BreadcrumbItem key={index} active>{item.text}</BreadcrumbItem>)
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      : ( <BreadcrumbItem key={index}><Link to={{pathname:process.env.PUBLIC_URL+'/'+item.link?item.link:null,state:{from:"update"}}}>{item.text?item.text:null}</Link></BreadcrumbItem> );
                })):null}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;