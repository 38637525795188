import API from "../../utils/api";

export const getAllSellerLeadsApi = async (data) => {
    const response = await API.get('seller-lead/seller-lead', { params: data });
    return { response };
}

export const addSellerLeadApi = async (sellerLead) => {
    const response = await API.post('seller-lead/seller-lead', sellerLead);
    return { response };
}

export const updateSellerLeadApi = async (sellerLead,id) => {
    const response = await API.post(`seller-lead/seller-lead/${id}`, sellerLead);
    return {response};
}

export const deleteSellerLeadApi = async (id) => {
    const response = await API.delete(`seller-lead/seller-lead/${id}`);
    return {response};
}

export const editSellerLeadApi = async (id) => {
    const response = await API.get(`seller-lead/seller-lead/${id}`);
    return {response};
}

export const getSellerLeadSettingsApi = async () => {
    const response = await API.get(`seller-lead/settings`);
    return {response};
}
