
import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    activateShippingSetting,
    getAllShippingSettingsApi, getShippingSettingsApi, sortShippingOrderApi,
    storeShippingSettingsApi,
    updateShippingSettingsApi
} from "../../../../services/shippingSetting";
import {toast} from "react-toastify";


export const getAllShippingSettings = createAsyncThunk('orderSetting/getAllShippingData', async (filters, {dispatch})=> {
    const response = await getAllShippingSettingsApi(filters);
    dispatch(setShippingSettingPaginationData(response.response.pagination))
    return response.response.data;
});

export const addShippingSetting = createAsyncThunk('orderSetting/storeShippingData', async (data, {dispatch})=>{
    const response = await storeShippingSettingsApi(data);
    const res = await response.response;
    dispatch(getAllShippingSettings());
    toast.success("Add shipping successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;

});

export const  updateShippingSetting = createAsyncThunk('orderSetting/updateShippingData', async (data, {dispatch})=>{
    const response = await updateShippingSettingsApi(data, data.id);
    const res = await response.response;
    dispatch(getAllShippingSettings());
    toast.success("Updated shipping successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
});

export const getShippingSettings = createAsyncThunk('orderSetting/getProductSettings', async (data )=>{
    const response = await getShippingSettingsApi();
    return response.response;
});

export const sortShippingOrder = createAsyncThunk('orderSetting/sortShippingOrder', async (data, {dispatch}) => {
    const response = await sortShippingOrderApi(data);
    const res = await response.response;
    dispatch(getAllShippingSettings());
    return res;
});
export const updateActiveStatusShippingOrder = createAsyncThunk('orderSetting/activateShippingOrder', async (data, {dispatch}) => {
    const response = await activateShippingSetting(data, data.id);
    const res = await response.response;
    dispatch(getAllShippingSettings());
    return res;
});

const shippingSettingAdapter = createEntityAdapter({});

export const {
    selectAll: selectShippingSetting,
    selectById: selectShippingSettingById,
} = shippingSettingAdapter.getSelectors(
    state => state.shippingSetting.shippingSetting
);

const shippingSettingSlice = createSlice({
    name: 'shippingSetting',
    initialState:shippingSettingAdapter.getInitialState({
        searchText: '',
        shippingScheduleTypes: '',
        pageCount: 0,
        unreadPageCount: 0,
        selectedArray: [],
        status:'',
        addDialog: {
            component: 'shippingSetting',
            type: 'new',
            props: {
                open: false
            },
            data: null
        }
    }),
    reducers: {
        openNewDialog: (state,action)=>{
            state.addDialog = {
                component: 'shippingSetting',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setShippingSettings: (state, action) => {
            state.shippingScheduleTypes = action.payload.shippingScheduleTypes;
        },
        setShippingSettingPaginationData: (state, action) => {
            state.pageCount = action.payload.count
        },
        setStatusVal: (state, action) => {
            state.status = action.payload
        },
        setSelectedArray: (state, action) => {
            state.selectedArray = action.payload
        }
    },
    extraReducers: {
        [getAllShippingSettings.fulfilled]: shippingSettingAdapter.setAll
    }
});

export const {
    openNewDialog, openEditDialog, closeEditDialog,setShippingSettings, setShippingSettingPaginationData, setStatusVal, setSelectedArray
} = shippingSettingSlice.actions;

export default shippingSettingSlice.reducer;