import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addCompositionGroupApi, deleteCompositionGroupApi,
    getCompositionGroupsApi, updateCompositionGroupApi
} from "../../../services/manage-product/setting/compositionGroupService";
import {toast} from "react-toastify";
import {updateProductSeasonApi} from "../../../services/manage-product/setting/productSeasonService";
import {getAllProductSeasons} from "./productSeasonSlice";


export const getAllCompositionGroups = createAsyncThunk('compositionGroup/getCompositionGroup', async (filters, {dispatch})=> {
    const response = await getCompositionGroupsApi(filters);
    return response.response;
});

export const addCompositionGroup = createAsyncThunk('compositionGroup/addCompositionGroup', async (compositionGroup,{
    dispatch,
    getState
}) => {
    try {
        const response = await addCompositionGroupApi(compositionGroup)
        const data = await response.response;
        dispatch(getAllCompositionGroups());
        toast.success("Add composition group successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateCompositionGroup = createAsyncThunk('compositionGroup/updateCompositionGroup', async (compositionGroup, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateCompositionGroupApi(compositionGroup, compositionGroup.id);
        const data = await response.response;
        dispatch(getAllCompositionGroups());
        toast.success("Update composition group successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteCompositionGroup = createAsyncThunk('compositionGroup/deleteCompositionGroup', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteCompositionGroupApi(id);
        const data = await response.response;
        dispatch(getAllCompositionGroups());
        toast.success("Update composition group successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const compositionGroupAdapter = createEntityAdapter({});

export const {selectAll:selectCompositionGroup, selectById: selectCompositionGroupById} = compositionGroupAdapter.getSelectors(
    state => state.product.compositionGroup
);

const compositionGroupSlice = createSlice({
    name:'compositionGroup',
    initialState: compositionGroupAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'compositionGroup',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectCompositionGroupSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'compositionGroup',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllCompositionGroups.fulfilled]: compositionGroupAdapter.setAll
    }
});

export const {
    selectCompositionGroupSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog

} = compositionGroupSlice.actions;

export default compositionGroupSlice.reducer;