import {Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Media, Row} from "reactstrap";
import React, {Fragment} from "react";
import './ckeditorStyles.css';

export const ViewBannerImages = (props) => {
    const {bannerImages,bannerIndex,setBannerIndex}=props;


    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody className="my-gallery row gallery-with-description">
                        <div className="ribbon ribbon-clip ribbon-success">Banner Images</div>
                        <Row>
                            {bannerImages? bannerImages.map((item, i) =>
                                <Col md={4} key={i}>
                                    <Card>
                                        <div className="product-box border">
                                            <div className="product-img">
                                                <img style={{height:'150px',width:'20vw',objectFit:'cover'}} src={item.path} alt="" />
                                                <div className="product-hover">
                                                    <ul>
                                                        <li onClick={() => setBannerIndex({...bannerIndex, index: i, isOpen: true})} >
                                                            <Button type="button" color="default" >
                                                                <i className="icon-eye"></i>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            ) : ''}
                        </Row>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    );
};