import {Card, CardBody, CardHeader, Col, FormGroup, Label, Media, Row} from "reactstrap";
import React, {Fragment} from "react";
import './ckeditorStyles.css';

export const ViewUploadedImages = (props) => {
    const {images,photoIndex,setPhotoIndex}=props;


    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody className="my-gallery row gallery-with-description">
                        <div className="ribbon ribbon-clip ribbon-secondary">Uploaded Images</div>
                        <Row>
                            {images? images.map((item, i) =>
                                <figure key={i} className="col-xl-3 col-sm-6">
                                    <a href="#javascript" data-size="1600x950">
                                        <Media
                                            style={{height:"auto",objectFit:"cover"}}
                                            src={item.path}
                                            alt="Gallery"
                                            className="img-thumbnail img-hover-zoom"
                                            onClick={() =>
                                                setPhotoIndex({...photoIndex, index: i, isOpen: true})
                                            }
                                            // onMouseOver={()=>alert("T")}
                                        />
                                        <div className="caption">
                                            <h4>{item.name}</h4>
                                        </div>
                                    </a>
                                </figure>

                            ) : ''}
                        </Row>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    );
};