import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapCategoriesResponseToTable, mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import {
    addProductCategory,
    closeEditDialog,
    deleteProductCategory,
    getAllProductCategories,
    openEditDialog,
    openNewDialog,
    selectProductCategory, updateProductCategory
} from "../../store/productCategorySlice";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import * as yup from "yup";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ProductCategoryForm from "./productCategoryForm";
import {
    categoryAutoSuggestionApi,
    typeAutoSuggestionApi
} from "../../../../services/manage-product/setting/productCategoryService";
import AsyncSelect from "react-select/async";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import PreLoader from "../../../../share-components/ui/preLoader";


const defaultValues = {
    id: '',
    name: '',
    type: '',
    parent_category: '',
    is_parent: '',
    default_config: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Type',
        link: ''
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product name'),
    // type: yup.object().required('Type is required field'),
    // is_parent: yup.boolean(),
    // email: yup
    //     .object()
    //     .when("is_parent", {
    //         is: true,
    //         then: yup.object().required("Must enter email address")
    //             .typeError('Please select type')
    //     })
});

const ProductCategory = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [type, setType] = useState([]);
    const [parent, setParent] = useState([]);
    const productCategory = useSelector(selectProductCategory);
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const tableColumns = [
        {
            name: 'Category Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Type',
            selector: row => row.type_text,
            sortable: true,
            left: true,
        },
        {
            name: 'Is Child',
            selector: row => row.parent_text,
            sortable: true,
            left: true,
        },

    ];

    useEffect(() => {
        const data = {
            name, parent, type
        }
        dispatch(getAllProductCategories({...data})).then(res =>{
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, parent, type, name]);

    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(typeAutoSuggestionApi(data)))
            });
        });

    const categoryPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                is_main_categories:1
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(categoryAutoSuggestionApi(data)))
            });
        });

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        const formData = {
            name: data.name,
            parent_category: data.is_parent?data.parent_category[0]?data.parent_category[0].value:data.parent_category.value:'',
            type: data.type[0]?data.type[0].value:data.type.value,
            is_parent: data.is_parent,
            id:data.id,
            default_config:data.default_config,
        };
        if (data.type !== "") {
            if (dialog.type === 'new') {
                dispatch(addProductCategory({...formData})).then(res => {
                    setButtonDisable(false);
                    if (!res.error) closeDialog(dialog.type)
                });
            } else {
                dispatch(updateProductCategory({...formData})).then(res => {
                    setButtonDisable(false);
                    if (!res.error) closeDialog(dialog.type)
                });
            }
        } else {
            toast.error("Please select the type")
        }
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductCategory(row.id))
    }
    const closeDialog = () => {
        dispatch(closeEditDialog());

    }


    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Product Category"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Product Category') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Product Category</a>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Card>
                                                    :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" xl="3">
                                    <AsyncSelect
                                        placeholder={<div>Search By Type</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        styles={customStyles}
                                        // defaultValue={getValues('category')}
                                        loadOptions={typePromiseOption}
                                        onChange={(e) => {
                                            setType(e?e.value:null)
                                        }}

                                    />

                                </Col>
                                <Col sm="12" xl="9">
                                    <Form>
                                        <FormGroup>
                                            <input
                                                onChange={(e) => setName(e.target.value)}
                                                name="name"
                                                type="text" className="form-control border"
                                                placeholder="Search By Category Name"/>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                            <div className="table-responsive product-table mt-4">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={mapCategoriesResponseToTable(productCategory)}
                                    pagination
                                    onEdit={(currentUser.permission).includes('Update Product Category') ? onEdit:''}
                                    onDelete={(currentUser.permission).includes('Delete Product Category') ? onDelete:''}
                                />
                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </>}

            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Category'
                selector='product'
                dialog={({product}) => product.productCategory.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <ProductCategoryForm
                    control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                    typePromiseOption={typePromiseOption} categoryPromiseOption={categoryPromiseOption}
                    getValues={getValues}
                />
            </ModalFormSubmit>

        </Fragment>
    );
};

export default withReducer([{category: productReducer}])(ProductCategory)