import API from "../../utils/api";

export const getNavigationMenuApi = async () => {
    const response = await API.get('permission/get-permissions');
    return { response };
};

export const setPermissionApi = async data => {
    const response = await API.post('permission/set-permission', data);
    return { response };
};

export const getUserRoleList = async () => {
    const response = await API.get('role/role');
    return { response }
};