import API from "../utils/api";

export const getOrdersApi = async (data) => {
    const response = await API.get('order/get-all',{params:data});
    return { response };
};

export const getCheckoutApi = async (data) => {
    const response = await API.get('order/checkout',{params:data});
    return { response };
};

export const getCheckoutDataApi = async (id) => {
    const response = await API.get(`order/checkout/${id}`);
    return { response };
}

export const showOrderApi = async id => {
    const response = await API.get(`order/${id}/show`);
    return { response };
};

export const downloadPdfApi = async ( id,data)  => {
    const response = await API.get(`order/${id}/download/pdf`,{'content-type': 'application/pdf','responseType': 'blob',params:{name:data}});
    return { response };
};

export const downloadBulkPdfApi = async ( data)  => {
    const response = await API.get(`order/download-bulk/pdf`,{'content-type': 'application/pdf','responseType': 'blob',params:{id_array:data.ids,name:data.name}});
    return { response };
};

export const downloadInvoiceApi = async ( id,data)  => {
    const response = await API.get(`order/${id}/download/invoice`,{'content-type': 'application/pdf','responseType': 'blob',});
    return { response };
};

export const getOrderSettingsApi = async () => {
    const response = await API.get('order/get-settings');
    return { response };
};

export const addReturnApi = async (data) => {
    const response = await API.post('order/return-items/add', data);
    return { response };
};

export const sendReturnItemShopifyApi = async (data) => {
    const response = await API.post('order/send-return-items-shopify', data);
    return { response };
};

export const createTrackingInvoiceApi = async (data) => {
    const response = await API.post('order/invoice-tracking/add', data);
    return { response };
};
export const addTrackingApi = async (data) => {
    const response = await API.post('order/tracking/add', data);
    return { response };
};

export const downloadTrackingInvoiceApi = async ( id)  => {
    const response = await API.get(`order/download/tracking/invoice/${id}`,{'content-type': 'application/pdf','responseType': 'blob',});
    return { response };
};

export const showTrackingInvoiceApi = async id => {
    const response = await API.get(`order/${id}/tracking/show`);
    return { response };
};

export const getTrackingItemApi = async id => {
    const response = await API.get(`order/tracking/item/${id}/show`);
    return { response };
};

export const getReturnsApi = async (data) => {
    const response = await API.get('order/return',{params:data});
    return { response };
};

export const showReturnApi = async id => {
    const response = await API.get(`order/return/${id}/show`);
    return { response };
};

export const showReturnListApi = async id => {
    const response = await API.get(`order/return/${id}/list`);
    return { response };
};

export const searchProduct = async (data) => {
    const response = await API.get('order/draft/search-product',{params:data});
    return { response };
};

export const customerAutoSuggestionApi = async (text) => {
    const response = await API.get('order/draft/customer-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const showPendingReturnRequestListApi = async (data) => {
    const response = await API.get('order/return-request-get-all',{params:data});
    return { response };
};

export const showPendingReturnReturn = async id => {
    const response = await API.get(`order/return-request-items/${id}/show`);
    return { response };
};

export const storePendingReturnRequest = async (data) => {
    const response = await API.post('order/return-request/update', data);
    return { response };
};

export const getDraftOrdersApi = async (data) => {
    const response = await API.get(`order/draft`,{
        params: data,
    });
    return { response };
};
export const addDraftOrderApi = async (data) => {
    const response = await API.post('order/draft', data);
    return { response };
};
export const getDraftOrderApi = async (id) => {
    const response = await API.get(`order/draft/`+id+'/edit');
    return { response };
};

export const updateDraftOrderApi = async (data, id) => {
    const response = await API.put(`order/draft/${id}`, data);
    return { response };
};

export const getOrderCommissionsApi = async (data) => {
    const response = await API.get('order/get-seller-admin-commission',{params:data});
    return { response };
};

export const generateOrderShippingDetails = async (data) => {
    const response = await API.get('order/export-shipping-data', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const generateVatDetails = async (data) => {
    const response = await API.get('order/export-vat-data', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const generateOrderPdfApi = async (data) => {
    const response = await API.get(`order/generate-order-pdf`,{'content-type': 'application/pdf','responseType': 'blob',});
    return { response };
};
