import {Col, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    setSizeOrder,
    setSortDialog
} from "../../store/sizeMatrixConfigurationSlice";
import dragula from "react-dragula";
import "./sortModal.css"


const SizeMetrixSortModal = (props) => {

    const {nonSelector, dialog, closeDialog, val} = props;
    const addEditDialog = dialog;
    const dispatch = useDispatch();
    const productSate = useSelector(({product}) => product.sizeMatrixConfiguration.sortDialogProps);

    let dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
                let   sizeMatrixContainer = dragula([componentBackingInstance], {
                    isContainer: function (el) {
                        return false; // only elements in drake.containers will be taken into account
                    },
                    moves: function (el, source, handle, sibling) {
                        return true; // elements are always draggable by default
                    },
                    accepts: function (el, target, source, sibling) {
                        return true; // elements can be dropped in any of the `containers` by default
                    },
                    invalid: function (el, handle) {
                        return false; // don't prevent any drags from initiating by default
                    },
                });
                sizeMatrixContainer.on('drop', (el, target, source, sibling) => {
                    const cardOrder = source.childNodes;
                    let sizeIds = [];
                    cardOrder.forEach(item => {
                        sizeIds.push(item.getAttribute('data-id'))
                    });
                    console.log(sizeIds,id)
                    dispatch(setSizeOrder({id:id,size_id:sizeIds}))
                });
        }
    };

    const closDialog = () => {
        dispatch(setSortDialog({props: false}))
    }

    const data = productSate.row ? productSate.sortData : '';

    const id = productSate.row ? productSate.row.id : '';


    return (
        <Modal {...productSate.props} toggle={() => {
            closDialog()
        }}>
            <ModalHeader
                toggle={() => {
                closDialog()
            }}>
                <Label>Sort Order</Label>
            </ModalHeader>
            <ModalBody>
                <Col sm="12">
                    <div className="ui-sortable small-drag" id="draggableMultiple">
                        <div  className='container' ref={dragulaDecorator}>
                            {data && data.map((item, i) =>
                                <div id={item.value} data-id={item.value} key={i}>{item.label}</div>
                            )}
                        </div>
                    </div>
                </Col>
            </ModalBody>

        </Modal>
    );
}

export default SizeMetrixSortModal;
