import React, { Fragment } from 'react';
import {FormGroup, Input, Label} from "reactstrap";
import {EmailAddress} from "../../../constant";
import Select from 'react-select'


const UserForm = (props) => {
    const { errors, control, userRoles, register,setValue,trigger,roles,getValues } = props;
    //const roleRegister = register("role", { required: true });

    return (
        <Fragment>
            <FormGroup>
                <Label className="col-form-label">Name</Label>
                <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.name?.message}</div>

            </FormGroup>
            <FormGroup>
                <Label className="col-form-label">{EmailAddress}</Label>
                <input name="email" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.email?.message}</div>

            </FormGroup>
            <FormGroup>
                <Label htmlFor="col-form-label">Role</Label>
                <Select    getOptionLabel ={(option)=>option.name} defaultValue={getValues('role')}
                           getOptionValue ={(option)=>option.id} {...register("role")}    onChange={(e)=>{
                    setValue('role',e);
                    trigger('role');
                }} className={` ${errors.role ? 'is-invalid' : ''}`} options={roles} />

                <div className="invalid-feedback">{errors.role?.message}</div>
            </FormGroup>
        </Fragment>
    );
}

export default UserForm;